import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { Typography, Divider, Checkbox, Grid } from '@material-ui/core';
import { Button } from '@clef/client-library';
import cx from 'classnames';
import {
  autoSplitCoreAlgorithm,
  DefectDistribution,
  calculateDeviationFromCoreAlgorithm,
  assignmentToDistributionToAssignSplitMapping,
  splitValueToNoDefectAssignment,
} from '@clef/shared/utils/auto_split_core_algorithm';
import { ApiResponseLoader } from '@clef/client-library';
import { SelectMediaOption, DatasetGroupOptions, LabelType } from '@clef/shared/types';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useDefectSelector } from '../../store/defectState/actions';
import throttle from 'lodash/throttle';
import DoubleSlider from './DoubleSlider';
import DistributionPreviewGraphs from './DistributionPreviewGraphs';
import { useAutoSplitStyles } from './styles';
import StepContainer from './StepContainer';
import { useSnackbar } from 'notistack';
import { useAutoSplitState } from './state';
import { greyScale } from '@clef/client-library';
import { getDefectColor, isAbnormalDefect } from '../../utils';
import {
  useAutoSplitMutation,
  useGetDatasetStatsQuery,
  datasetQueryKeys,
} from '@/serverStore/dataset';
import { useQueryClient } from '@tanstack/react-query';

export const sliderValueToSplitPercentage = (s: [number, number]): [number, number, number] => {
  return [s[0], s[1] - s[0], 100 - s[1]];
};

export const noDefectKey = t('No defect media');

export const defaultSliderValue: [number, number] = [70, 90];
const defaultAnomalyDetectionAbnomalClassSliderValue: [number, number] = [0, 50];

export interface Step2SplitDistributionProps {
  selectMediaOption?: SelectMediaOption;
}

const Step2SplitDistribution: React.FC<Step2SplitDistributionProps> = ({ selectMediaOption }) => {
  const styles = useAutoSplitStyles();
  const {
    state: { defectDistributionWithAssignment, includeAlreadySplitMedia },
    dispatch,
  } = useAutoSplitState();
  const { enqueueSnackbar } = useSnackbar();
  const idleCallbackId = useRef(-1);
  const { data: selectedProject } = useGetSelectedProjectQuery();
  const isAnomalyDetection = selectedProject?.labelType === LabelType.AnomalyDetection;
  const [previewMode, setPreviewMode] = useState<'selected' | 'selected+existing'>('selected');
  const autoSplit = useAutoSplitMutation();
  const queryClient = useQueryClient();

  const { data: mediaGroupByDefectType } = useGetDatasetStatsQuery(
    {
      selectOptions: selectMediaOption,
      groupOptions: [DatasetGroupOptions.DEFECT_TYPE],
    },
    !!selectMediaOption,
  );

  const allDefects = useDefectSelector();

  // filter out defects that does not have count
  const defectList =
    allDefects && mediaGroupByDefectType
      ? allDefects.filter(({ id }) =>
          mediaGroupByDefectType.find(defectType => defectType.defect_id === id),
        )
      : undefined;

  const { data: mediaGroupByDefectDistribution } = useGetDatasetStatsQuery(
    {
      selectOptions: selectMediaOption,
      groupOptions: [DatasetGroupOptions.DEFECT_DISTRIBUTION],
    },
    !!selectMediaOption,
  );

  const [adjustSliderValueTogether, setAdjustSliderValueTogether] = useState(!isAnomalyDetection);
  const [splitSliderValuePerDefect, setSplitSliderValuePerDefect] = useState<
    { [defect: string]: [number, number] } | undefined
  >(undefined);

  const [assignmentDeviation, setAssignmentDeviation] = useState<
    { [defectId: number]: number } | undefined
  >(undefined);

  const coreAlgorithmAndSetDefectDistribution = useCallback(
    throttle(
      (newDefectSplitValue: { [defect: string]: [number, number] }) => {
        if (defectList && mediaGroupByDefectDistribution && newDefectSplitValue) {
          if (idleCallbackId.current > 0) {
            window.cancelIdleCallback(idleCallbackId.current);
          }
          // Use requestIdleCallback here so core algorithm triggers async so ui can update first.
          idleCallbackId.current = window.requestIdleCallback(() => {
            const defectIdList: number[] = defectList.map(defect => defect.id);
            const defectDistributions: DefectDistribution[] = (
              mediaGroupByDefectDistribution as DefectDistribution[]
            ).filter(stats => stats.count && stats.defect_distribution);
            const numberOfMediaWithNoDefects =
              mediaGroupByDefectDistribution.find(stats => !stats.defect_distribution)?.count || 0;

            const [targetSplitPerDefect, targetSplitNoDefect] = Object.entries(
              newDefectSplitValue,
            ).reduce(
              (acc, [defectName, value]) => {
                const [targetSplitPerDefect, targetSplitNoDefect] = acc;
                if (defectName === noDefectKey) {
                  return [targetSplitPerDefect, sliderValueToSplitPercentage(value)];
                } else {
                  const defectId = defectList.find(_ => _.name === defectName)!.id;
                  return [
                    {
                      ...targetSplitPerDefect,
                      [defectId]: sliderValueToSplitPercentage(value),
                    },
                    targetSplitNoDefect,
                  ];
                }
              },
              [
                {} as { [defectId: number]: [number, number, number] },
                undefined as [number, number, number] | undefined,
              ],
            );
            const distributionWithDefectResult = autoSplitCoreAlgorithm(
              defectIdList,
              defectDistributions,
              targetSplitPerDefect,
            );
            const distributionWithNoDefectResult = targetSplitNoDefect
              ? splitValueToNoDefectAssignment(numberOfMediaWithNoDefects, targetSplitNoDefect)
              : undefined;
            dispatch(draft => {
              draft.defectDistributionWithAssignment = distributionWithNoDefectResult
                ? [
                    ...distributionWithDefectResult,
                    {
                      count: numberOfMediaWithNoDefects,
                      defect_distribution: null,
                      assignment: distributionWithNoDefectResult,
                    },
                  ]
                : distributionWithDefectResult;
            });
            setAssignmentDeviation(
              calculateDeviationFromCoreAlgorithm(
                defectIdList,
                distributionWithDefectResult,
                targetSplitPerDefect,
              ),
            );
          });
        }
      },
      500,
      { leading: false },
    ),
    [defectList, mediaGroupByDefectDistribution],
  );

  // when defects response returns for the first time
  useEffect(() => {
    if (defectList && mediaGroupByDefectDistribution && !splitSliderValuePerDefect) {
      const newSplitValue = defectList.reduce(
        (acc, defect) => ({
          ...acc,
          [defect.name]:
            isAnomalyDetection && isAbnormalDefect(defect.name)
              ? defaultAnomalyDetectionAbnomalClassSliderValue
              : defaultSliderValue,
        }),
        {} as { [key: string]: [number, number] },
      );
      const hasNoDefectMedia = !!mediaGroupByDefectDistribution.find(
        stats => !stats.defect_distribution,
      );
      if (hasNoDefectMedia) {
        newSplitValue[noDefectKey] = defaultSliderValue;
      }
      setSplitSliderValuePerDefect(newSplitValue);
      coreAlgorithmAndSetDefectDistribution(newSplitValue);
    }
  }, [
    defectList,
    coreAlgorithmAndSetDefectDistribution,
    mediaGroupByDefectDistribution,
    splitSliderValuePerDefect,
    isAnomalyDetection,
  ]);

  const handleSliderChange = useCallback(
    (defectName: string) => (newValue: [number, number]) => {
      // SPECIAL LOGIC: For anomaly detection, abnormal detect cannot be assigned to training set
      if (isAnomalyDetection && isAbnormalDefect(defectName)) {
        newValue[0] = 0;
      }
      let newSplitValue: { [defectName: string]: [number, number] };
      // if adjustSliderValueTogether, change all slider values together
      if (adjustSliderValueTogether && splitSliderValuePerDefect) {
        newSplitValue = Object.keys(splitSliderValuePerDefect).reduce(
          (acc, key) => ({ ...acc, [key]: newValue }),
          {},
        );
      } else {
        newSplitValue = { ...splitSliderValuePerDefect, [defectName]: newValue };
      }
      setSplitSliderValuePerDefect(newSplitValue);
      // call coreAlgorithmAndSetDefectDistribution
      coreAlgorithmAndSetDefectDistribution(newSplitValue);
    },
    [
      adjustSliderValueTogether,
      coreAlgorithmAndSetDefectDistribution,
      isAnomalyDetection,
      splitSliderValuePerDefect,
    ],
  );

  const defectColorMap: { [key: string]: string } | undefined = useMemo(
    () =>
      defectList?.reduce((acc, defect) => ({ ...acc, [defect.name]: getDefectColor(defect) }), {}),
    [defectList],
  );

  const postAutoSplit = useCallback(async () => {
    if (!selectMediaOption) return;
    dispatch(draft => {
      draft.isMakingPostRequest = true;
    });
    try {
      await autoSplit.mutateAsync({
        selectOptions: selectMediaOption,
        splitByDefectDistribution: assignmentToDistributionToAssignSplitMapping(
          defectDistributionWithAssignment!,
        ),
      });
      enqueueSnackbar(t('Successfully assigned images to train/dev/test.'), {
        variant: 'success',
      });
      dispatch(draft => {
        draft.currentStep = 2;
      });
    } catch (err) {
      enqueueSnackbar(err?.message);
    } finally {
      dispatch(draft => {
        draft.isMakingPostRequest = false;
      });
    }
  }, [defectDistributionWithAssignment, dispatch, enqueueSnackbar, selectMediaOption]);

  return (
    <StepContainer
      primaryButton={{
        text: 'Assign split',
        action: async () => {
          await postAutoSplit();
          selectedProject?.datasetId &&
            queryClient.invalidateQueries(
              datasetQueryKeys.mediaDetails(selectedProject?.datasetId),
            );
        },
        disabled: !defectDistributionWithAssignment || !selectMediaOption,
      }}
      secondaryButton={{
        text: 'Back',
        action: () =>
          dispatch(draft => {
            draft.currentStep = 0;
          }),
      }}
    >
      {/* Slider per defect */}
      <section className={styles.infoSection}>
        <Typography variant="subtitle1" component="div" className={styles.headerText} gutterBottom>
          {t('Set your target defect split distribution')}
        </Typography>
        {!isAnomalyDetection && (
          <div className={styles.checkBoxContainer}>
            <Checkbox
              color="primary"
              size="small"
              data-testid="adjust-defects-together-checkbox"
              checked={adjustSliderValueTogether}
              onClick={() => setAdjustSliderValueTogether(prev => !prev)}
            />
            <Typography variant="body2" component="span" className={styles.italic}>
              {t('Adjust value for all defect types together')}
            </Typography>
          </div>
        )}
        {/* Slider to split each defect */}
        <ApiResponseLoader
          response={splitSliderValuePerDefect}
          loading={!splitSliderValuePerDefect}
        >
          {result => (
            <div
              className={styles.sliderGridContainer}
              style={{ gridTemplateRows: `repeat(${Object.entries(result).length + 1}, 32px)` }}
            >
              <Typography variant="body2" className={styles.sliderGridSplitTitle}>
                {t('train / dev / test (%)')}
              </Typography>
              {Object.entries(result).map(([defectName, value], idx) => (
                <DoubleSlider
                  key={defectName}
                  name={
                    defectName === noDefectKey ? (
                      <span className={styles.italic}>{defectName}</span>
                    ) : (
                      defectName
                    )
                  }
                  value={value}
                  onChange={handleSliderChange(defectName)}
                  gridRowStart={idx + 2}
                  color={defectColorMap?.[defectName] || greyScale[500]!}
                  enableLeftSlider={!isAnomalyDetection || idx === 0}
                />
              ))}
            </div>
          )}
        </ApiResponseLoader>
      </section>
      <Divider />
      {/* Preview graphs */}
      <section className={styles.infoSection}>
        <Grid container alignItems="center" className={styles.infoHeaderMargin}>
          <Typography variant="subtitle1" component="div">
            {t('Result Preview')}
          </Typography>
          {!includeAlreadySplitMedia && (
            <>
              <Button
                id="preview-selected-images"
                color="primary"
                variant="text"
                size="small"
                className={cx(previewMode === 'selected' && styles.bold)}
                onClick={() => setPreviewMode('selected')}
              >
                {t('Selected images')}
              </Button>
              <Button
                id="preview-selected-and-split-images"
                color="primary"
                variant="text"
                size="small"
                className={cx(previewMode === 'selected+existing' && styles.bold)}
                onClick={() => setPreviewMode('selected+existing')}
              >
                {t('Selected images + Existing media with split')}
              </Button>
            </>
          )}
        </Grid>
        <DistributionPreviewGraphs
          distributionWithAssignment={defectDistributionWithAssignment}
          assignmentDeviation={assignmentDeviation}
          previewMode={previewMode}
        />
      </section>
    </StepContainer>
  );
};

export default Step2SplitDistribution;
