import CommonApi from '@/api/common_api';
import { OrgId, ProjectId } from '@clef/shared/types';
import { Dispatch } from 'redux';
import FeatureToggleAPI from '../api/feature_toggle_api';
import actionReducer from './reducer_utils';
import { ActionType, FeatureToggleState } from './types';

const LOAD_ENABLE_FEATURES_FOR_USER = 'load_enabled_features_for_user';
const RESET_ENABLE_FEATURES_FOR_USER = 'reset_enabled_features_for_user';
const LOAD_ENABLE_FEATURES_FOR_Project = 'load_enabled_features_for_project';
const LOAD_ENABLE_FEATURES_FOR_ORG = 'load_enabled_features_for_org';
const LOAD_ENABLE_FEATURES_DEFAULT = 'load_enabled_features_default';

const actions: ActionType<FeatureToggleState> = {};
const initialState: FeatureToggleState = {
  forUser: null,
  forProject: null,
  forOrg: null,
  // fetched when logged out
  defaults: null,
};

actions[LOAD_ENABLE_FEATURES_FOR_USER] = (state, { payload }) => {
  return {
    ...state,
    forUser: payload,
  };
};

actions[RESET_ENABLE_FEATURES_FOR_USER] = state => {
  return {
    ...state,
    forUser: null,
  };
};

actions[LOAD_ENABLE_FEATURES_FOR_Project] = (state, { payload }) => {
  return {
    ...state,
    forProject: {
      ...state.forProject,
      [payload.projectId]: payload.enabledFeatures,
    },
  };
};

actions[LOAD_ENABLE_FEATURES_DEFAULT] = (state, { payload }) => {
  return {
    ...state,
    defaults: payload,
  };
};

actions[LOAD_ENABLE_FEATURES_FOR_ORG] = (state, { payload }) => {
  return {
    ...state,
    forOrg: {
      ...state.forOrg,
      [payload.orgId]: payload.enabledFeatures,
    },
  };
};

function loadEnabledFeaturesForUser(enabledFeatures: string[]) {
  return {
    type: LOAD_ENABLE_FEATURES_FOR_USER,
    payload: enabledFeatures,
  };
}

export function resetEnabledFeaturesForUser() {
  return {
    type: RESET_ENABLE_FEATURES_FOR_USER,
  };
}

function loadEnabledFeaturesForOrg(orgId: number, enabledFeatures: string[]) {
  return {
    type: LOAD_ENABLE_FEATURES_FOR_ORG,
    payload: { orgId, enabledFeatures },
  };
}

function loadEnabledFeaturesForProject(projectId: ProjectId, enabledFeatures: string[]) {
  return {
    type: LOAD_ENABLE_FEATURES_FOR_Project,
    payload: { projectId, enabledFeatures },
  };
}

function loadEnabledFeaturesDefault(enabledFeatures: string[]) {
  return {
    type: LOAD_ENABLE_FEATURES_DEFAULT,
    payload: enabledFeatures,
  };
}

export const fetchEnabledFeaturesForUser = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    const enabledFeatures = await FeatureToggleAPI.getEnabledFeaturesForUser();
    if (enabledFeatures) {
      dispatch(loadEnabledFeaturesForUser(enabledFeatures));
    }
  };
};

export const fetchEnabledFeaturesForOrg = (orgId: OrgId) => {
  return async (dispatch: Dispatch, getState: any): Promise<void> => {
    const { forOrg } = getState().enabledFeatures as FeatureToggleState;
    if (forOrg && forOrg[orgId]) {
      return;
    }
    const enabledFeatures = await FeatureToggleAPI.getEnabledFeaturesForOrg(orgId);
    if (enabledFeatures) {
      dispatch(loadEnabledFeaturesForOrg(orgId, enabledFeatures));
    }
  };
};

export const fetchEnabledFeaturesForProject = (projectId: ProjectId) => {
  return async (dispatch: Dispatch, getState: any): Promise<void> => {
    const { forProject } = getState().enabledFeatures as FeatureToggleState;
    if (forProject && forProject[projectId]) {
      return;
    }
    const enabledFeatures = await FeatureToggleAPI.getEnabledFeaturesForProject(projectId);
    if (enabledFeatures) {
      dispatch(loadEnabledFeaturesForProject(projectId, enabledFeatures));
    }
  };
};

export const fetchEnableFeaturesDefault = () => {
  return async (dispatch: Dispatch, getState: any): Promise<void> => {
    const { defaults } = getState().enabledFeatures as FeatureToggleState;
    if (defaults) {
      return;
    }
    const enabledFeatures = await CommonApi.getDefaultFeatures();
    if (enabledFeatures) {
      dispatch(loadEnabledFeaturesDefault(enabledFeatures));
    }
  };
};

export default actionReducer(actions, initialState);
