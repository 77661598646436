import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Box, makeStyles } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { useAtom, useSetAtom } from 'jotai';
import classnames from 'classnames';

import { Button, Typography, useKeyPress, useLocalStorage } from '@clef/client-library';

import CLEF_PATH from '@/constants/path';
import { StepName } from '@/types/client';
import { useFirstRunExperienceWorkflowAssistantEnabled } from '@/hooks/useFeatureGate';
import {
  useUnsavedLabelCheckDecorator,
  useLabelingState,
} from '@/components/Labeling/labelingState';
import { useWorkflowAssistantState } from '@/components/WorkflowAssistant/state';
import WorkflowAssistant from '@/components/WorkflowAssistant';
import {
  useCurrentMediaStates,
  datasetSearchParamsAtom,
  annotationInstanceAtom,
  labelUpdateStateAtom,
  isLabelModeAtom,
  currentToolModeAtom,
  mediaStatesMapAtom,
  LabelUpdateState,
  useSaveAnnotations,
  selectedDefectAtom,
} from '@/uiStates/mediaDetails/pageUIStates';
import { useDatasetMediasQuery } from '@/serverStore/dataset';

const useStyles = makeStyles(theme => ({
  navigation: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0, 6),
    width: '100%',
    height: 48,
    backgroundColor: '#FFF',
    zIndex: theme.zIndex.drawer + 1,
  },
  leftContent: {
    display: 'inline-flex',
    alignItems: 'center',
    height: 32,
    paddingRight: theme.spacing(2),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  backBtn: {
    color: '#4D5761',
    backgroundColor: theme.palette.greyModern[100],
  },
  diagonalDivider: {
    width: 1,
    height: 26,
    transform: 'rotate(20deg)',
    background: theme.palette.greyModern[300],
    margin: theme.spacing(0, 3),
  },
  centerContentWrapper: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  paginationButton: {
    height: 32,
    minWidth: 32,
    padding: 0,
    backgroundColor: theme.palette.greyModern[100],
    borderRadius: 10,
  },
  paginationValue: {
    margin: theme.spacing(0, 4),
  },
  right: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  },
  detailsButton: {
    width: 99,
    height: 36,
    marginLeft: theme.spacing(2),
    backgroundColor: '#E5E7EB',
  },
  detailsDrawer: {
    top: theme.spacing(12),
  },
  drawerPaper: {
    backgroundColor: '#E5E7EB',
  },
  onboardingWrapper: {
    border: `4px solid ${theme.palette.blue[100]}`,
    background: theme.palette.blue[50],
  },
  onboardingInnerWrapper: {
    border: `1px solid ${theme.palette.blue[500]}`,
    background: theme.palette.blue[25],
  },
}));

interface NavigationProps {
  onPageBack: () => void;
}

const Navigation: React.FC<NavigationProps> = ({ onPageBack }) => {
  const history = useHistory();

  const styles = useStyles();

  const {
    state: { toolMode, selectedDefect },
  } = useLabelingState();

  const [shownGoNextTip] = useLocalStorage('skipAfterFirstActionTip');

  const [datasetSearchParams] = useAtom(datasetSearchParamsAtom);
  const [annotationInstance] = useAtom(annotationInstanceAtom);
  const setLabelUpdateState = useSetAtom(labelUpdateStateAtom);
  const [isLabelMode] = useAtom(isLabelModeAtom);
  const setCurrentToolMode = useSetAtom(currentToolModeAtom);
  const [mediaStatesMap] = useAtom(mediaStatesMapAtom);
  const setSelectedDefect = useSetAtom(selectedDefectAtom);

  const currentMediaStates = useCurrentMediaStates();

  const saveAnnotations = useSaveAnnotations();

  const { data: datasetMedias = [] } = useDatasetMediasQuery(
    {
      sortOptions: datasetSearchParams.sortOptions,
      columnFilterMap: datasetSearchParams.columnFilterMap,
      metadataFilterMap: datasetSearchParams.metadataFilterMap,
      includeMediaStatus: true, // task creation needs here
    },
    datasetSearchParams && !annotationInstance,
  );

  const mediaIndex = useMemo(() => {
    if (!currentMediaStates.mediaDetails || datasetMedias.length === 0) return -1;
    return datasetMedias.findIndex(media => media.id === currentMediaStates.mediaDetails!.id);
  }, [currentMediaStates.mediaDetails, datasetMedias]);

  const goToPreviousMedia = () => {
    if (mediaIndex > 0) {
      saveAnnotations();
      setLabelUpdateState(LabelUpdateState.Unchanged);
      setSelectedDefect(selectedDefect ?? undefined);

      if (isLabelMode) {
        setCurrentToolMode(toolMode);
      } else {
        setCurrentToolMode(undefined);
      }

      const nextMedia = datasetMedias[mediaIndex - 1];
      history.push(`${CLEF_PATH.data.mediaDetails}/${nextMedia.id}${window.location.search}`);
    }
  };
  const handleGoToPreviousMedia = useUnsavedLabelCheckDecorator(goToPreviousMedia);
  const goToNextMedia = () => {
    if (mediaIndex < datasetMedias.length - 1) {
      saveAnnotations();
      setLabelUpdateState(LabelUpdateState.Unchanged);
      setSelectedDefect(selectedDefect ?? undefined);

      if (isLabelMode) {
        setCurrentToolMode(toolMode);
      } else {
        setCurrentToolMode(undefined);
      }

      const nextMedia = datasetMedias[mediaIndex + 1];
      history.push(`${CLEF_PATH.data.mediaDetails}/${nextMedia.id}${window.location.search}`);
    }
  };
  const handleGoToNextMedia = useUnsavedLabelCheckDecorator(goToNextMedia);
  useKeyPress('left', handleGoToPreviousMedia);
  useKeyPress('right', handleGoToNextMedia);

  const {
    state: { step, goButtonClicked },
    dispatch: workflowAssistantDispatch,
  } = useWorkflowAssistantState();

  const workflowAssistantEnabled = useFirstRunExperienceWorkflowAssistantEnabled();

  const showWorkflowAssistant =
    workflowAssistantEnabled && step !== null && step?.stepName !== StepName.Complete;

  // If go button is clicked and the current step is not label, close this dialog and mark go button unclicked
  useEffect(() => {
    if (goButtonClicked && step?.stepName !== StepName.Label) {
      onPageBack();

      workflowAssistantDispatch(state => {
        state.goButtonClicked = false;
      });
    }
  }, [goButtonClicked, onPageBack, step?.stepName, workflowAssistantDispatch]);

  return (
    <Box className={styles.navigation}>
      <Box className={styles.leftContent}>
        <Button
          id="back-from-media-details"
          onClick={() => {
            onPageBack();
            const initialPageIndex =
              datasetSearchParams.sortOptions.offset / datasetSearchParams.sortOptions.limit;
            history.push(`${CLEF_PATH.data.dataBrowser}?initialPageIndex=${initialPageIndex}`);
          }}
          startIcon={<ArrowBackIcon htmlColor="#4D5761" />}
        >
          <Typography maxWidth={300}>{currentMediaStates?.mediaDetails?.name || ''}</Typography>
        </Button>
      </Box>
      <Box className={styles.centerContentWrapper}>
        {/* pagination is not needed for instance media details */}
        {!annotationInstance && datasetMedias.length > 0 && mediaIndex > -1 && (
          <Grid item container alignItems="center">
            <Button
              id="media-details-go-prev"
              className={styles.paginationButton}
              size="small"
              disabled={mediaIndex === 0}
              onClick={handleGoToPreviousMedia}
              aria-label="Previous media"
            >
              <KeyboardArrowLeft />
            </Button>
            <Typography variant="body_bold" className={styles.paginationValue}>
              {t('{{current}} / {{total}}', {
                current: mediaIndex + 1,
                total: datasetMedias.length,
              })}
            </Typography>
            <Box
              className={classnames({
                [styles.onboardingWrapper]:
                  !shownGoNextTip && Object.keys(mediaStatesMap).length === 1,
              })}
            >
              <Box
                className={classnames({
                  [styles.onboardingInnerWrapper]:
                    !shownGoNextTip && Object.keys(mediaStatesMap).length === 1,
                })}
              >
                <Button
                  id="media-details-go-next"
                  className={styles.paginationButton}
                  size="small"
                  disabled={mediaIndex === datasetMedias.length - 1}
                  onClick={handleGoToNextMedia}
                  aria-label="Next media"
                >
                  <KeyboardArrowRight />
                </Button>
              </Box>
            </Box>
          </Grid>
        )}
      </Box>
      <Box className={styles.right}>
        {showWorkflowAssistant && <WorkflowAssistant conciseVersion />}
      </Box>
    </Box>
  );
};

export default Navigation;
