import React, { useState } from 'react';
import { Box, Popover, makeStyles, Slider, TextField, Tooltip } from '@material-ui/core';
import { Button, IconButton, Typography } from '@clef/client-library';
import { RegisteredModel } from '@clef/shared/types';
import { BundleInfo } from '@/api/model_api';
import { CONFIDENCE_THRESHOLD_OPTIONS } from '@clef/shared/constants';

import { useGetConfusionMatrixQuery } from '@/serverStore/modelAnalysis';
import { range } from 'lodash';
import InfoIcon from '@/pages/DataBrowser/icon/InfoIcon';
import { ConfusionMatrixTable } from './ConfusionMatrixTable';
import LoadingProgress from '../LoadingProgress';

const useStyles = makeStyles(theme => ({
  chartContainer: {
    minWidth: 700,
    padding: theme.spacing(6),
    gap: theme.spacing(5),
  },
  actionItemsContainer: {
    padding: theme.spacing(6, 12, 8, 12),
  },
  actionItemCancel: {
    margin: theme.spacing(0, 4),
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '20px',
    whiteSpace: 'nowrap',
    fontSize: 14,
  },
  title: {
    color: theme.palette.greyModern[500],
    fontWeight: 700,
  },
  thresholdWrapper: {
    width: '300px',
    display: 'flex',
    columnGap: '12px',
  },
  thresholdInputRoot: {
    width: 44,
    height: 36,
  },
  thresholdInput: {
    textAlign: 'center',
    padding: 0,
    fontFamily: theme.typography.body1.fontFamily,
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },
  sliderRoot: {
    margin: 'auto 0',
    flex: 1,
  },
  sliderRail: {
    color: theme.palette.greyModern[400],
    borderRadius: 4,
    height: 4,
  },
  sliderTrack: {
    color: '#4B5565',
    borderRadius: 4,
    height: 4,
  },
  sliderMark: {
    borderRadius: 2,
    height: 4,
    width: 4,
    color: `${theme.palette.common.white} !important`,
    backgroundColor: `${theme.palette.common.white} !important`,
    opacity: `1 !important`,
  },
  sliderMarkLabel: {
    top: 20,
  },
  sliderThumb: {
    width: 16,
    height: 16,
    background: '#FFFFFF',
    border: '1.5px solid #4B5565',
    borderRadius: 12,
    marginTop: -7,
    '&:hover': {
      boxShadow: '0px 0px 0px 8px rgb(75, 85, 101, .16)',
    },
    '&.MuiSlider-active': {
      boxShadow: '0px 0px 0px 14px rgb(75, 85, 101, .16)',
    },
  },
  sliderActive: {
    boxShadow: '0px 0px 0px 14px rgb(75, 85, 101, .16)',
  },
  markLabelTooltipLayer: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: 'transparent',
    transform: 'translate(25%, -100%)',
  },
}));

export type AdjustThresholdDropdownProps = {
  model?: RegisteredModel & { bundles?: BundleInfo[] };
  initialThreshold: number;
  evaluationSetId?: number;
  anchorEl: any;
  handleClose: () => void;
  onSave: (threshold: number) => void;
  onCancel: () => void;
};

const AdjustThresholdDropdown = (props: AdjustThresholdDropdownProps) => {
  const { model, initialThreshold, anchorEl, handleClose, onSave, onCancel, evaluationSetId } =
    props;
  const [threshold, setThreshold] = useState(initialThreshold);
  const [finalThreshold, setFinalThreshold] = useState<number>(initialThreshold);
  const { data, isFetching } = useGetConfusionMatrixQuery(
    model?.id,
    evaluationSetId,
    finalThreshold,
  );
  const isMatrixQueryLoading = isFetching || (data && data.status !== 'completed');
  const styles = useStyles();
  const { thresholdsMin, thresholdsMax, thresholdsStep } = CONFIDENCE_THRESHOLD_OPTIONS;
  const thresholds = range(thresholdsMin, thresholdsMax, thresholdsStep).map(
    x => Math.round(x * 1000) / 1000,
  );

  return (
    <Popover
      id="adjust-threshold-dropdown"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        marginBottom={0.5}
        className={styles.chartContainer}
      >
        <Typography variant="h4">{t('Adjust Confidence Threshold')}</Typography>
        <Box display="flex" alignItems="center">
          <Box className={styles.titleWrapper} mr={3}>
            <Typography display="inline" className={styles.title}>
              {t('Confidence Threshold')}
            </Typography>
            <Tooltip
              arrow
              placement="top"
              title={t(
                'Your model makes several predictions. You can adjust the Confidence Threshold to filter out the less confident predictions so you can focus on the most confident ones.',
              )}
            >
              <IconButton id="confidence-threshold-info" size="small">
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Box className={styles.thresholdWrapper}>
            <Slider
              min={thresholdsMin}
              max={thresholdsMax - thresholdsStep}
              step={thresholdsStep}
              value={threshold}
              // disabled={isFetching}
              marks={model?.bundles
                ?.map(bundle => {
                  return { value: bundle.threshold, label: `${bundle.threshold} (saved)` };
                })
                ?.map(mark => ({
                  ...mark,
                  label: (
                    <Tooltip arrow={true} title={mark.label} placement="bottom">
                      <Box className={styles.markLabelTooltipLayer} />
                    </Tooltip>
                  ),
                }))}
              onChange={(_, newThreshold) => {
                setThreshold(newThreshold as number);
              }}
              onChangeCommitted={(_, value) => !Array.isArray(value) && setFinalThreshold(value)}
              classes={{
                root: styles.sliderRoot,
                rail: styles.sliderRail,
                track: styles.sliderTrack,
                thumb: styles.sliderThumb,
                active: styles.sliderActive,
                mark: styles.sliderMark,
                markActive: styles.sliderMark,
                markLabel: styles.sliderMarkLabel,
              }}
            />
            <TextField
              variant="outlined"
              type="number"
              value={threshold}
              InputProps={{
                classes: {
                  root: styles.thresholdInputRoot,
                  input: styles.thresholdInput,
                },
              }}
              inputProps={{
                className: styles.thresholdInput,
                step: thresholdsStep,
                min: thresholdsMin,
                max: thresholdsMax - thresholdsStep,
                'data-testid': 'threshold-input',
              }}
              onChange={e => {
                let value = Number(e.target.value || 0);
                const max = thresholdsMax - thresholdsStep;
                if (value > max) {
                  value = max;
                } else if (value < thresholdsMin) {
                  value = thresholdsMin;
                } else if (!thresholds.includes(value)) {
                  value =
                    thresholds.find(item => Math.abs(item - value) <= thresholdsStep * 0.55) || 0;
                }

                setThreshold(value || 0);
              }}
            />
          </Box>
        </Box>
        <Box minHeight={300}>
          {isMatrixQueryLoading ? (
            <LoadingProgress />
          ) : (
            <ConfusionMatrixTable
              model={model}
              evaluationSetId={evaluationSetId}
              threshold={finalThreshold}
            />
          )}
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-end"
        className={styles.actionItemsContainer}
      >
        <Button
          id="cancel-threshold"
          color="default"
          className={styles.actionItemCancel}
          onClick={() => {
            onCancel();
            setThreshold(initialThreshold);
          }}
        >
          {t('Cancel')}
        </Button>
        {initialThreshold !== threshold && (
          <Button
            id="save-threshold"
            color="primary"
            variant="contained"
            onClick={() => onSave(threshold)}
          >
            {t('Generate a New Report at {{threshold}}', { threshold })}
          </Button>
        )}
      </Box>
    </Popover>
  );
};

export default AdjustThresholdDropdown;
