import React from 'react';
import { Typography } from '@material-ui/core';
import DropFileZone from '../components/DropFileZone';
import { useAppDispatch } from '../../../../store';
import { addFileToClassifiedFolders } from '../../../../store/uploadState';
import { useUploadStyles } from '../styles';
import { useDefectSelector } from '../../../../store/defectState/actions';
import { AnomalyDefectionClassName } from '@clef/shared/constants';
import { upperFirst } from 'lodash';

export interface AnomalyDetectionDropFileZoneProps {
  type: AnomalyDefectionClassName;
}

const AnomalyDetectionDropFileZone: React.FC<AnomalyDetectionDropFileZoneProps> = ({ type }) => {
  const styles = useUploadStyles();

  const allDefects = useDefectSelector();
  const dispatch = useAppDispatch();

  return (
    <DropFileZone
      dataTestId="anomaly-detection-upload-input"
      onDrop={files =>
        dispatch(
          addFileToClassifiedFolders({
            files: files,
            existingDefects: allDefects,
            folder: type,
          }),
        )
      }
    >
      <Typography variant="h3" className={styles.headerText}>
        {t('Upload {{type}} Images', {
          type: upperFirst(type),
        })}
      </Typography>
      <Typography variant="body1" component="div" className={styles.dropzoneText}>
        {t('Drag and drop ')}
        <strong>{t('folders/images ')}</strong>
        {t('here, or click to select files')}
      </Typography>
    </DropFileZone>
  );
};

export default AnomalyDetectionDropFileZone;
