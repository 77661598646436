import React from 'react';
import cx from 'classnames';
import { Badge, Tabs, Tab, Typography, makeStyles, Tooltip } from '@material-ui/core';
import useStateSyncSearchParams from '../../hooks/useStateSyncSearchParams';
import { Theme } from '@material-ui/core';

export enum TabsType {
  Card = 'card',
  Line = 'line',
}

const useStyles = makeStyles<Theme, { type: TabsType }>(theme => ({
  tabsRoot: {
    minHeight: 30,
  },
  tabRoot: {
    minHeight: 36,
    padding: '2px 10px',
    overflow: 'inherit',
  },
  tabItem: {
    color: theme.palette.common.black,
    width: 'auto',
    minWidth: 'initial',
    padding: '0',
    marginRight: '28px',
  },
  tab: {
    color: props =>
      props.type === TabsType.Card ? theme.palette.grey[500] : theme.palette.common.black,
    width: 'auto',
    minWidth: 'initial',
    border: props =>
      props.type === TabsType.Card ? `1px solid ${theme.palette.indigoBlue[200]}` : 'none',
    padding: props => (props.type === TabsType.Card ? theme.spacing(2, 5) : '0'),
    marginRight: props => (props.type === TabsType.Card ? 0 : '28px'),
  },
  leftBorder: {
    borderRadius: '12px 0 0 12px',
  },
  rightBorder: {
    borderRadius: '0 12px 12px 0',
  },
  selectedTab: {
    color: props =>
      props.type === TabsType.Card ? theme.palette.indigoBlue[900] : theme.palette.blue[600],
    backgroundColor: props =>
      props.type === TabsType.Card ? theme.palette.indigoBlue[100] : 'inherit',
  },
  indicator: {
    backgroundColor: props =>
      props.type === TabsType.Card ? 'transparent' : theme.palette.blue[600],
  },
  tabPanel: {
    paddingTop: theme.spacing(5),
    width: '100%',
  },
  titleText: {
    fontFamily: 'Commissioner, sans-serif',
    fontWeight: props => (props.type === TabsType.Card ? 700 : 600),
    fontSize: props => (props.type === TabsType.Card ? 12 : 15),
    lineHeight: props => (props.type === TabsType.Card ? '16px' : '20px'),
  },
  withIcon: {
    display: 'inline-flex',
    alignItems: 'center',
  },
}));

export interface ITab {
  key: string;
  title: string;
  disabled?: boolean;
  badgeNumber?: number;
  component: JSX.Element | null;
  endIcon?: JSX.Element;
  onClick?: () => void | undefined;
  tooltip?: string;
}

export type TabSetter = (tabNumber: number) => void;

interface Props {
  tabs: ITab[];
  type?: TabsType;
  disableSearchParam?: boolean;
  onChange?: (tabKey: string) => void;
  variant?: 'standard' | 'scrollable' | 'fullWidth';
  defaultTab?: string;
  lockedTabKey?: string;
  classes?:
    | {
        tabPanel?: string;
        tabItem?: string;
        selectedTab?: string;
        indicator?: string;
        tabLabel?: string;
      }
    | undefined;
  tabQueryKey?: string;
}

const TabManager: React.FC<Props> = ({
  tabs,
  type = TabsType.Line,
  onChange,
  variant = 'scrollable',
  disableSearchParam,
  defaultTab,
  lockedTabKey,
  classes = {},
  tabQueryKey = 'tab',
}: Props) => {
  const styles = useStyles({ type });
  const [selectedTab, setSelectedTab] = useStateSyncSearchParams(
    tabQueryKey,
    defaultTab ?? tabs?.[0]?.key,
    disableSearchParam,
  );
  const renderTab = tabs.find(tab => tab.key === selectedTab);

  return (
    <>
      <Tabs
        variant={variant}
        value={selectedTab}
        onChange={(_: any, newTabKey: string): void => {
          if (newTabKey === lockedTabKey) return;
          setSelectedTab(newTabKey);
          onChange?.(newTabKey);
        }}
        classes={{
          indicator: classes.indicator || styles.indicator,
          root: styles.tabsRoot,
        }}
      >
        {tabs.map(({ key, title, badgeNumber, disabled, endIcon, onClick, tooltip }, index) => (
          <Tab
            label={
              <Tooltip title={tooltip ?? ''} arrow placement="top">
                <Badge badgeContent={badgeNumber} color="secondary" invisible={!badgeNumber}>
                  <Typography
                    variant={type === TabsType.Card ? 'body2' : 'h4'}
                    className={cx(styles.titleText, classes.tabLabel, {
                      [styles.withIcon]: endIcon,
                    })}
                  >
                    {title}
                    {endIcon && endIcon}
                  </Typography>
                </Badge>
              </Tooltip>
            }
            className={cx(
              classes.tabItem,
              styles.tab,
              selectedTab === key && (classes.selectedTab || styles.selectedTab),
              {
                [styles.leftBorder]: type === TabsType.Card && index === 0,
                [styles.rightBorder]: type === TabsType.Card && index === tabs.length - 1,
              },
            )}
            key={key}
            value={key}
            disabled={disabled}
            classes={{ root: styles.tabRoot }}
            onClick={onClick}
            style={tooltip ? { pointerEvents: 'auto' } : undefined}
          />
        ))}
      </Tabs>
      {renderTab && (
        // pass key here to ensure we get a full rerender
        <div className={classes?.tabPanel ? classes.tabPanel : styles.tabPanel} key={renderTab.key}>
          {renderTab.component}
        </div>
      )}
    </>
  );
};

export default TabManager;
