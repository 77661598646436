import { BaseAPI } from './base_api';
import { ApiInterfaceMap } from '@clef/shared/types';

class APICommandAPI extends BaseAPI {
  async getInterfaceMap(endpointId?: string): Promise<ApiInterfaceMap> {
    return this.get('api_interface', { endpointId }, true);
  }
}

export default new APICommandAPI('deployment');
