import { Box, Tooltip } from '@material-ui/core';
import React from 'react';
import useHeaderStyles from './styles';
import cx from 'classnames';

interface SnowflakeSyncTaskProgressBarProps {
  stats: { total: number; inserted: number; duplicated: number; failed: number };
}

const calculatePercentage = (value: number, total: number): number => {
  return total > 0 ? (value / total) * 100 : 0;
};

const SnowflakeSyncTaskProgressBar: React.FC<SnowflakeSyncTaskProgressBarProps> = ({
  stats: { total, inserted, duplicated, failed },
}) => {
  const classes = useHeaderStyles();
  const duplicatedPercentage = calculatePercentage(duplicated, total);
  const failedPercentage = calculatePercentage(failed, total);
  const completedPercentage = calculatePercentage(inserted, total);
  const remainingPercentage = 100 - duplicatedPercentage - failedPercentage - completedPercentage;

  return (
    <Box className={classes.progressBarContainer}>
      <Tooltip title={`Synced: ${inserted} (${completedPercentage.toFixed(1)}%)`}>
        <Box
          width={`${completedPercentage}%`}
          className={cx(classes.progressBarCommon, classes.progressBarInserted)}
        />
      </Tooltip>
      <Tooltip title={`Duplicated: ${duplicated} (${duplicatedPercentage.toFixed(1)}%)`}>
        <Box
          width={`${duplicatedPercentage}%`}
          className={cx(classes.progressBarCommon, classes.progressBarDuplicated)}
        />
      </Tooltip>
      <Tooltip title={`Failed: ${failed} (${failedPercentage.toFixed(1)}%)`}>
        <Box
          width={`${failedPercentage}%`}
          className={cx(classes.progressBarCommon, classes.progressBarFailed)}
        />
      </Tooltip>
      <Tooltip
        title={`Remaining: ${total - duplicated - failed - inserted} (${remainingPercentage.toFixed(
          1,
        )}%)`}
      >
        <Box
          width={`${remainingPercentage}%`}
          className={cx(classes.progressBarCommon, classes.progressBarRemaining)}
        />
      </Tooltip>
    </Box>
  );
};
export default SnowflakeSyncTaskProgressBar;
