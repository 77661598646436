import CLEF_PATH from '@/constants/path';
import { ClientFeatures, useFeatureGateEnabled } from '@/hooks/useFeatureGate';
import { useGetEndpointListQuery, useGetJobDetailListQuery } from '@/serverStore/endpoints';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useStateSyncSearchParams } from '@clef/client-library';
import { EndpointData } from '@clef/shared/types';
import { Box, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useImmer } from 'use-immer';
import {
  ProjectModelDetailsContext,
  initialState,
} from '../DataBrowser/ProjectModelDetails/states';
import CreateEndpointDialog from './components/CreateEndpointDialog';
import DeployModelRunner from './components/DeployModelRunner';
import DeployModelRunnerForSnowflake from './components/DeployModelRunnerForSnowflake';
import DownloadLandingEdgeConfirmDialog from './components/DownloadLandingEdgeConfirmDialog';
import { DownloadList } from './components/DownloadList';
import { EmptyDeploymentPage } from './components/EmptyPageState';
import { EndpointSection } from './components/EndpointSection';
import { EndpointsSidebar, SidebarSections } from './components/EndpointsSidebar';

export type DeploymentInferencePageProps = {};

export const FREDeploymentInferencePageInternal: React.FC<DeploymentInferencePageProps> = () => {
  const { id: projectId } = useGetSelectedProjectQuery().data ?? {};

  const [state, dispatchModelStates] = useImmer(initialState);
  const history = useHistory();

  const [createEndpointDialogOpen, setCreateEndpointDialogOpen] = useState(false);
  const enableSnowflakeModelRunner = useFeatureGateEnabled(ClientFeatures.SnowflakeModelRunner);

  const [activeSection, setActiveSection] = useStateSyncSearchParams(
    'sidebar_section',
    SidebarSections.CloudDeployment,
  );
  const [, setSelectedEndpointId] = useStateSyncSearchParams('selected_endpoint_id', '');

  const [downloadLandingEdgeDialogOpen, setDownloadLandingEdgeDialogOpen] = useState(false);
  const [deployModelRunnerOpen, setDeployModelRunnerOpen] = useState(false);

  const activateItem = (section: SidebarSections, endpointData?: EndpointData) => {
    setActiveSection(section);
    setSelectedEndpointId(endpointData?.endpoint.id ?? '');
  };

  const openModelLink = () => {
    setDeployModelRunnerOpen(false);
    history.push(CLEF_PATH.modelsV2.list);
  };

  if (!projectId) return null;

  return (
    <ProjectModelDetailsContext.Provider value={{ state, dispatch: dispatchModelStates }}>
      <Box display="flex" minHeight="100%">
        <EndpointsSidebar
          activeSection={activeSection as SidebarSections}
          onSidebarItemClick={activateItem}
          onEndpointCreate={() => {
            setCreateEndpointDialogOpen(true);
          }}
          onDownloadLandingEdgeButtonClick={() => setDownloadLandingEdgeDialogOpen(true)}
          onDeployModelRunnerButtonClick={() => setDeployModelRunnerOpen(true)}
        />

        {activeSection === SidebarSections.DownloadList && <DownloadList />}
        {[
          SidebarSections.CloudDeployment,
          SidebarSections.StaticDeployment,
          SidebarSections.EdgeDeployment,
        ].includes(activeSection as SidebarSections) && (
          <EndpointSection setCreateEndpointDialogOpen={setCreateEndpointDialogOpen} />
        )}
      </Box>
      <CreateEndpointDialog
        open={createEndpointDialogOpen}
        onClose={() => setCreateEndpointDialogOpen(false)}
      />
      <DownloadLandingEdgeConfirmDialog
        open={downloadLandingEdgeDialogOpen}
        onClose={() => setDownloadLandingEdgeDialogOpen(false)}
      />
      {enableSnowflakeModelRunner ? (
        <DeployModelRunnerForSnowflake
          open={deployModelRunnerOpen}
          onClose={() => setDeployModelRunnerOpen(false)}
          openModelLink={openModelLink}
        />
      ) : (
        <DeployModelRunner
          open={deployModelRunnerOpen}
          onClose={() => setDeployModelRunnerOpen(false)}
          openModelLink={openModelLink}
          onViewApiKeyAndSecretButtonClick={() => {
            setDeployModelRunnerOpen(false);
            history.push(CLEF_PATH.organizationSettings + '?tab=api_key_v2');
          }}
        />
      )}
    </ProjectModelDetailsContext.Provider>
  );
};

export const FREDeploymentInferencePage: React.FC<DeploymentInferencePageProps> = () => {
  const { id: projectId, orgId } = useGetSelectedProjectQuery().data ?? {};
  const { data: endpointsData, isLoading: endpointsLoading } = useGetEndpointListQuery(
    projectId ?? 0,
  );
  const { data: bundles, isLoading: bundlesLoading } = useGetJobDetailListQuery(
    projectId ?? 0,
    orgId ?? 0,
  );
  const [activeSection] = useStateSyncSearchParams(
    'sidebar_section',
    SidebarSections.CloudDeployment,
  );
  const [createEndpointDialogOpen, setCreateEndpointDialogOpen] = useState(false);
  const [downloadLandingEdgeDialogOpen, setDownloadLandingEdgeDialogOpen] = useState(false);

  if (
    !endpointsData?.length &&
    (activeSection !== SidebarSections.DownloadList || !bundles?.length)
  ) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <EmptyDeploymentPage
          endpointsLoading={endpointsLoading || bundlesLoading}
          onCreateEndpoint={() => setCreateEndpointDialogOpen(true)}
          onDownloadEdge={() => setDownloadLandingEdgeDialogOpen(true)}
        />
        <CreateEndpointDialog
          open={createEndpointDialogOpen}
          onClose={() => setCreateEndpointDialogOpen(false)}
        />
        <DownloadLandingEdgeConfirmDialog
          open={downloadLandingEdgeDialogOpen}
          onClose={() => setDownloadLandingEdgeDialogOpen(false)}
        />
      </Grid>
    );
  }
  return <FREDeploymentInferencePageInternal />;
};
