import React, { useCallback, useState } from 'react';
import FullscreenDialog from '../../FullScreenDialog';

import { useUploadStyles } from '../styles';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { UploadStage } from '../../../../store/uploadState/types';
import { useDialog } from '../../../Layout/components/useDialog';
import { UploadFullscreenProps } from '..';
import ObjectDetectionUploadDropFileZone from './ObjectDetectionUploadDropFileZone';
import UploadWrapper from '../components/UploadWrapper';
import { Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { getUploadStats } from '../../../../store/uploadState/utils';
import { resetUploadState } from '../../../../store/uploadState';
import MediaUploadProgress from '../components/MediaUploadProgress';
import WebcamFileSwitchButton from '../../../../components/WebcamFileSwitchButton';
import WebcamCapture from '../components/WebcamCapture';
import QRCodeUploadButton from '@/components/QRCodeUploadButton';
import { ClientFeatures, useFeatureGateEnabled } from '@/hooks/useFeatureGate';

const ObjectDetectionUploadContent: React.FC<{
  mediaCount?: number;
  mediaLimit?: number | null;
  mediaCapacity?: number | null;
  hasNoMediaCapacity?: boolean;
  isNewCreditSystem?: boolean;
  handleDisableEscapeKeyDown?: (disableEscapeKeyDown: boolean) => void;
  onClose?: () => void;
  initialUseWebcam?: boolean;
}> = ({
  mediaCount,
  mediaLimit,
  mediaCapacity,
  hasNoMediaCapacity,
  isNewCreditSystem,
  handleDisableEscapeKeyDown,
  onClose,
  initialUseWebcam = false,
}) => {
  const styles = useUploadStyles();
  const [useWebcam, setUseWebcam] = useState<boolean>(initialUseWebcam);
  const isWebcamEnabled = !useFeatureGateEnabled(ClientFeatures.DisableWebcam);

  return (
    <div className={styles.contentContainer}>
      <UploadWrapper handleDisableEscapeKeyDown={handleDisableEscapeKeyDown} onClose={onClose}>
        <Typography variant="h1" align="center" className={styles.headerText}>
          {t('Upload Images')}
        </Typography>
        <MediaUploadProgress
          mediaCount={mediaCount}
          mediaLimit={mediaLimit}
          isNewCreditSystem={isNewCreditSystem}
          className={styles.mediaCountProgressBar}
        />

        {/* Drop in zone or Webcam */}
        {useWebcam && isWebcamEnabled ? (
          <WebcamCapture
            disabled={hasNoMediaCapacity}
            fileCapacity={mediaCapacity}
            fileLimit={mediaLimit}
            isNewCreditSystem={isNewCreditSystem}
          />
        ) : (
          <ObjectDetectionUploadDropFileZone
            disabled={hasNoMediaCapacity}
            fileCapacity={mediaCapacity}
            fileLimit={mediaLimit}
            isNewCreditSystem={isNewCreditSystem}
          />
        )}
        <div className={styles.webcamFileSwitchButtonWrapper}>
          {isWebcamEnabled && (
            <WebcamFileSwitchButton
              useWebcam={useWebcam}
              onClick={() => setUseWebcam(prev => !prev)}
            />
          )}
          <QRCodeUploadButton />
        </div>
      </UploadWrapper>
    </div>
  );
};

const ObjectDetectionUploadDialog: React.FC<UploadFullscreenProps> = props => {
  const { open, onClose, initialUseWebcam } = props;
  const { uploadStage, uploadData } = useTypedSelector(state => state.uploadState);
  const { showConfirmationDialog } = useDialog();
  const dispatch = useDispatch();
  const { retryableCount } = getUploadStats(uploadData);
  const [disableEscapeKeyDown, setDisableEscapeKeyDown] = useState(false);

  const onDialogClose = useCallback(() => {
    let confirmMessage: string | undefined;
    if (uploadStage === UploadStage.NotStarted && uploadData.length) {
      confirmMessage = t('There are still media not uploaded');
    } else if (uploadStage === UploadStage.UploadFulfilledWithFailure && retryableCount > 0) {
      confirmMessage = t('There are media failed to upload');
    }
    if (confirmMessage) {
      showConfirmationDialog({
        title: t('Are you sure you want to leave'),
        content: confirmMessage,
        onConfirm: () => {
          onClose();
          dispatch(resetUploadState());
        },
        confirmText: t('Leave'),
        color: 'primary',
      });
    } else {
      onClose();
      dispatch(resetUploadState());
    }
  }, [dispatch, onClose, retryableCount, showConfirmationDialog, uploadData.length, uploadStage]);

  return (
    <FullscreenDialog
      open={open}
      onClose={uploadStage !== UploadStage.UploadInProgress ? onDialogClose : undefined}
      onMinimize={uploadStage === UploadStage.UploadInProgress ? onClose : undefined}
      coverPendoGuide
      disableEscapeKeyDown={disableEscapeKeyDown}
    >
      {open && (
        <ObjectDetectionUploadContent
          handleDisableEscapeKeyDown={value => {
            setDisableEscapeKeyDown(value);
          }}
          onClose={onClose}
          initialUseWebcam={initialUseWebcam}
        />
      )}
    </FullscreenDialog>
  );
};

export default ObjectDetectionUploadDialog;
