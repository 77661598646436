import React, { CSSProperties, useCallback, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Box, Card, CircularProgress } from '@material-ui/core';
import { Typography } from '@clef/client-library';
import cx from 'classnames';

import useStyles from './newStyles';
import { useCreateProjectMutation } from '@/serverStore/projects';
import { LabelType } from '@clef/shared/types';
import { queryClient } from '@/serverStore';
import { usageQueryKey } from '@/serverStore/usage';
import { useTypedSelector } from '@/hooks/useTypedSelector';

export interface CreateProjectCardProps {
  style?: CSSProperties;
}

export const CreateProjectCard: React.FC<CreateProjectCardProps> = ({ style }) => {
  const styles = useStyles();
  const createProject = useCreateProjectMutation();
  const [isCreating, setIsCreating] = useState(false);
  const orgId = useTypedSelector(state => state.login.user)?.orgId!;

  const handleCreateProject = useCallback(
    async (labelType?: LabelType) => {
      if (isCreating) {
        return;
      }
      setIsCreating(true);
      await createProject(labelType);
      queryClient.invalidateQueries(usageQueryKey.summary(orgId));
      setIsCreating(false);
    },
    [createProject, isCreating, orgId],
  );

  return (
    <Box position="relative">
      <Card
        onClick={() => handleCreateProject()}
        className={cx(styles.container, styles.createProjectCardContainer)}
        style={{
          ...style,
          pointerEvents: isCreating ? 'none' : 'auto',
          opacity: isCreating ? 0.7 : 1,
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%">
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            {isCreating ? (
              <CircularProgress className={styles.createProjectCardAddIcon} />
            ) : (
              <AddIcon className={styles.createProjectCardAddIcon} />
            )}

            <Typography variant="h2" className={styles.createProjectCardText}>
              {t('Create Project')}
            </Typography>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};
