import { Button } from '@clef/client-library';
import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import qrcodeIcon from '@/images/qrcode_icon.svg';
import MobileUploadDialog from '@/pages/deployment/components/MobileUploadDialog';
import { ClientFeatures, useFeatureGateEnabled } from '@/hooks/useFeatureGate';

type QRCodeUploadButtonProps = {};

const QRCodeUploadButton: React.FC<QRCodeUploadButtonProps> = () => {
  const [openMobileUploadDialog, setOpenMobileUploadDialog] = useState(false);
  const isQrCodeEnabled = !useFeatureGateEnabled(ClientFeatures.DisableQrCode);
  if (!isQrCodeEnabled) {
    return null;
  }

  return (
    <Box marginBottom={2}>
      <Button
        id="qrcode-upload-button"
        variant="outlined"
        startIcon={<img src={qrcodeIcon} width={18} height={18} />}
        onClick={() => setOpenMobileUploadDialog(true)}
      >
        {t('Upload from Your Phone')}
      </Button>
      {openMobileUploadDialog && (
        <MobileUploadDialog onClose={() => setOpenMobileUploadDialog(false)} />
      )}
    </Box>
  );
};

export default QRCodeUploadButton;
