import { useQuery } from '@tanstack/react-query';
import { ApiErrorType } from '@/api/base_api';
import APICommandAPI from '@/api/api_command_api';
import { useSnackbar } from 'notistack';

export const apiCommandQueryKeys = {
  all: ['apiCommand'] as const,
  interfaceMap: (endpointId?: string) =>
    [...apiCommandQueryKeys.all, 'interfaceMap', endpointId] as const,
};

export const useApiCommandMap = (params: { endpointId?: string }) => {
  const { enqueueSnackbar } = useSnackbar();

  return useQuery({
    queryKey: apiCommandQueryKeys.interfaceMap(params.endpointId),
    queryFn: async () => {
      const res = await APICommandAPI.getInterfaceMap(params.endpointId);
      return res ?? {};
    },
    onError: async (e: ApiErrorType) => {
      enqueueSnackbar(
        t('Failed to load API commands, {{errorMessage}}', { errorMessage: e.message }),
        { variant: 'error', preventDuplicate: true, autoHideDuration: 12000 },
      );
    },
  });
};
