export enum APICommandType {
  Python = 'python',
  Javascript = 'javascript',
  Curl = 'curl',
  Sql = 'sql',
}

export type StringHashMap = {
  [key: string]: string;
};

export interface ApiInterface {
  apiCommandType: APICommandType;
  command: string;
  refererLinks?: StringHashMap;
  baseUrl?: string;
  endpointId?: string;
}

export interface ApiInterfaceMap {
  [APICommandType.Python]?: ApiInterface;
  [APICommandType.Javascript]?: ApiInterface;
  [APICommandType.Curl]?: ApiInterface;
  [APICommandType.Sql]?: ApiInterface;
}
