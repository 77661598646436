import React, { useMemo, useState } from 'react';
import { Box, makeStyles, TextField, Typography } from '@material-ui/core';
import ModelsUsageSection from './ModelsUsageSection';
import TopProjectsUsageSection from './TopProjectsUsageSection';
import UsageLogSection from './UsageLogs';
import TopProjectsUsageByImages from './TopProjectsUsageByImages';
import { GetUsageParams } from '../../../api/usage_api';
import { Dropdown, useDebouncedEffect } from '@clef/client-library';
import classNames from 'classnames';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { format, addDays, differenceInDays } from 'date-fns';
import { MillisecondsForOneDay } from '../../../utils/date_utils';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { Alert } from '@material-ui/lab';

export type UsageSectionProps = {
  initCycleStart: string;
  initCycleEnd: string;
};

export type UsageParams = {
  startTime: string;
  endTime: string;
  interval: GetUsageParams['interval'];
};

export const useUsageStyles = makeStyles(theme => ({
  usageSectionContainer: {
    padding: theme.spacing(0, 3),
  },
  datePicker: {
    padding: theme.spacing(2, 3),
  },
  title: {
    color: theme.palette.grey[900],
  },
  chartContainer: {},
  value: {
    padding: `1px ${theme.spacing(1)}px`,
    backgroundColor: '#F4F3FF',
    color: '#5925DC',
    borderRadius: 4,
    fontWeight: 700,
    fontSize: 14,
  },
  value1: {
    backgroundColor: '#F4F3FF',
    color: '#5925DC',
  },
  value2: {
    backgroundColor: `rgba(52, 199, 89, 0.1)`,
    color: '#34C759',
  },
  valueOCR: {
    backgroundColor: theme.palette.yellow[200],
    color: theme.palette.yellow[800],
  },
  greyValue: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.grey[600],
  },
  timeList: {
    padding: 0,
  },
  timeListItem: {
    listStyle: 'none',
    padding: theme.spacing(1.5, 3),
    fontSize: 14,
    cursor: 'pointer',
  },
  checkedTime: {
    border: `1px solid rgba(0, 0, 0, 0.23)`,
    borderRadius: 4,
    height: 32.63,
    padding: theme.spacing(0, 3),
    lineHeight: '32.63px',
    fontSize: 14,
  },
  checked: {
    backgroundColor: theme.palette.blue[25],
  },
  alert: {
    padding: theme.spacing(0, 4),
  },
}));

enum DateType {
  Month = 0,
  Week = 1,
  Current = 2,
  Custom = 3,
}

const UsageSection: React.FC<UsageSectionProps> = ({ initCycleStart, initCycleEnd }) => {
  const styles = useUsageStyles();
  const [startTime, setStartTime] = useState(initCycleStart);
  const [endTime, setEndTime] = useState(initCycleEnd);
  const interval: GetUsageParams['interval'] = 'day';
  const [checkedTimeIndex, setCheckedTimeIndex] = useState(3);
  const { currentTime } = useTypedSelector(state => state.login.user) ?? {};

  const [editingStartTime, setEditingStartTime] = useState(initCycleStart);
  const [editingEndTime, setEditingEndTime] = useState(initCycleEnd);

  const params = useMemo(() => ({ startTime, endTime, interval }), [endTime, startTime, interval]);
  const timeSelect = [t('Last 30 days'), t('Last week'), t('Current cycle'), t('Custom')];

  const dateErrorMessage = useMemo(() => {
    const startDate = new Date(editingStartTime);
    const endDate = new Date(editingEndTime);

    if (startDate > endDate) {
      return t('Start date cannot be later than end date.');
    }

    const diffInDays = differenceInDays(startDate, addDays(endDate, -1));
    if (Math.abs(diffInDays) > 31) {
      return t('The duration between the start date and end date should be within 31 days.');
    }
    return;
  }, [editingEndTime, editingStartTime]);

  useDebouncedEffect(
    () => {
      if (!dateErrorMessage && editingStartTime && editingEndTime) {
        setStartTime(editingStartTime);
        setEndTime(editingEndTime);
      }
    },
    [dateErrorMessage, editingStartTime, editingEndTime],
    1000,
  );

  const dateDays: Record<DateType, number> = {
    [DateType.Month]: 30,
    [DateType.Week]: 7,
  } as Record<DateType, number>;

  return (
    <Box className={styles.usageSectionContainer} marginTop={3}>
      <Box display="flex" alignItems="center">
        <Box display="flex" marginRight={2} alignItems="center">
          <Box marginRight={2}>
            <Typography>{t('Date:')}</Typography>
          </Box>
          <Dropdown
            dropdown={toggleDropdown => (
              <ul className={styles.timeList}>
                {timeSelect.map((item, index) => (
                  <li
                    className={classNames(styles.timeListItem, {
                      [styles.checked]: checkedTimeIndex === index,
                    })}
                    key={index}
                    onClick={() => {
                      const dayTimes = MillisecondsForOneDay;
                      const now = currentTime ? new Date(currentTime) : new Date();
                      setCheckedTimeIndex(index);
                      toggleDropdown(false);
                      if (index === DateType.Custom) {
                        return;
                      }
                      if (index === DateType.Current) {
                        setStartTime(initCycleStart);
                        setEndTime(initCycleEnd);
                        setEditingStartTime(initCycleStart);
                        setEditingEndTime(initCycleEnd);
                      } else {
                        const newStartTime = now.getTime() - dayTimes * dateDays[index as DateType];
                        setStartTime(format(newStartTime, 'yyyy-MM-dd'));
                        setEndTime(format(now, 'yyyy-MM-dd'));
                        setEditingStartTime(format(newStartTime, 'yyyy-MM-dd'));
                        setEditingEndTime(format(now, 'yyyy-MM-dd'));
                      }
                    }}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            )}
          >
            <Box className={styles.checkedTime} display="flex" alignItems="center">
              <Typography>{timeSelect[checkedTimeIndex]}</Typography>
              <ArrowDropDown />
            </Box>
          </Dropdown>
        </Box>
        {checkedTimeIndex === DateType.Custom && (
          <>
            <TextField
              id="date"
              type="date"
              value={editingStartTime}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                className: styles.datePicker,
              }}
              variant="outlined"
              onChange={e => setEditingStartTime(e.target.value)}
              error={!!dateErrorMessage}
            />
            <Box marginLeft={2} marginRight={2}>
              {t('To')}
            </Box>
            <TextField
              id="date"
              type="date"
              value={editingEndTime}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                className: styles.datePicker,
              }}
              variant="outlined"
              onChange={e => setEditingEndTime(e.target.value)}
              error={!!dateErrorMessage}
            />
            {dateErrorMessage && (
              <Box marginLeft={4}>
                <Alert severity="error" className={styles.alert}>
                  {dateErrorMessage}
                </Alert>
              </Box>
            )}
          </>
        )}
      </Box>
      {/* TODO hide */}
      {/* <ImagesUsageSection /> */}
      <ModelsUsageSection params={params} />
      <TopProjectsUsageSection params={params} />
      <TopProjectsUsageByImages params={params} />
      <UsageLogSection params={params} />
    </Box>
  );
};

export default UsageSection;
