import React, { useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { SelectOptions, useMount } from '@clef/client-library';
import { ProjectSplit, SelectMediaOption } from '@clef/shared/types';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';

import { useStyles } from './styles';
import { useUpdateMediaSplitMutation } from '@/serverStore/dataset';
import { useSaveAnnotations } from '@/uiStates/mediaDetails/pageUIStates';

export interface SplitInfoProps {
  split: number | null | undefined;
  mediaId: number | undefined;
  projectSplits: ProjectSplit[];
}

const SplitInfo: React.FC<SplitInfoProps> = ({ split, mediaId, projectSplits }) => {
  const styles = useStyles();

  const isMount = useMount();
  const [applyingSplitSetMediaIds, setApplyingSplitSetMediaIds] = useState([] as number[]);
  const applyingSplitSet = applyingSplitSetMediaIds.includes(mediaId ?? -1);
  const { data: project } = useGetSelectedProjectQuery();

  const projectSplitNameToId = projectSplits!.reduce(
    (obj: Record<string, number>, projectSplit) => {
      obj[projectSplit.splitSetName] = projectSplit.id;
      return obj;
    },
    {},
  );
  // split may be null and Number(null) = 0
  projectSplitNameToId['Unassigned'] = 0;
  let splitValueName = '';
  const otherSplitOptionNames = [] as string[];
  Object.entries(projectSplitNameToId).forEach(([name, id]) => {
    if (id === Number(split)) {
      splitValueName = name;
    } else {
      otherSplitOptionNames.push(name);
    }
  });
  const updateMediaSplit = useUpdateMediaSplitMutation();
  const saveAnnotations = useSaveAnnotations();

  return (
    <div data-testid="media-details-split-select-option" style={{ width: '100%' }}>
      {applyingSplitSet && (
        <div>
          <CircularProgress size="27px" />
        </div>
      )}
      {!applyingSplitSet && (
        <SelectOptions
          classes={{
            dropdownRoot: styles.splitDropdownRoot,
            selector: styles.splitSelector,
            dropdownOptions: styles.splitDropdownOptions,
          }}
          size="medium"
          options={otherSplitOptionNames}
          value={split === undefined ? '' : splitValueName}
          onChange={async option => {
            if (!project?.datasetId) return;
            setApplyingSplitSetMediaIds(prev => [...prev, mediaId!]);
            await saveAnnotations();
            updateMediaSplit.mutate(
              {
                datasetId: project.datasetId,
                splitSet: projectSplitNameToId[option],
                selectMediaOptions: {
                  selectedMedia: [mediaId],
                  unselectedMedia: [],
                  isUnselectMode: false,
                  fieldFilterMap: {},
                  columnFilterMap: {},
                } as SelectMediaOption,
              },
              {
                onSettled: () => {
                  isMount() &&
                    setApplyingSplitSetMediaIds(prev => prev.filter(id => id !== mediaId));
                },
              },
            );
          }}
        />
      )}
    </div>
  );
};

export default SplitInfo;
