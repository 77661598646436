import { Typography } from '@material-ui/core';
import React from 'react';

import ApiKeyV2 from '@/components/ApiKeyV2';
import { TabManager } from '@clef/client-library';
import { ClientFeatures } from '@clef/shared/features';
import Column from '../../components/Layout/components/Column';
import { useFeatureGateEnabled } from '../../hooks/useFeatureGate';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import ActiveProjects from './ActiveProjects';
import MembersSection from './MembersSection';
import PlanAndBilling from './PlanAndBilling';
import InternalSettings from './Settings/InternalSettings';
import useStyles from './styles';
import Usage from './Usage';

const OrganizationSettings: React.FC<{}> = () => {
  const styles = useStyles();
  const currentUser = useTypedSelector(state => state.login.user!);
  const enableInternalSettings = useFeatureGateEnabled(ClientFeatures.EnableInternalSettings);
  const enableApiKey = !useFeatureGateEnabled(ClientFeatures.DisableApiKey);
  const enableSubscription = !useFeatureGateEnabled(ClientFeatures.DisableSubscription);
  const enableMemberManagement = !useFeatureGateEnabled(ClientFeatures.DisableMemberManagement);
  return (
    <div className={styles.orgSettingsPage}>
      <Column className={styles.pageTitleContainer}>
        <Typography variant="h1" className={styles.titleText}>
          {currentUser.company}
        </Typography>
      </Column>

      <TabManager
        tabs={[
          ...(enableSubscription
            ? [
                {
                  key: 'planAndBilling',
                  title: t('Plan and Billing'),
                  component: <PlanAndBilling />,
                },
              ]
            : []),
          ...(enableSubscription
            ? [
                {
                  key: 'usage',
                  title: t('Usage'),
                  component: <Usage />,
                },
              ]
            : []),
          ...(enableMemberManagement
            ? [
                {
                  key: 'member',
                  title: t('Members'),
                  component: <MembersSection />,
                },
              ]
            : []),
          {
            key: 'activeProject',
            title: t('Active Projects'),
            component: <ActiveProjects />,
          },
          ...(enableApiKey
            ? [
                {
                  key: 'api_key_v2',
                  title: t('API Keys'),
                  component: <ApiKeyV2 />,
                },
              ]
            : []),
          ...(enableInternalSettings
            ? [
                {
                  key: 'internal_settings',
                  title: t('Internal Settings'),
                  component: <InternalSettings />,
                },
              ]
            : []),
        ]}
      />
    </div>
  );
};

export default OrganizationSettings;
