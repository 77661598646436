import React, { useState } from 'react';
import { Button } from '@clef/client-library';
import { ModelStatus } from '@clef/shared/types';
import { CircularProgress, Tooltip, makeStyles, Grid, Typography } from '@material-ui/core';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useCurrentProjectModelInfoQuery } from '@/serverStore/projectModels';
import RunLiveDialog from './RunLiveDialog';
import { useDataBrowserState } from '../dataBrowserState';
import { HighlightWrapper } from '../../../components/WorkflowAssistant/HighlightWrapper';
import { useWorkflowAssistantState } from '../../../components/WorkflowAssistant/state';
import { StepName } from '../../../types/client';
import { useCheckCreditReachLimit } from '../../../hooks/useSubscriptions';
import DeployModelDialog from '@/components/Dialogs/DeployModelDialog';
import { PulseWrapper } from '@clef/client-library';
import { useIsLargeImageModel } from '@/hooks/useIsLargeImageModel';
import { useModelStatusQuery } from '@/serverStore/projectModels';
import { ReachLimitModelDialog } from '../TrainModelButtonGroup/ReachLimitModelDialog';

const RUN_LIVE = 'Run Live';

const useStyles = makeStyles(theme => ({
  deployButton: {
    marginRight: theme.spacing(3),
  },
}));

type RunLiveProps = {
  modelName?: string;
  isRunLive?: boolean;
  // Override default buttonText logic
  buttonText?: string;
  disabled?: boolean;
  tooltipText?: string;
  overwriteOnRunLiveButtonClick?: () => void | Promise<void>;
  isDeployPending?: boolean;
  modelId?: string;
};

const RunLive: React.FC<RunLiveProps> = ({
  modelName = 'Untitled model',
  disabled = false,
  isRunLive = false,
  buttonText = '',
  tooltipText = '',
  overwriteOnRunLiveButtonClick,
  isDeployPending,
  modelId,
}) => {
  const [isLargeImageModel, loading] = useIsLargeImageModel(modelId);
  const styles = useStyles();
  const [openRunLiveDialog, setOpenRunLiveDialog] = useState(false);
  const { id: projectId, datasetId = 0 } = useGetSelectedProjectQuery().data ?? {};
  const { id: currentModelId, confidence } = useCurrentProjectModelInfoQuery();
  const { data: modelStatusRes } = useModelStatusQuery(projectId, currentModelId);
  const { dispatch } = useDataBrowserState();
  const {
    state: { step, hovering, autoHovering },
  } = useWorkflowAssistantState();

  const modelStatus = modelStatusRes?.status;
  const waitingModel = modelStatus !== ModelStatus.Succeed && modelStatus !== ModelStatus.Saved;

  const { hasReachLimit } = useCheckCreditReachLimit();
  const shouldOpenReachLimitDialog = hasReachLimit;
  const [openReachLimitModelDialog, setOpenReachLimitModelDialog] = useState(false);

  const [deployModelDialogOpen, setDeployModelDialogOpen] = useState(false);

  const renderButton = (text: string, onClick: () => void) => (
    <Button
      id="deploy-model-button"
      variant="contained"
      color="primary"
      size="medium"
      disabled={waitingModel || disabled}
      startIcon={waitingModel ? <CircularProgress size="16px" /> : null}
      onClick={onClick}
    >
      {t(text)}
    </Button>
  );

  // First Run Live Experience should only be available if the project is OD, Seg or Cls (labelType ===
  // LabelType.BoundingBox, labelType === LabelType.Classification, labelType === LabelType.Segmentation)
  if (loading) {
    return <CircularProgress size={20} />;
  }

  return (
    <>
      {!isRunLive ? (
        <Grid container justifyContent="flex-end">
          <Button
            id="data-browser-deploy-button"
            variant="outlined"
            color="primary"
            className={styles.deployButton}
            startIcon={waitingModel || isDeployPending ? <CircularProgress size="16px" /> : null}
            disabled={isDeployPending || waitingModel || disabled}
            onClick={() => {
              if (overwriteOnRunLiveButtonClick) {
                overwriteOnRunLiveButtonClick();
              } else {
                setOpenRunLiveDialog(true);
              }
            }}
          >
            {t('Deploy')}
          </Button>

          <>
            {isLargeImageModel ? (
              <Tooltip
                arrow
                placement="top"
                title={
                  <span>
                    <Typography variant="body2">
                      {t('This model is incompatible with cloud inference.')}
                    </Typography>
                    <Typography variant="body2">
                      {t('Use LandingEdge to run predictions.')}
                    </Typography>
                  </span>
                }
              >
                <span>
                  <Button
                    id="deploy-model-button"
                    disabled
                    variant="contained"
                    color="primary"
                    size="medium"
                  >
                    {t('Predict')}
                  </Button>
                </span>
              </Tooltip>
            ) : (
              <Tooltip
                arrow
                placement="top"
                title={
                  tooltipText ? tooltipText : waitingModel ? t('Waiting for publishing model') : ''
                }
              >
                <HighlightWrapper
                  enabled={(hovering || autoHovering) && step?.stepName === StepName.Predict}
                >
                  <PulseWrapper enabled={step?.stepName === StepName.Predict}>
                    <Button
                      id="deploy-model-button"
                      variant="contained"
                      color="primary"
                      size="medium"
                      disabled={waitingModel || disabled}
                      startIcon={waitingModel ? <CircularProgress size="16px" /> : null}
                      onClick={() => {
                        if (shouldOpenReachLimitDialog) {
                          setOpenReachLimitModelDialog(true);
                          return;
                        }
                        dispatch(draft => {
                          draft.isFirstRunLiveExperienceModalOpen = true;
                        });
                      }}
                    >
                      {t('Predict')}
                    </Button>
                  </PulseWrapper>
                </HighlightWrapper>
              </Tooltip>
            )}
          </>
        </Grid>
      ) : (
        <span>{renderButton(buttonText || RUN_LIVE, () => setOpenRunLiveDialog(true))}</span>
      )}
      {openRunLiveDialog && (
        <RunLiveDialog modelName={modelName} onClose={() => setOpenRunLiveDialog(false)} />
      )}

      <DeployModelDialog
        open={deployModelDialogOpen}
        onClose={() => setDeployModelDialogOpen(false)}
        modelInfo={{
          id: currentModelId,
          threshold: confidence,
        }}
        isLargeImageModel={isLargeImageModel}
      />
      {datasetId && projectId && (
        <ReachLimitModelDialog
          projectId={projectId}
          datasetId={datasetId}
          open={openReachLimitModelDialog}
          onClose={() => {
            setOpenReachLimitModelDialog(false);
          }}
        />
      )}
    </>
  );
};

export default RunLive;
