import React from 'react';
import { Media } from '@clef/shared/types';
import MediaContainer from '@/pages/DataBrowser/MediaContainer';
import { calcOptimalRatio } from '@/pages/DataBrowser/MediaGrid/MediaGrid';
import { VirtualGrid } from '@clef/client-library';

export type ModelImageListClassificationProps = {
  showHeatmap: boolean;
  mediaList?: Media[];
  modelId?: string;
  version?: number;
  threshold?: number;
  itemPerRowCap?: number;
  onImageClick?: (mediaId: number) => void;
};

const ModelImageListClassification: React.FC<ModelImageListClassificationProps> = props => {
  const {
    mediaList,
    modelId,
    version,
    threshold,
    itemPerRowCap = 6,
    onImageClick,
    showHeatmap,
  } = props;
  if (!mediaList) {
    return null;
  }
  const imageRatio = calcOptimalRatio(mediaList);
  return (
    <>
      <VirtualGrid componentList={mediaList} itemPerRowCap={itemPerRowCap} imageRatio={imageRatio}>
        {(media: Media) => {
          return (
            <MediaContainer
              showHeatmap={showHeatmap}
              media={media}
              modelId={modelId}
              versionId={version}
              showGroundTruth={false}
              showPredictions
              showClassChip={true}
              disableSetClass
              threshold={threshold}
              onInfoClick={() => onImageClick?.(media.id)}
            />
          );
        }}
      </VirtualGrid>
    </>
  );
};

export default ModelImageListClassification;
