import React, { useMemo, useState } from 'react';
import { Box, Tooltip } from '@material-ui/core';
import { formatDistance } from 'date-fns';

import { Typography, Button, IconButton } from '@clef/client-library';

import { getDateNumber } from '@/utils';

import useStyles from './styles';
import InfoIcon from '@material-ui/icons/Info';
import { LabelType, ModelStatus, RegisteredModel, UserPermission } from '@clef/shared/types';
import { useHasPermission } from '@/hooks/useProjectRolePermissions';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import LoadingProgress from '@/pages/model_iteration/componentsV2/LoadingProgress';
import HighlightWrapper from '@/components/WorkflowAssistant/HighlightWrapper';
import { PulseWrapper } from '@clef/client-library';
import { useWorkflowAssistantState } from '@/components/WorkflowAssistant/state';
import { StepName } from '@/types/client';
import { useGetProjectModelListQuery } from '@/serverStore/projectModels';
import { isModelTrainingSuccessful } from '@/store/projectModelInfoState/utils';
import ModelDetailsDialogPerformanceSummary from './ModelDetailsDialogPerformanceSummary';
import {
  useModelPerformanceConfusionMatricesData,
  useModelPerformanceData,
} from './useModelPerformanceSummaryData';
import TryModelDialog from './TryModelDialog';
import MoreButton from './MoreButton';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useIsLargeImageModel } from '@/hooks/useIsLargeImageModel';
import classNames from 'classnames';

const ModelInfoPerformanceTooltip = (props: { labelType: LabelType | undefined }) => {
  const { labelType } = props;
  const styles = useStyles();
  return (
    <Box display="flex" alignItems="center" marginLeft={2}>
      <Box>
        <Typography className={styles.modelCardPerformanceF1Text} variant="body_bold">
          {labelType === LabelType.Segmentation ? t('IOU') : t('F1')}
        </Typography>
      </Box>
      <Tooltip
        placement="top"
        arrow
        interactive
        title={
          labelType === LabelType.Segmentation
            ? t(
                'Intersection over Union (IOU) is used to measure the accuracy of the model by measuring the overlap between the predicted and actual masks in an image. A higher IOU indicates better agreement between the ground truth and predicted mask. For IoU we ignore the implicit background and micro-averaging. {{learnMore}}',
                {
                  learnMore: (
                    <Typography
                      style={{
                        display: 'inline',
                        cursor: 'pointer',
                        marginLeft: 4,
                        textDecoration: 'underline',
                        fontSize: '12px',
                      }}
                      onClick={() => {
                        window.open(
                          'https://support.landing.ai/landinglens/docs/compare-models#segmentation-intersection-over-union-iou',
                          '_blank',
                        );
                      }}
                    >
                      {t('Learn more.')}
                    </Typography>
                  ),
                },
              )
            : t(
                'The F1 score combines precision and recall into a single score, creating a unified measure that assesses the model’s effectiveness in minimizing false positives and false negatives. A higher F1 score indicates the model is balancing the two factors well. We calculate F1 using micro-averaging.',
              )
        }
      >
        <InfoIcon className={styles.infoIcon} />
      </Tooltip>
    </Box>
  );
};

interface ModelInfoCardPerformanceSummaryProps {
  model: RegisteredModel;
  initialExpand?: boolean;
}

const ModelInfoCardPerformanceSummary: React.FC<ModelInfoCardPerformanceSummaryProps> = ({
  model,
  initialExpand = false,
}) => {
  const styles = useStyles();
  const dateNow = useMemo(() => new Date(), []);
  const { data: selectedProject } = useGetSelectedProjectQuery();
  const [modelDetailsDialogOpen, setModelDetailsDialogOpen] = useState(false);
  const { performanceArray, isLoading: isModelPerformanceDataLoading } =
    useModelPerformanceData(model);
  const { allConfusionMatricesArray, isLoading: isModelPerformanceConfusionMatricesLoading } =
    useModelPerformanceConfusionMatricesData(model, selectedProject?.labelType);
  const [tryModelDialogOpen, setTryModelDialogOpen] = useState(false);

  const filteredConfusionMatricesArray = allConfusionMatricesArray.filter(cm => {
    return cm.count > 0;
  });
  const [expanded, setExpanded] = useState<boolean>(initialExpand);

  const { data: projectModelsData } = useGetProjectModelListQuery();
  const isLatestTrainedModel =
    projectModelsData
      ?.filter(model => model.modelName)
      .find(model => isModelTrainingSuccessful(model.status, model.metricsReady))?.id === model.id;

  const {
    state: { step, hovering, autoHovering },
  } = useWorkflowAssistantState();

  const canDeployModel = useHasPermission(UserPermission.DeployModel);
  const isTerminated = model.status === ModelStatus.Terminated;

  const [isLargeImageModel, loading] = useIsLargeImageModel(model.id);

  const onTryModel = () => {
    setTryModelDialogOpen(true);
  };

  return (
    <>
      <Box
        className={classNames(styles.modelInfoCard, isTerminated && 'disabled')}
        display="flex"
        flexDirection={'column'}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between" mr={-4}>
          <Typography variant="h4_semibold" maxWidth={164}>
            {model.modelName}
          </Typography>
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
            {filteredConfusionMatricesArray.length > 0 && (
              <IconButton
                size="small"
                onClick={e => {
                  e.stopPropagation();
                  setExpanded(expanded => !expanded);
                }}
              >
                {expanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            )}
            {!isTerminated && (
              <Box className={styles.moreActionBtn}>
                <MoreButton model={model} showDownloadCsv={true} />
              </Box>
            )}
          </Box>
        </Box>
        <Typography variant="body_small">
          {t('Trained {{dateDistance}} ago', {
            dateDistance: formatDistance(getDateNumber(model.createdAt), dateNow),
          })}
        </Typography>
        {isModelPerformanceDataLoading || isModelPerformanceConfusionMatricesLoading ? (
          <Box paddingTop={5} paddingBottom={5} flex={1}>
            <LoadingProgress size={20} />
          </Box>
        ) : (
          <>
            <Box
              display="flex"
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Box display="flex" flexDirection={'row'} paddingTop={3} paddingBottom={3}>
                {performanceArray.map(item => {
                  return (
                    <Box
                      className={styles.modelCardPerformanceArraySetName}
                      key={item.setName}
                      display="flex"
                      flexDirection={'column'}
                      alignItems={'center'}
                      justifyContent={'center'}
                    >
                      <Box className={styles.modelCardPerformanceNumber}>{item.f1}</Box>
                      <Box className={styles.modelCardPerformanceSetText}>{item.setName}</Box>
                    </Box>
                  );
                })}
              </Box>
              <ModelInfoPerformanceTooltip labelType={selectedProject?.labelType ?? undefined} />
            </Box>
            {expanded && (
              <Box display="flex" flexDirection={'column'}>
                {filteredConfusionMatricesArray.map(item => {
                  return (
                    <Box
                      key={item.name}
                      display="flex"
                      flexDirection={'row'}
                      justifyContent={'space-between'}
                      alignItems="center"
                      paddingBottom={2}
                    >
                      <Box className={styles.modelCardPerformanceConfusionMatrixText}>
                        {item.name}
                      </Box>
                      <Tooltip
                        placement="bottom"
                        title={t('{{count}} {{unit}}', {
                          count: item.count,
                          unit:
                            selectedProject?.labelType === LabelType.BoundingBox
                              ? t('bounding boxes')
                              : selectedProject?.labelType === LabelType.Segmentation
                              ? t('pixels')
                              : t('images'),
                        })}
                        arrow={true}
                      >
                        <Box
                          className={
                            item.isCorrect
                              ? styles.modelCardPerformanceConfusionMatrixCorrectNumber
                              : styles.modelCardPerformanceConfusionMatrixIncorrectNumber
                          }
                        >
                          {item.count}
                        </Box>
                      </Tooltip>
                    </Box>
                  );
                })}
              </Box>
            )}
            {!isTerminated && (
              <Button
                className={styles.modelCardViewConfusionMatrixBtn}
                id={`model-card-view-confusion-matrix-${model.id}`}
                variant="contained"
                onClick={() => !isTerminated && setModelDetailsDialogOpen(true)}
              >
                {t('View Confusion Matrix')}
              </Button>
            )}
            {canDeployModel && !isTerminated && (
              <HighlightWrapper
                enabled={
                  isLatestTrainedModel &&
                  (hovering || autoHovering) &&
                  step?.stepName === StepName.Predict
                }
              >
                <PulseWrapper
                  enabled={isLatestTrainedModel && step?.stepName === StepName.Predict}
                  className={styles.modelCardTryModelBtnWrapper}
                >
                  <Tooltip
                    arrow
                    placement="top"
                    title={
                      isLargeImageModel ? (
                        <span>
                          <Typography variant="body2">
                            {t('This model is incompatible with cloud inference.')}
                          </Typography>
                          <Typography variant="body2">
                            {t('Use LandingEdge to run predictions.')}
                          </Typography>
                        </span>
                      ) : (
                        ''
                      )
                    }
                  >
                    <span>
                      <Button
                        className={styles.modelCardTryModelBtn}
                        id={`model-card-try-model-${model.id}`}
                        variant="contained"
                        disabled={isLargeImageModel || loading}
                        onClick={e => {
                          e.stopPropagation();
                          onTryModel();
                        }}
                      >
                        {t('Try Model')}
                      </Button>
                    </span>
                  </Tooltip>
                </PulseWrapper>
              </HighlightWrapper>
            )}
            {isTerminated && (
              <Box>
                <Box className={styles.endedBadge}>{t('Manually Ended')}</Box>
              </Box>
            )}
          </>
        )}
      </Box>
      {modelDetailsDialogOpen && (
        <ModelDetailsDialogPerformanceSummary
          model={model}
          open={modelDetailsDialogOpen}
          onClose={() => {
            setModelDetailsDialogOpen(false);
          }}
        />
      )}
      {tryModelDialogOpen && (
        <TryModelDialog
          model={model}
          onClose={() => {
            setTryModelDialogOpen(false);
          }}
        />
      )}
    </>
  );
};

export default ModelInfoCardPerformanceSummary;
