import { Box, Divider, Grid, Menu, MenuItem, Typography } from '@material-ui/core';
import cx from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { LATEST_RELEASE_VERSION } from '@clef/shared/constants';
import { isOrgCollaborator } from '@clef/shared/utils';
import { startCase } from 'lodash';
import { Link } from 'react-router-dom';
import CLEF_PATH, { JoinOrgOpener } from '../../../../constants/path';
import { ClientFeatures, useFeatureGateEnabled } from '../../../../hooks/useFeatureGate';
import { useMenu } from '../../../../hooks/useMenu';
import { useCurrentSubscription } from '../../../../hooks/useSubscriptions';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { getPlanName } from '../../../../pages/plan/utils';
import { resetEnabledFeaturesForUser } from '../../../../store/feature_toggle_store';
import { refreshAuthenticatedStates, signOut } from '../../../../store/newLoginState/actions';
import { rootElement } from '../../../../utils/dom_utils';
import { UserAvatar } from '../../../UserAvatar/UserAvatarById';
import { Column } from '../Column';
import useHeaderStyles from './styles';

export interface ProfileMenuProps {
  useNewUi?: boolean;
}

export const ProfileMenu: React.FC<ProfileMenuProps> = ({ useNewUi }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useHeaderStyles();
  const user = useTypedSelector(state => state.login.user);
  const currentUser = useTypedSelector(state => state.login.user);

  const {
    open: rootMenuOpen,
    menuRef: rootMenuRef,
    onMenuOpen: onRootMenuOpen,
    onMenuClose: onRootMenuClose,
  } = useMenu();

  const userInfoButton = useMemo(
    () => (
      <div
        onClick={e => onRootMenuOpen(e)}
        data-testid="profile-button"
        className={classes.profileButtonContainer}
      >
        <Grid
          container
          className={cx(classes.userInfoGridContainer, classes.profileButton, {
            [classes.profileButtonHover]: true,
            [classes.profileButtonBorder]: !useNewUi,
          })}
          alignItems="center"
        >
          <Grid item>
            <Typography className={classes.prorfileButtonOrgName}>
              {currentUser?.company}
            </Typography>
          </Grid>

          <Grid item className={classes.userInfoGridLeftItem}>
            {currentUser?.id && (
              <UserAvatar
                userId={currentUser.id}
                disableTooltip
                classes={{
                  avatar: classes.avatar,
                }}
              />
            )}
          </Grid>

          <Grid item className={classes.userInfoGridRightItem}>
            <KeyboardArrowDownIcon className={classes.keyboardArrowDownIcon} />
          </Grid>
        </Grid>
      </div>
    ),
    [
      onRootMenuOpen,
      classes.profileButton,
      classes.userInfoGridContainer,
      classes.prorfileButtonOrgName,
      classes.userInfoGridLeftItem,
      classes.avatar,
      classes.userInfoGridRightItem,
      classes.keyboardArrowDownIcon,
      currentUser?.company,
      currentUser?.id,
      classes.profileButtonHover,
      classes.profileButtonBorder,
      classes.profileButtonContainer,
      useNewUi,
    ],
  );

  const userInfoListItem = useMemo(
    () => (
      <Box className={classes.menuUserInfo}>
        <Grid container className={classes.userInfoGridContainer}>
          <Grid item className={classes.userInfoGridLeftItem}>
            {currentUser?.id && (
              <UserAvatar
                userId={currentUser.id}
                disableTooltip
                classes={{
                  avatar: classes.avatar,
                }}
              />
            )}
            <Column className={classes.userInfoColumn}>
              <Typography variant="h4" className={classes.userNameText}>
                {user!.name}
              </Typography>
              <Typography variant="subtitle1">{user!.email}</Typography>
            </Column>
          </Grid>
        </Grid>
      </Box>
    ),
    [
      classes.menuUserInfo,
      classes.userInfoGridContainer,
      classes.userInfoGridLeftItem,
      classes.userInfoColumn,
      classes.avatar,
      classes.userNameText,
      currentUser?.id,
      user,
    ],
  );

  const onOrgSwitch = useCallback(() => {
    localStorage.removeItem('clef_is_login');
    dispatch(refreshAuthenticatedStates());
    dispatch(resetEnabledFeaturesForUser());
    history.push(`${CLEF_PATH.login.joinOrg}?disableAutoSignin=true&opener=${JoinOrgOpener.App}`);
    onRootMenuClose();
  }, [dispatch, history, onRootMenuClose]);

  const subscription = useCurrentSubscription();
  const enableInternalSettings = useFeatureGateEnabled(ClientFeatures.EnableInternalSettings);
  const enableApiKey = !useFeatureGateEnabled(ClientFeatures.DisableApiKey);
  const enableSubscription = !useFeatureGateEnabled(ClientFeatures.DisableSubscription);
  const enableMemberManagement = !useFeatureGateEnabled(ClientFeatures.DisableMemberManagement);
  const enableAuthFeatures = !useFeatureGateEnabled(ClientFeatures.SkipAuthenticationFlows);
  const enableShowReleaseVersion = !useFeatureGateEnabled(ClientFeatures.DisableShowReleaseVersion);

  const apiKeyUrl = CLEF_PATH.organizationSettings + '?tab=api_key_v2';

  return (
    <>
      {userInfoButton}

      <Menu
        id="profile-menu"
        open={rootMenuOpen}
        anchorEl={rootMenuRef}
        onClose={onRootMenuClose}
        getContentAnchorEl={null}
        classes={{ paper: classes.profileMenu }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        container={rootElement}
        disableAutoFocusItem
      >
        {userInfoListItem}

        <Divider className={classes.divider} />

        {user &&
          !isOrgCollaborator(user.userRole) &&
          [
            enableSubscription && (
              <MenuItem
                key="plan"
                component={Link}
                to={CLEF_PATH.organizationSettings}
                onClick={onRootMenuClose}
              >
                <Box className={classes.freProfileMenuOrg}>
                  <Box>
                    <Typography className={classes.freProfileMenuOrgName}>
                      {currentUser?.company}
                    </Typography>
                    {subscription && (
                      <Box whiteSpace="nowrap" className={classes.planLine} marginTop={1}>
                        {t('Your organization is on the {{plan}}', {
                          plan: (
                            <span className={classes.planText}>
                              {t('{{plan}} Plan', {
                                plan: startCase(getPlanName(subscription)),
                              })}
                            </span>
                          ),
                        })}
                      </Box>
                    )}
                  </Box>
                </Box>
              </MenuItem>
            ),
            enableSubscription && (
              <MenuItem
                key="billing"
                component={Link}
                to={CLEF_PATH.organizationSettings}
                onClick={onRootMenuClose}
              >
                <div className={classes.headerButton}>{t('Plan and Billing')}</div>
              </MenuItem>
            ),
            enableSubscription && (
              <MenuItem
                key="usage"
                component={Link}
                to={`${CLEF_PATH.organizationSettings}?tab=usage`}
                onClick={onRootMenuClose}
              >
                <div className={classes.headerButton}>{t('Usage')}</div>
              </MenuItem>
            ),
            enableMemberManagement && (
              <MenuItem
                key="members"
                component={Link}
                to={`${CLEF_PATH.organizationSettings}?tab=member`}
                onClick={onRootMenuClose}
              >
                <div className={classes.headerButton}>{t('Members')}</div>
              </MenuItem>
            ),
            <MenuItem
              key="active-project"
              component={Link}
              to={`${CLEF_PATH.organizationSettings}?tab=activeProject`}
              onClick={onRootMenuClose}
            >
              <div className={classes.headerButton}>{t('Active Projects')}</div>
            </MenuItem>,
            enableApiKey && (
              <MenuItem key="api-key" component={Link} to={apiKeyUrl} onClick={onRootMenuClose}>
                <div className={classes.headerButton}>{t('API Key')}</div>
              </MenuItem>
            ),
            enableInternalSettings && (
              <MenuItem
                key="settings"
                component={Link}
                to={`${CLEF_PATH.organizationSettings}?tab=internal_settings`}
                onClick={onRootMenuClose}
              >
                <div className={classes.headerButton}>{t('Settings')}</div>
              </MenuItem>
            ),
            <Divider key="divider" className={classes.divider} />,
          ].filter(Boolean)}

        {enableAuthFeatures && (
          <MenuItem onClick={onOrgSwitch}>
            <div className={cx(classes.headerButton)}>{t('Switch Organization')}</div>
          </MenuItem>
        )}

        <Divider className={classes.divider} />

        {enableAuthFeatures && (
          <MenuItem onClick={() => dispatch(signOut())}>
            <Typography
              className={cx(classes.headerButton, classes.importantHeaderButton)}
              color="secondary"
            >
              {t('Sign Out')}
            </Typography>
          </MenuItem>
        )}
        {enableShowReleaseVersion && (
          <MenuItem className={classes.releaseVersionMenuItem}>
            <Typography variant="h3" className={classes.releaseVersionName}>
              {t(`Release version: ${LATEST_RELEASE_VERSION}`)}
            </Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default ProfileMenu;
