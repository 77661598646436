import React, { useCallback, useState } from 'react';
import { Popover, List, ListItem, ListItemText } from '@material-ui/core';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

import { DatasetVersion } from '@clef/shared/types';
import { IconButton } from '@clef/client-library';
import { ClientFeatures } from '@clef/shared/features';
import { pendoEntity } from '../../../utils/pendo';

import { ERROR_SNACKBAR_STYLE } from '@/constants/metadata_constants';
import DatasetAPI from '@/api/dataset_api';
import { useFeatureGateEnabled } from '@/hooks/useFeatureGate';
import { datasetQueryKeys } from '@/serverStore/dataset';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';

import DeleteDialog from './DeleteDialog';
import useStyles from './styles';

enum DownloadType {
  CSV,
  Pascal_VOC,
  Defect_Map,
}

export type MoreButtonProps = {
  snapshot: DatasetVersion;
};

const MoreButton: React.FC<MoreButtonProps> = ({ snapshot }) => {
  const styles = useStyles();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { id: projectId } = useGetSelectedProjectQuery().data ?? {};

  const internalUser = useFeatureGateEnabled(ClientFeatures.PaulExportInternalAction);

  const { pascalVoc, csvPath, defectMapPath, datasetId, version } = snapshot;

  const onDownloadFailed = useCallback(() => {
    enqueueSnackbar('Download Failed', ERROR_SNACKBAR_STYLE);
  }, [enqueueSnackbar]);

  const onDownloadBlocked = useCallback(() => {
    enqueueSnackbar(
      'Download popup is blocked by the browser, you might need to deactivate the blocker or allow it to open the tab.',
      { ...ERROR_SNACKBAR_STYLE, autoHideDuration: 8000 },
    );
  }, [enqueueSnackbar]);

  // fetch downloadable urls and download
  const downloadAction = async (type: DownloadType) => {
    const { pascalVocSignedUrl, defectMapPathSignedUrl, csvPathSignedUrl } =
      await DatasetAPI.getDatasetVersionSignedUrls(datasetId, version);
    let newWindow;
    switch (type) {
      case DownloadType.CSV:
        if (!csvPathSignedUrl) {
          onDownloadFailed();
        }
        newWindow = window.open(csvPathSignedUrl);
        if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
          onDownloadBlocked();
        } else {
          enqueueSnackbar('Download Completed', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            autoHideDuration: 8000,
          });
        }
        return;
      case DownloadType.Pascal_VOC: {
        if (!pascalVocSignedUrl) {
          onDownloadFailed();
        }
        newWindow = window.open(pascalVocSignedUrl);
        if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
          onDownloadBlocked();
        } else {
          enqueueSnackbar('Download Completed', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            autoHideDuration: 8000,
          });
        }
        return;
      }
      case DownloadType.Defect_Map: {
        if (!defectMapPathSignedUrl) {
          onDownloadFailed();
        }
        window.open(defectMapPathSignedUrl);
        return;
      }
    }
  };

  const [exportingFiles, setExportingFiles] = useState(false);
  const downloadFiles = async (type: DownloadType) => {
    if (exportingFiles) return;
    setExportingFiles(true);

    // if pascalVoc.pascalVocPath exists, download directly
    if (
      (type === DownloadType.Pascal_VOC && pascalVoc?.pascalVocPath) ||
      (type === DownloadType.CSV && csvPath)
    ) {
      downloadAction(type);
      setExportingFiles(false);
      return;
    }

    if (type === DownloadType.CSV && !csvPath) {
      pendoEntity?.track('download_csv_snap', {
        status: 'Download Failed: CSV file not found.',
        projectId,
        datasetId,
        version,
      });
      enqueueSnackbar('Download Failed: CSV file not found.', {
        ...ERROR_SNACKBAR_STYLE,
      });
      setExportingFiles(false);
      return;
    }
    // trigger export job and poll for status
    const downloadingKey = enqueueSnackbar('Prepare for downloading...', {
      variant: 'default',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      persist: true,
    });

    let intervalId: NodeJS.Timeout | null = null;
    // initial call
    try {
      let res = await DatasetAPI.exportSnapshotVersion(datasetId, version, true);

      intervalId = setInterval(async () => {
        if (res.status === 'Failed') {
          closeSnackbar(downloadingKey);
          enqueueSnackbar('Download Failed', {
            ...ERROR_SNACKBAR_STYLE,
          });
          intervalId && clearInterval(intervalId);
          setExportingFiles(false);
          return;
        }
        if (
          res.status === 'Completed' &&
          ((type === DownloadType.Pascal_VOC && res.pascalVocPath) ||
            (type === DownloadType.CSV && res.csvPath))
        ) {
          closeSnackbar(downloadingKey);
          downloadAction(type);
          projectId &&
            queryClient.invalidateQueries(datasetQueryKeys.exportedWithVersions(projectId));
          intervalId && clearInterval(intervalId);
          setExportingFiles(false);
          return;
        }
        res = await DatasetAPI.exportSnapshotVersion(datasetId, version);
      }, 5000);
    } catch (e) {
      closeSnackbar(downloadingKey);
      enqueueSnackbar(`Download Failed: ${e.message}`, {
        ...ERROR_SNACKBAR_STYLE,
      });
      intervalId && clearInterval(intervalId);
      setExportingFiles(false);
    }
  };

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  return (
    <>
      <PopupState variant="popover" popupId="snapshot-page-more-action-popup-popover">
        {popupState => (
          <>
            <IconButton
              id="snapshot-page-more-action"
              className={styles.more}
              {...bindTrigger(popupState)}
            >
              <MoreHoriz />
            </IconButton>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <List>
                <ListItem
                  button
                  id="download-pascal-voc"
                  onClick={() => {
                    downloadFiles(DownloadType.Pascal_VOC);
                    popupState.close();
                  }}
                  disabled={exportingFiles}
                >
                  <ListItemText primary={t('Download Dataset')} />
                </ListItem>
                {internalUser && (
                  <ListItem
                    button
                    id="download-csv"
                    onClick={() => {
                      downloadFiles(DownloadType.CSV);
                      popupState.close();
                    }}
                    disabled={exportingFiles}
                  >
                    <ListItemText primary={t('Download CSV')} />
                  </ListItem>
                )}
                {internalUser && !!defectMapPath && (
                  <ListItem
                    button
                    id="download-class-map"
                    onClick={() => {
                      downloadAction(DownloadType.Defect_Map);
                      popupState.close();
                    }}
                  >
                    <ListItemText primary={t('Download Class Map')} />
                  </ListItem>
                )}
                <ListItem
                  button
                  id="delete-snapshot"
                  onClick={() => {
                    setDeleteDialogOpen(true);
                    popupState.close();
                  }}
                >
                  <ListItemText primary={t('Delete this snapshot')} className={styles.red} />
                </ListItem>
              </List>
            </Popover>
          </>
        )}
      </PopupState>

      {deleteDialogOpen && (
        <DeleteDialog snapshot={snapshot} onClose={() => setDeleteDialogOpen(false)} />
      )}
    </>
  );
};

export default MoreButton;
