import React, { useState } from 'react';
import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import { useTypographyStyles } from '@clef/client-library/src/themes/typography';
import LandingLogo from '@/images/logo_withoutText_color.svg';
import { Button, QrCode } from '@clef/client-library';
import { getMobileInferenceEndpoint } from '../../utils';
import coinsIcon from '@/images/deploy/coins.svg';
import { refreshPublicEndpointByIdApi } from '@/hooks/api/usePublicEndpointApi';
import { useSnackbar } from 'notistack';
import public_endpoint_api from '@/api/public_endpoint_api';
import { ClientFeatures, useFeatureGateEnabled } from '@/hooks/useFeatureGate';

const useStyles = makeStyles(theme => ({
  divider: {
    borderTop: `1px solid ${theme.palette.greyModern[300]}`,
    width: '100%',
  },
}));

export type MobileInferenceDialogContentEnabledProps = {
  inferenceCost: number;
  endpointId?: string;
};

const MobileInferenceDialogContentEnabled: React.FC<
  MobileInferenceDialogContentEnabledProps
> = props => {
  const { endpointId, inferenceCost } = props;
  const isCreditReferenceEnabled = !useFeatureGateEnabled(ClientFeatures.DisableCreditReference);
  const styles = useStyles();
  const typographyStyles = useTypographyStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  if (!endpointId) {
    return null;
  }

  return (
    <>
      <Box textAlign="center" className={typographyStyles.h2_semibold} marginTop={3}>
        {t('Scan and run inference on your phone!')}
      </Box>
      <Box marginTop={4}>
        <QrCode value={getMobileInferenceEndpoint(endpointId)} size={160} logo={LandingLogo} />
      </Box>
      <Box marginTop={4}>
        <Button
          id="turn-off-public-endpoint-from-dialog"
          color="primary"
          disabled={loading}
          startIcon={loading ? <CircularProgress size={20} /> : undefined}
          onClick={async () => {
            if (loading) {
              return;
            }
            setLoading(true);
            try {
              await public_endpoint_api.changePublicEndpointStateById(
                endpointId,
                /* enable */ false,
              );
              refreshPublicEndpointByIdApi({ keys: 'refresh-all' });
            } catch (e) {
              enqueueSnackbar(e.message, { variant: 'error', autoHideDuration: 12000 });
            }
            setLoading(false);
          }}
        >
          {t('Turn It Off')}
        </Button>
      </Box>
      {isCreditReferenceEnabled && (
        <>
          <Box marginTop={5} className={styles.divider}></Box>
          <Box display="flex" alignItems="center" marginTop={5}>
            <img src={coinsIcon} />
          </Box>
          <Box marginTop={5} textAlign="center" className={typographyStyles.body_regular}>
            {t(
              `Anyone with the QR code can run inference and consume your credits. Each inference costs ${inferenceCost} ${
                inferenceCost > 1 ? 'credits' : 'credit'
              } per image.`,
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default MobileInferenceDialogContentEnabled;
