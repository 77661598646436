import BaseDialog from '@/components/Dialogs/BaseDialog';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard';
import ContainerIconSVG from '@/images/deploy/container.svg';
import ContentCopySvg from '@/images/deploy/content_copy.svg';
import { IconButton, Typography } from '@clef/client-library';
import { useTypographyStyles } from '@clef/client-library/src/themes/typography';
import { Box, DialogProps, Link, makeStyles } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import React, { useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { lightfair } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { EnvironmentEnum, getEnv } from '../../../constants';
import ArchSelector from './DockerDeployment/ArchSelector';
import OSOption, { getCurrentOS } from './DockerDeployment/OSOption';
import { useActiveProjectsEnabled } from '@/hooks/useSubscriptions';
export interface DeployModelRunnerProps extends DialogProps {
  open: boolean;
  onClose: () => void | Promise<void>;
  openModelLink: () => void;
  onViewApiKeyAndSecretButtonClick?: () => void | Promise<void>;
}

const useStyles = makeStyles(theme => ({
  linkButton: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  linkButtonIcon: {
    marginLeft: theme.spacing(1),
    fontSize: 16,
  },
  linkButtonLabel: {
    fontSize: 12,
    textDecorationLine: 'underline',
  },
  copyApiCommandButton: {
    background: theme.palette.common.white,
    position: 'absolute',
    bottom: 10,
    right: 10,
    borderRadius: 6,
    width: 30,
    height: 30,
  },
  tabLabel: {
    fontSize: 14,
  },
  leftTitle: {
    display: 'flex',
    alignItems: 'center',
    flex: '1',
    maxWidth: '85px',
  },
}));

const getDockerImageAndPrepare = (): any => {
  const env = getEnv();
  switch (env) {
    case EnvironmentEnum.Development: {
      return {
        imageRepo: '970073041993.dkr.ecr.us-east-2.amazonaws.com/modelrunner-private:latest',
        prepare: 'AWS_PROFILE=dev/staging infra-cli sso',
        environment: '-e LANDING_ENVIRONMENT=dev ',
      };
    }
    case EnvironmentEnum.Staging: {
      return {
        imageRepo: '970073041993.dkr.ecr.us-east-2.amazonaws.com/modelrunner-private:latest',
        prepare: 'AWS_PROFILE=dev/staging infra-cli sso',
        environment: '-e LANDING_ENVIRONMENT=staging ',
      };
    }
    case EnvironmentEnum.Production: {
      return {
        imageRepo: 'public.ecr.aws/landing-ai/deploy:latest',
        prepare: null,
        environment: '',
      };
    }
    case EnvironmentEnum.RegulatedStaging: {
      return {
        imageRepo:
          '970073041993.dkr.ecr.us-east-2.amazonaws.com/modelrunner-private:validation-ready-IMAGE-VERSION',
        prepare: 'AWS_PROFILE=staging infra-cli sso, get the IMAGE-VERSION from Edge team',
        environment: '-e LANDING_ENVIRONMENT=staging ',
      };
    }
    case EnvironmentEnum.RegulatedProduction: {
      return {
        imageRepo: 'public.ecr.aws/landing-ai/deploy:validation-ready-IMAGE-VERSION',
        prepare: 'Get the IMAGE-VERSION here ',
        environment: '',
      };
    }
  }
  return {
    imageRepo: 'public.ecr.aws/landing-ai/deploy:latest',
    prepare: 'Get the IMAGE-VERSION here ',
    environment: `-e LANDING_BASE_URL=${window.location.protocol}//${window.location.host} `,
  };
};

interface DeployCommandProps {
  osType: OSOption;
}

const DeployCommand: React.FC<DeployCommandProps> = ({ osType }) => {
  const styles = useStyles();
  const { prepare, imageRepo, environment } = getDockerImageAndPrepare();
  const getInfoTypeString = (
    option: OSOption,
    isActiveProjectEnabled: boolean,
  ): { [key: string]: string } => {
    switch (option) {
      case OSOption.Mac:
      case OSOption.Linux:
      case OSOption.X86_64_ARM64:
        return {
          prepare: prepare,
          license: `docker run --rm ${environment}\\
  ${imageRepo} \\
  trial-license -k YOUR_API_KEY`,
          docker: isActiveProjectEnabled
            ? `docker run -p 8000:8000 --rm ${environment}\\
  ${imageRepo} \\
  run-model-id -k YOUR_API_KEY -m YOUR_MODEL_ID`
            : `docker run -p 8000:8000 --rm ${environment}\\
  -e LANDING_LICENSE_KEY=<YOUR_LICENSE_KEY> \\
  ${imageRepo} \\
  run-model-id -k YOUR_API_KEY -m YOUR_MODEL_ID`,
          command: `curl -X 'POST' \\
  -H 'Content-Type: multipart/form-data' \\
  -F 'file=@./input_image.jpg' \\
  'http://localhost:8000/images'`,
        };
      case OSOption.JetsonJetpack4x:
        return {
          prepare: prepare,
          license: `docker run --rm ${environment}\\
  ${imageRepo}-jp4 \\
  trial-license -k YOUR_API_KEY`,
          docker: isActiveProjectEnabled
            ? `docker run -p 8000:8000 --rm ${environment}\\
  ${imageRepo}-jp4 \\
  run-model-id -k YOUR_API_KEY -m YOUR_MODEL_ID`
            : `docker run -p 8000:8000 --rm ${environment}\\
  -e LANDING_LICENSE_KEY=<YOUR_LICENSE_KEY> \\
  ${imageRepo}-jp4 \\
  run-model-id -k YOUR_API_KEY -m YOUR_MODEL_ID`,
          command: `curl -X 'POST' \\
  -H 'Content-Type: multipart/form-data' \\
  -F 'file=@./input_image.jpg' \\
  'http://localhost:8000/images'`,
        };
      case OSOption.JetsonJetpack5x:
        return {
          prepare: prepare,
          license: `docker run --rm ${environment}\\
  ${imageRepo}-jp5 \\
  trial-license -k YOUR_API_KEY`,
          docker: isActiveProjectEnabled
            ? `docker run -p 8000:8000 --rm ${environment}\\
  ${imageRepo}-jp5 \\
  run-model-id -k YOUR_API_KEY -m YOUR_MODEL_ID`
            : `docker run -p 8000:8000 --rm ${environment}\\
  -e LANDING_LICENSE_KEY=<YOUR_LICENSE_KEY> \\
  ${imageRepo}-jp5 \\
  run-model-id -k YOUR_API_KEY -m YOUR_MODEL_ID`,
          command: `curl -X 'POST' \\
  -H 'Content-Type: multipart/form-data' \\
  -F 'file=@./input_image.jpg' \\
  'http://localhost:8000/images'`,
        };
      case OSOption.Windows:
        return {
          prepare: prepare,
          license: `docker run --rm ${environment}\`
  ${imageRepo} \`
  trial-license -k YOUR_API_KEY`,
          docker: isActiveProjectEnabled
            ? `docker run -p 8000:8000 --rm ${environment}\`
  ${imageRepo} \`
  run-model-id -k YOUR_API_KEY -m YOUR_MODEL_ID`
            : `docker run -p 8000:8000 --rm ${environment}\`
  -e LANDING_LICENSE_KEY=<YOUR_LICENSE_KEY> \`
  ${imageRepo} \`
  run-model-id -k YOUR_API_KEY -m YOUR_MODEL_ID`,
          command: `curl.exe -X POST \`
  -H "Content-Type: multipart/form-data" \`
  -F "file=@./input_image.jpg" \`
  http://localhost:8000/images`,
        };
      default:
        return {};
    }
  };

  const isActiveProjectEnabled = useActiveProjectsEnabled();
  const infoTypeString = getInfoTypeString(osType, isActiveProjectEnabled);
  const licenseText = infoTypeString.license;
  const dockerText = infoTypeString.docker;
  const commandText = infoTypeString.command;

  // Call the useCopyToClipboard hook within the component.
  const handleCopyLicense = useCopyToClipboard({
    text: licenseText,
    successMessage: t('command copied to clipboard.'),
  });

  const handleCopyDocker = useCopyToClipboard({
    text: dockerText,
    successMessage: t('command copied to clipboard.'),
  });

  const handleCopyCommand = useCopyToClipboard({
    text: commandText,
    successMessage: t('command copied to clipboard.'),
  });

  return (
    <Box>
      <Box position="relative" style={{ marginTop: '12px' }}>
        <Typography variant="body1" style={{ display: 'inline' }}>
          {t(infoTypeString.prepare)}
        </Typography>
        {(EnvironmentEnum.RegulatedProduction || EnvironmentEnum.Local) && (
          <Link
            style={{ cursor: 'pointer' }}
            onClick={() => window.open('https://gallery.ecr.aws/landing-ai/deploy')}
          >
            https://gallery.ecr.aws/landing-ai/deploy
          </Link>
        )}
      </Box>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {!isActiveProjectEnabled && (
          <div style={{ display: 'flex' }}>
            <Box className={styles.leftTitle}>
              <Typography>{t('License')}:</Typography>
            </Box>
            <Box style={{ flex: '1' }}>
              <Box position="relative">
                <SyntaxHighlighter
                  wrapLongLines
                  lineNumberStyle={{ display: 'none' }}
                  language="bash"
                  style={lightfair}
                  customStyle={{
                    background: 'rgba(188, 198, 212, 0.3)',
                    width: '100%',
                    minHeight: '90px',
                  }}
                >
                  {licenseText}
                </SyntaxHighlighter>
                <IconButton className={styles.copyApiCommandButton} onClick={handleCopyLicense}>
                  <img src={ContentCopySvg} width={18} height={18} />
                </IconButton>
              </Box>
            </Box>
          </div>
        )}

        <div style={{ display: 'flex' }}>
          <Box className={styles.leftTitle}>
            <Typography>{t('Run')}:</Typography>
          </Box>
          <Box style={{ flex: '1' }}>
            <Box position="relative">
              <SyntaxHighlighter
                wrapLongLines
                lineNumberStyle={{ display: 'none' }}
                language="bash"
                style={lightfair}
                customStyle={{
                  background: 'rgba(188, 198, 212, 0.3)',
                  width: '100%',
                  minHeight: '105px',
                }}
              >
                {dockerText}
              </SyntaxHighlighter>
              <IconButton className={styles.copyApiCommandButton} onClick={handleCopyDocker}>
                <img src={ContentCopySvg} width={18} height={18} />
              </IconButton>
            </Box>
          </Box>
        </div>

        <div style={{ display: 'flex' }}>
          <Box className={styles.leftTitle}>
            <Typography>{t('Try')}:</Typography>
          </Box>
          <Box style={{ flex: '1' }}>
            <Box position="relative">
              <SyntaxHighlighter
                wrapLongLines
                lineNumberStyle={{ display: 'none' }}
                language="bash"
                style={lightfair}
                customStyle={{
                  background: 'rgba(188, 198, 212, 0.3)',
                  width: '100%',
                }}
              >
                {commandText}
              </SyntaxHighlighter>
              <IconButton className={styles.copyApiCommandButton} onClick={handleCopyCommand}>
                <img src={ContentCopySvg} width={18} height={18} />
              </IconButton>
            </Box>
          </Box>
        </div>
      </div>
    </Box>
  );
};

const DeployModelRunner: React.FC<DeployModelRunnerProps> = ({
  open,
  onClose,
  openModelLink,
  onViewApiKeyAndSecretButtonClick,
}) => {
  const typographyStyles = useTypographyStyles();

  const styles = useStyles();
  const [selectedOS, setSelectedOS] = useState<OSOption>(getCurrentOS());

  const onSelect = (value: string) => {
    setSelectedOS(OSOption[value as keyof typeof OSOption]);
  };
  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      containerProps={{ minWidth: 650, paddingX: '30px', paddingY: '30px' }}
      maxWidth="md"
    >
      <Box display="flex" justifyContent="center">
        <img src={ContainerIconSVG} width={100} />
      </Box>
      <Box
        textAlign="center"
        className={typographyStyles.h3_semibold}
        marginBottom={7}
        style={{ marginBottom: '12px' }}
      >
        {t('Container Deploy')}
      </Box>
      <ArchSelector onSelect={onSelect} />
      <DeployCommand osType={selectedOS} />
      <Box display="flex" justifyContent="space-between" width="100%" marginTop={2}>
        <Box>
          <Link className={styles.linkButton} onClick={onViewApiKeyAndSecretButtonClick}>
            <Typography className={styles.linkButtonLabel}>{t('Get API Key')}</Typography>
            <LaunchIcon className={styles.linkButtonIcon} />
          </Link>
          <Link className={styles.linkButton} onClick={openModelLink}>
            <Typography className={styles.linkButtonLabel}>{t('Get Model ID')}</Typography>
            <LaunchIcon className={styles.linkButtonIcon} />
          </Link>
        </Box>
        <Box>
          <Link
            className={styles.linkButton}
            style={{
              marginLeft: 'auto',
            }}
            onClick={() => window.open('https://gallery.ecr.aws/landing-ai/deploy')}
          >
            <Typography className={styles.linkButtonLabel}>
              {t('View All Image Versions')}
            </Typography>
            <LaunchIcon className={styles.linkButtonIcon} />
          </Link>
          <Link
            className={styles.linkButton}
            style={{
              marginLeft: 'auto',
            }}
            onClick={() => window.open('https://landing-ai.github.io/landingai-python/')}
          >
            <Typography className={styles.linkButtonLabel}>
              {t('See Examples in Our SDK')}
            </Typography>
            <LaunchIcon className={styles.linkButtonIcon} />
          </Link>
        </Box>
      </Box>
    </BaseDialog>
  );
};

export default DeployModelRunner;
