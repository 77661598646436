import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  modelListWrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 332,
    height: 'calc(100vh - 108px)',
    borderLeft: `2px solid ${theme.palette.indigo[200]}`,
    overflowY: 'auto',
  },
  modelListCloseBtn: {
    height: 32,
    borderRadius: 10,
    border: `1px solid ${theme.palette.indigo[300]}`,
    color: theme.palette.indigo[900],
  },
  modelInfoCard: {
    border: `1px solid ${theme.palette.greyModern[300]}`,
    borderRadius: 10,
    padding: theme.spacing(5, 4),
    marginBottom: theme.spacing(4),
    '&:hover': {
      border: `1.5px solid ${theme.palette.greyModern[300]}`,
      boxShadow: `0px 1px 2px 1px rgba(48, 55, 79, 0.08), 0px 1px 2px 0px rgba(48, 55, 79, 0.16);`,
    },
    '&.disabled:hover': {
      border: `1px solid ${theme.palette.greyModern[300]}`,
      boxShadow: 'none',
    },
  },
  toggleShowAllModelsBtn: {
    height: 32,
    width: '100%',
  },
  toggleShowLessModelsBtn: {
    height: 32,
    width: '100%',
    marginTop: 12,
  },
  moreActionBtn: {
    marginRight: theme.spacing(4),
  },
  inProgressChip: {
    width: 'fit-content',
    height: 20,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1.5),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.blue[50],
    borderRadius: 5,
    marginBottom: theme.spacing(4),
  },
  stepperRoot: {
    boxShadow: 'none!important',
    padding: 0,
  },
  stepLabel: {
    fontWeight: 'bold',
    fontSize: 14,
  },
  modelCardPerformanceNumber: {
    fontSize: 18,
    fontWeight: 600,
    color: theme.palette.greyModern[800],
  },
  modelCardPerformanceSetText: {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.greyModern[500],
  },
  modelCardPerformanceF1Text: {
    color: theme.palette.greyModern[500],
    fontSize: 14,
    fontWeight: 600,
  },
  modelCardPerformanceArraySetName: {
    '&:not(:last-child)': {
      paddingRight: theme.spacing(4),
    },
  },
  modelCardPerformanceConfusionMatrixText: {
    color: theme.palette.greyModern[500],
    fontSize: 14,
    fontWeight: 500,
  },
  modelCardPerformanceConfusionMatrixCorrectNumber: {
    color: theme.palette.green[500],
    fontSize: 14,
    fontWeight: 500,
  },
  modelCardPerformanceConfusionMatrixIncorrectNumber: {
    color: theme.palette.red[500],
    fontSize: 14,
    fontWeight: 500,
  },
  modelCardTryModelBtnWrapper: {
    width: '100%',
  },
  modelCardTryModelBtn: {
    width: '100%',
    border: `1px solid ${theme.palette.greyModern[300]}`,
    marginTop: theme.spacing(2),
    '&:hover': {
      color: theme.palette.blue[600],
      backgroundColor: theme.palette.blue[50],
    },
  },
  modelCardViewConfusionMatrixBtn: {
    width: '100%',
    backgroundColor: theme.palette.greyModern[100],
    '&:hover': {
      color: theme.palette.blue[600],
      backgroundColor: theme.palette.blue[50],
    },
  },
  infoIcon: {
    height: 16,
    width: 16,
    color: theme.palette.blue[300],
  },
  modelDetailsTrainingInProgressDialogTitle: {
    borderBottom: `1px solid ${theme.palette.greyModern[300]}`,
    boxShadow: `0 2px 4px 0 ${theme.palette.greyModern[300]}`,
  },

  modelDetailsTrainingInProgressChip: {
    width: 'fit-content',
    height: 24,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1.5),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.blue[50],
    borderRadius: 5,
  },
  modelDetailsTrainingInProgressChipText: {
    color: theme.palette.primary.main,
  },
  modelDetailsTrainingInProgressDialogContent: {
    padding: theme.spacing(7, 20),
  },
  viewFullReportContainer: {
    backgroundColor: theme.palette.blue[50],
    borderRadius: 8,
    padding: theme.spacing(3, 5),
  },
  viewFullReportBtn: {
    color: theme.palette.blue[600],
  },
  modelDetailsPerformanceDialogContent: {
    padding: theme.spacing(5, 6),
  },
  tryModelBtnModelDetailsPerformance: {
    color: theme.palette.common.white,
  },
  confusionMatrixAccordionRow: {
    height: 44,
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.greyModern[300]}`,
    },
  },
  modelDetailsDialogInfoIcon: {
    height: 16,
    width: 16,
    color: theme.palette.blue[200],
  },
  modelDetailsDialogPerformanceNumber: {
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.greyModern[800],
  },
  modelDetailsDialogPerformanceSetText: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.greyModern[500],
  },
  gm600: {
    color: theme.palette.greyModern[600],
  },
  firstCol: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 40,
    width: 280,
  },
  midCol: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  lastCol: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: 160,
    width: 200,
  },
  popper: {
    pointerEvents: 'auto',
  },
  arrow: {
    color: theme.palette.blue[700],
  },
  tooltip: {
    padding: theme.spacing(4),
    backgroundColor: theme.palette.blue[700],
  },
  gotIt: {
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 700,
  },
  linkText: {
    fontWeight: 'bold',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  fontSize16: {
    fontSize: 16,
  },
  endedBadge: {
    color: theme.palette.grey[500],
    backgroundColor: theme.palette.grey[100],
    display: 'inline-block',
    borderRadius: 4,
    padding: theme.spacing(1, 2),
    fontSize: 12,
  },
}));

export default useStyles;
