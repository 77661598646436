import React, { useEffect } from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';

import { useStateSyncSearchParams } from '@clef/client-library';
import CloudDataSvg from '@/images/deploy/cloud_data.svg';
import DeviceMonitorDashboard from '@/pages/continuous_learning/DeviceMonitorDashboard';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { ApiCommand } from './ApiCommand';
import { Endpoint } from '@clef/shared/types';
import MobileInferenceCard from './MobileInferenceCard';
import { ClientFeatures, useFeatureGateEnabled } from '@/hooks/useFeatureGate';

const useStyles = makeStyles(theme => ({
  container: {
    background: theme.palette.greyModern[50],
    padding: theme.spacing(10),
    overflow: 'hidden',
    flex: 2,
    borderRadius: 8,
    '& + $container': {
      marginLeft: theme.spacing(7),
    },
  },
}));

export type HistoricalDataProps = {
  endpoint: Endpoint;
  inferenceCost: number;
  onViewApiKeyAndSecretButtonClick?: () => void | Promise<void>;
  onQrCodeGenerated?: () => void;
};

export const HistoricalData: React.FC<HistoricalDataProps> = ({
  endpoint,
  inferenceCost,
  onViewApiKeyAndSecretButtonClick,
  onQrCodeGenerated,
}) => {
  const styles = useStyles();
  const { id: projectId } = useGetSelectedProjectQuery().data ?? {};
  const endpointName = endpoint.name;
  const isMobileInferenceEnabled = !useFeatureGateEnabled(ClientFeatures.DisableMobileInference);

  const [, setLocalDeviceId] = useStateSyncSearchParams('device', endpointName);

  useEffect(() => {
    if (endpointName) {
      setLocalDeviceId(endpointName);
    }
  }, [endpointName, setLocalDeviceId]);

  if (!projectId) return null;

  return (
    <DeviceMonitorDashboard
      edgeProjectId={projectId}
      hideDeviceSwitcher
      emptyStateComponent={
        <Box display="flex" alignItems="stretch">
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            className={styles.container}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              marginBottom={7}
            >
              <img src={CloudDataSvg} />
              <Typography variant="h3">{t('API Inference')}</Typography>
            </Box>

            {endpoint.type === 'dynamic' && (
              <ApiCommand
                endpoint={endpoint}
                shouldWrapCodeLines
                onViewApiKeyAndSecretButtonClick={onViewApiKeyAndSecretButtonClick}
              />
            )}
          </Grid>

          {isMobileInferenceEnabled && endpoint.type === 'dynamic' && (
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              className={styles.container}
              style={{ flex: 1, maxWidth: 400 }}
            >
              <MobileInferenceCard
                endpointId={endpoint.id}
                inferenceCost={inferenceCost}
                onQrCodeGenerated={onQrCodeGenerated}
              />
            </Grid>
          )}
        </Box>
      }
    />
  );
};

export default HistoricalData;
