import { TabManager, Typography } from '@clef/client-library';
import { Box, CircularProgress, Link, makeStyles } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { lightfair } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { useApiKey } from '@/hooks/api/useUserApi';
import { useApiCommandMap } from '@/hooks/api/useApiCommandMap';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard';
import { ClientFeatures, useFeatureGateEnabled } from '@/hooks/useFeatureGate';
import { Button } from '@clef/client-library';
import { Endpoint, APICommandType, ApiInterface } from '@clef/shared/types';

const useStyles = makeStyles(theme => ({
  viewApiKeyAndSecretButton: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  viewApiKeyAndSecretButtonIcon: {
    marginLeft: theme.spacing(1),
    fontSize: 16,
  },
  viewApiKeyAndSecretButtonLabel: {
    fontSize: 12,
    textDecorationLine: 'underline',
  },
  copyApiCommandButton: {
    background: theme.palette.common.white,
    position: 'absolute',
    bottom: 40,
    right: 10,
  },
  copyLegacyKeyMessage: {
    color: theme.palette.greyModern[600],
  },
  extraButton: {
    color: theme.palette.primary.main,
    textDecorationLine: 'underline',
    cursor: 'pointer',
  },
  tabLabel: {
    fontSize: 14,
  },
}));

export type ApiCommandProps = {
  endpoint?: Endpoint;
  apiInterface?: ApiInterface;
  shouldWrapCodeLines?: boolean;
  onViewApiKeyAndSecretButtonClick?: () => void | Promise<void>;
};

export const ApiCommandComponent: React.FC<ApiCommandProps & { type: APICommandType }> = ({
  type,
  apiInterface,
  shouldWrapCodeLines,
  onViewApiKeyAndSecretButtonClick,
}) => {
  const styles = useStyles();

  const [apiKey] = useApiKey({});

  const showApiKey = !useFeatureGateEnabled(ClientFeatures.DisableApiKey);

  const v1ApiCommand = apiInterface?.command?.replace(
    "--header 'apikey: YOUR_API_KEY'",
    "--header 'apikey: YOUR_API_KEY' --header 'apisecret: YOUR_API_SECRET'",
  );

  const copyToClipboard = useCopyToClipboard({
    text: apiInterface?.command ?? '',
    successMessage: t('API command copied to clipboard.'),
  });

  const copyLegacyCommand = useCopyToClipboard({
    text: v1ApiCommand ?? '',
    successMessage: t('API command copied to clipboard.'),
  });

  return (
    <Box position="relative">
      <Box overflow="scroll">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          position="relative"
          width="100%"
          minWidth={600}
        >
          <Box position="relative" data-testid="fre-deploy-page-api-command-box" width="100%">
            <SyntaxHighlighter
              wrapLongLines={shouldWrapCodeLines}
              lineNumberStyle={{ display: 'none' }}
              language="bash"
              style={lightfair}
              customStyle={{
                margin: 0,
                background: 'rgba(188, 198, 212, 0.3)',
                width: '100%',
              }}
            >
              {apiInterface?.command ?? ''}
            </SyntaxHighlighter>
          </Box>

          <Box display="flex" alignItems="center" width="100%" marginTop={2}>
            {showApiKey && onViewApiKeyAndSecretButtonClick && (
              <Link
                id="fre-deploy-page-view-api-key-and-secret-button"
                className={styles.viewApiKeyAndSecretButton}
                onClick={onViewApiKeyAndSecretButtonClick}
              >
                <Typography className={styles.viewApiKeyAndSecretButtonLabel}>
                  {t('View API Key & Secret')}
                </Typography>
                <LaunchIcon className={styles.viewApiKeyAndSecretButtonIcon} />
              </Link>
            )}
            {!!apiKey?.apiKeyHash && type === APICommandType.Curl && (
              <Box className={styles.copyLegacyKeyMessage} marginLeft="auto">
                {t(
                  'If you have a legacy API Key and Secret, {{clickHere}} to copy the cURL command.',
                  {
                    clickHere: (
                      <span
                        role="button"
                        onClick={copyLegacyCommand}
                        className={styles.extraButton}
                      >
                        {t('click here')}
                      </span>
                    ),
                  },
                )}
              </Box>
            )}
            {type === APICommandType.Python && (
              <Box className={styles.copyLegacyKeyMessage} marginLeft="auto">
                {t('Please refer to {{sdkLink}}', {
                  sdkLink: (
                    <span
                      role="button"
                      onClick={() => window.open(apiInterface?.refererLinks?.sdk)}
                      className={styles.extraButton}
                    >
                      {t('Python SDK documentation')}
                    </span>
                  ),
                })}
              </Box>
            )}
            {type === APICommandType.Javascript && (
              <Box className={styles.copyLegacyKeyMessage} marginLeft="auto">
                {t('Please refer to {{sdkLink}} and {{exampleCode}}', {
                  sdkLink: (
                    <span
                      role="button"
                      onClick={() => window.open(apiInterface?.refererLinks?.sdk)}
                      className={styles.extraButton}
                    >
                      {t('Javascript documentation')}
                    </span>
                  ),
                  exampleCode: (
                    <span
                      role="button"
                      onClick={() => window.open(apiInterface?.refererLinks?.example)}
                      className={styles.extraButton}
                    >
                      {t('example code')}
                    </span>
                  ),
                })}
              </Box>
            )}
            {type === APICommandType.Sql && (
              <Box className={styles.copyLegacyKeyMessage} marginLeft="auto">
                {t('{{documentationLink}}', {
                  documentationLink: (
                    <span
                      role="button"
                      onClick={() => window.open(apiInterface?.refererLinks?.sdk)}
                      className={styles.extraButton}
                    >
                      {t('Please refer to SQL inference function documentation')}
                    </span>
                  ),
                })}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Button
        id="fre-deploy-page-copy-api-command-button"
        className={styles.copyApiCommandButton}
        onClick={copyToClipboard}
      >
        {t('Copy')}
      </Button>
    </Box>
  );
};

export const ApiCommand: React.FC<ApiCommandProps> = props => {
  const styles = useStyles();
  const { endpoint } = props;

  const { data: apiCommandMap, isLoading } = useApiCommandMap({
    endpointId: endpoint?.id,
  });

  if (isLoading) {
    return (
      <Box width="100%">
        <CircularProgress size="26px" />
      </Box>
    );
  }

  return (
    <Box width="100%">
      <TabManager
        classes={{ tabLabel: styles.tabLabel }}
        tabs={[
          // Python SDK tab
          ...(apiCommandMap?.[APICommandType.Python]
            ? [
                {
                  key: APICommandType.Python,
                  title: t('Python SDK'),
                  component: (
                    <ApiCommandComponent
                      {...props}
                      apiInterface={apiCommandMap[APICommandType.Python]}
                      type={APICommandType.Python}
                    />
                  ),
                },
              ]
            : []),
          // Javascript SDK tab
          ...(apiCommandMap?.[APICommandType.Javascript]
            ? [
                {
                  key: APICommandType.Javascript,
                  title: t('Javascript SDK'),
                  component: (
                    <ApiCommandComponent
                      {...props}
                      apiInterface={apiCommandMap[APICommandType.Javascript]}
                      type={APICommandType.Javascript}
                    />
                  ),
                },
              ]
            : []),
          // cURL Command tab
          ...(apiCommandMap?.[APICommandType.Curl]
            ? [
                {
                  key: APICommandType.Curl,
                  title: t('cURL Command'),
                  component: (
                    <ApiCommandComponent
                      {...props}
                      apiInterface={apiCommandMap[APICommandType.Curl]}
                      type={APICommandType.Curl}
                    />
                  ),
                },
              ]
            : []),
          // SQL Inference Function tab
          ...(apiCommandMap?.[APICommandType.Sql]
            ? [
                {
                  key: APICommandType.Sql,
                  title: t('SQL Inference Function'),
                  component: (
                    <ApiCommandComponent
                      {...props}
                      apiInterface={apiCommandMap[APICommandType.Sql]}
                      type={APICommandType.Sql}
                    />
                  ),
                },
              ]
            : []),
        ]}
      />
    </Box>
  );
};

export default ApiCommand;
