import { Box, Step, StepLabel, Stepper } from '@material-ui/core';
import React, { useMemo } from 'react';

import { ModelStatus } from '@clef/shared/types';

import { TrainingStepType } from '@/constants/model_train';
import { useModelStatusQuery } from '@/serverStore/projectModels';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';

import { ClientFeatures, useFeatureGateEnabled } from '@/hooks/useFeatureGate';
import { Typography } from '@clef/client-library';
import ProvisionGpuTimer from '../ModelTrainingInProgressV2/ProvisionGpuTimer';
import useStyles from './styles';

interface ModelTrainingProgressProps {
  modelId: string;
}

const steps = [
  {
    type: TrainingStepType.Snapshot,
    label: t('Preparing data snapshot'),
  },
  {
    type: TrainingStepType.Provision,
    label: t('Provisioning GPU'),
  },
  {
    type: TrainingStepType.Train,
    label: t('Training & learning'),
  },
  {
    type: TrainingStepType.Calculation,
    label: t('Calculating performance'),
  },
];

const ModelTrainingProgress: React.FC<ModelTrainingProgressProps> = ({ modelId }) => {
  const styles = useStyles();
  const { id: projectId } = useGetSelectedProjectQuery().data ?? {};
  const enableGPUTimer = useFeatureGateEnabled(ClientFeatures.ShowGpuTimer);

  const { data: modelStatus } = useModelStatusQuery(projectId, modelId);

  const activeStep = useMemo(() => {
    if (!modelStatus) {
      return undefined;
    }
    const { status, metricsReady } = modelStatus;
    if (status === ModelStatus.Created) {
      return 1;
    } else if (status === ModelStatus.Starting) {
      return 1;
    } else if (status === ModelStatus.Training || status === ModelStatus.Evaluating) {
      return 2;
    } else if (status === ModelStatus.Publishing || !metricsReady) {
      return 3;
    }
    return undefined;
  }, [modelStatus]);

  return (
    <Stepper
      activeStep={activeStep}
      orientation="vertical"
      elevation={0}
      classes={{ root: styles.stepperRoot }}
    >
      {steps.map(step => (
        <Step key={step.label}>
          <StepLabel>
            <Box
              display={'flex'}
              flexDirection="row"
              justifyContent={'flex-start'}
              alignItems={'center'}
            >
              <Typography className={styles.stepLabel}>{step.label}</Typography>
              {enableGPUTimer &&
                modelStatus?.createdAt &&
                step.type === TrainingStepType.Provision &&
                activeStep === 1 && (
                  <ProvisionGpuTimer startTime={modelStatus?.createdAt} modelId={modelId} />
                )}
              {/* {modelStatus?.createdAt && <ProvisionGpuTimer startTime={modelStatus?.createdAt} />} */}
            </Box>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default ModelTrainingProgress;
