import React, { useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import { Box, Dialog, makeStyles } from '@material-ui/core';
import { PredictContextProvider } from '@/components/Predict/predictContext';
import { useGetEndpointListQuery } from '@/serverStore/endpoints';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { ApiResponseLoader, useStateSyncSearchParams, Typography } from '@clef/client-library';
import { EndpointListItem } from '@clef/shared/types';
import { getDateNumber } from '@clef/shared/utils';
import { useHistory } from 'react-router';
import EndpointDetails from './EndpointDetails';
import { SidebarSections } from './EndpointsSidebar';
import CLEF_PATH from '@/constants/path';
import PredictDialog from './PredictDialog';
import BaseDialog from '@/components/Dialogs/BaseDialog';
import SetupDeployment from './SetupDeployment';
import CloudDataSvg from '@/images/deploy/cloud_data.svg';
import ModelDetailsDialog from '@/pages/DataBrowser/ModelPerformance/ModelDetailsDialog';
import ApiCommand from './ApiCommand';
import { useTypographyStyles } from '@clef/client-library/src/themes/typography';

const useStyles = makeStyles(theme => ({
  commonFontWeight: {
    fontWeight: 500,
  },
  endpointDetailsWrapper: {
    overflowX: 'scroll',
    width: '100%',
    height: 'calc(100% - 32px)',
    paddingLeft: 20,
    paddingRight: 60,
  },
  marginBottom6: {
    marginBottom: theme.spacing(6),
  },
  switchModelMenu: {
    width: 400,
  },
}));

interface IProps {
  setCreateEndpointDialogOpen: (value: boolean) => void;
}

export const EndpointSection: React.FC<IProps> = ({ setCreateEndpointDialogOpen }) => {
  const styles = useStyles();
  const history = useHistory();
  const { id: projectId, labelType, datasetId } = useGetSelectedProjectQuery().data ?? {};
  const [activeSection, setActiveSection] = useStateSyncSearchParams(
    'sidebar_section',
    SidebarSections.CloudDeployment,
  );
  const [selectedEndpointId, setSelectedEndpointId] = useStateSyncSearchParams(
    'selected_endpoint_id',
    '',
  );
  const typographyStyles = useTypographyStyles();
  const [predictDialogOpen, setPredictDialogOpen] = useState(false);
  const [updateDeploymentDialogOpen, setUpdateDeploymentDialogOpen] = useState(false);
  const [apiCommandDialogOpen, setApiCommandDialogOpen] = useState(false);
  const [modelDetailsDialogOpen, setModelDetailsDialogOpen] = useState(false);

  const {
    data: endpointsData,
    isFetching: endpointsFetching,
    error: endpointsError,
  } = useGetEndpointListQuery(projectId ?? 0);

  // const { data: apiInterfaceMap } = useGetApiInterfaceMapQuery(projectId ?? 0);
  // console.log('!!!!!!!!!!!!!!apiInterfaceMap', apiInterfaceMap);

  const [cloudEndpoints, staticEndpoints, edgeEndpoints] = useMemo(() => {
    const sortedEndpoint = (endpointsData ?? []).sort((a, b) => {
      return getDateNumber(b.endpoint.creationTime) - getDateNumber(a.endpoint.creationTime);
    });
    return sortedEndpoint.reduce(
      (accum: [EndpointListItem[], EndpointListItem[], EndpointListItem[]], data) => {
        if (data.endpoint.type === 'dynamic') {
          accum[0].push(data);
        } else if (data.endpoint.type === 'static') {
          accum[1].push(data);
        } else {
          accum[2].push(data);
        }
        return accum;
      },
      [[], [], []],
    );
  }, [endpointsData]);

  const currentEndpoint = selectedEndpointId
    ? (endpointsData ?? []).find(endpoint => endpoint.endpoint.id === selectedEndpointId)
    : cloudEndpoints[0] ?? staticEndpoints[0] ?? edgeEndpoints[0];

  useEffect(() => {
    if (currentEndpoint && currentEndpoint.endpoint.id !== selectedEndpointId) {
      setSelectedEndpointId(currentEndpoint.endpoint.id);
    }
  }, [currentEndpoint, selectedEndpointId]);

  // Auto set url param with device id
  const endpointName = currentEndpoint?.endpoint.name ?? '';
  const [, setLocalDeviceId] = useStateSyncSearchParams('device', endpointName);

  useEffect(() => {
    if (!currentEndpoint) {
      return;
    }
    if (
      currentEndpoint.endpoint.type === 'dynamic' &&
      activeSection !== SidebarSections.CloudDeployment
    ) {
      setActiveSection(SidebarSections.CloudDeployment);
    } else if (
      currentEndpoint.endpoint.type === 'static' &&
      activeSection !== SidebarSections.StaticDeployment
    ) {
      setActiveSection(SidebarSections.StaticDeployment);
    } else if (
      currentEndpoint.endpoint.type === 'edge' &&
      activeSection !== SidebarSections.EdgeDeployment
    ) {
      setActiveSection(SidebarSections.EdgeDeployment);
    }
    setLocalDeviceId(endpointName);
  }, [currentEndpoint, selectedEndpointId]);

  useEffect(() => {
    if (
      ![
        SidebarSections.CloudDeployment,
        SidebarSections.StaticDeployment,
        SidebarSections.EdgeDeployment,
      ].includes(activeSection as SidebarSections) ||
      !!currentEndpoint ||
      endpointsFetching
    ) {
      return;
    }
    setCreateEndpointDialogOpen(true);
  }, [activeSection, endpointsFetching, endpointsFetching, setCreateEndpointDialogOpen]);

  if (!projectId) {
    return null;
  }

  return (
    <PredictContextProvider
      projectId={projectId}
      datasetId={datasetId}
      labelType={labelType}
      modelId={currentEndpoint?.model?.jobId}
    >
      <ApiResponseLoader
        response={currentEndpoint}
        loading={endpointsFetching}
        error={endpointsError}
      >
        {currentEndpoint => {
          return (
            <Box className={cx(styles.endpointDetailsWrapper)}>
              <EndpointDetails
                endpoint={currentEndpoint ?? null}
                onModelDetailsButtonClick={() => setModelDetailsDialogOpen(true)}
                onUpdateDeploymentButtonClick={() => setUpdateDeploymentDialogOpen(true)}
                onPredictButtonClick={() => setPredictDialogOpen(true)}
                onViewApiCommandButtonClick={() => setApiCommandDialogOpen(true)}
                onViewApiKeyAndSecretButtonClick={() => {
                  history.push(CLEF_PATH.organizationSettings + '?tab=api_key_v2');
                }}
                labelType={labelType}
              />
            </Box>
          );
        }}
      </ApiResponseLoader>
      <PredictDialog
        open={predictDialogOpen}
        onClose={() => setPredictDialogOpen(false)}
        modelInfo={{
          id: currentEndpoint?.model?.jobId,
          threshold: currentEndpoint?.model?.threshold,
        }}
        selectedEndpointId={currentEndpoint?.endpoint.id}
      />
      {currentEndpoint && (
        <>
          <BaseDialog
            open={updateDeploymentDialogOpen}
            onClose={() => setUpdateDeploymentDialogOpen(false)}
          >
            <SetupDeployment
              endpointId={currentEndpoint?.endpoint.id}
              onModelDeploySuccess={() => {
                setUpdateDeploymentDialogOpen(false);
              }}
              initialModelId={currentEndpoint.model?.jobId}
              initialThreshold={currentEndpoint.model?.threshold}
              classes={{
                switchModelMenu: styles.switchModelMenu,
              }}
            />
          </BaseDialog>

          <Dialog
            open={apiCommandDialogOpen}
            onClose={() => setApiCommandDialogOpen(false)}
            maxWidth="lg"
          >
            <Box padding={6}>
              <Typography
                variant="h2"
                className={cx(styles.commonFontWeight, styles.marginBottom6)}
              >
                {t('API Command')}
              </Typography>
              <Box display="flex" justifyContent="center">
                <img src={CloudDataSvg} />
              </Box>
              <Box textAlign="center" className={typographyStyles.h3_semibold} marginBottom={7}>
                {t('API Inference')}
              </Box>
              <ApiCommand
                endpoint={currentEndpoint?.endpoint}
                // apiInterfaceMap={apiInterfaceMap}
                onViewApiKeyAndSecretButtonClick={() => {
                  setApiCommandDialogOpen(false);
                  history.push(CLEF_PATH.organizationSettings + '?tab=api_key_v2');
                }}
              />
            </Box>
          </Dialog>

          {/* Force mount/unmount the dialog to trigger its lifecycle to reset states */}
          {modelDetailsDialogOpen && (
            <ModelDetailsDialog
              open
              onClose={() => setModelDetailsDialogOpen(false)}
              modelId={currentEndpoint?.model?.jobId}
            />
          )}
        </>
      )}
    </PredictContextProvider>
  );
};
