import ContainerIconSvg from '@/images/deploy/container.svg';
import CloudDeploymentSvg from '@/images/deploy/cloud_deployment.svg';
import EdgeDeploymentSvg from '@/images/deploy/edge_deployment.svg';
import { ClientFeatures, useFeatureGateEnabled } from '@/hooks/useFeatureGate';
import { Button, Typography } from '@clef/client-library';
import { Box, CircularProgress, Grid, makeStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import cx from 'classnames';
import * as React from 'react';
import { useSubscriptionPlanSettings } from '@/hooks/api/useSubscriptionApi';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 28,
    width: '100%',
    alignItems: 'center',
  },
  legacyContainer: {
    marginTop: 12,
    display: 'flex',
    height: 'calc(100vh - 368px)',
  },
  emptyImg: {
    marginBottom: 28,
  },
  h2: {
    color: theme.palette.grey[900],
  },
  h4: {
    color: theme.palette.grey[900],
  },
  subtitle: {
    fontFamily: 'Commissioner',
    fontWeight: 400,
    fontSize: 14,
    maxWidth: 390,
    lineHeight: '20px',
    color: theme.palette.grey[600],
  },
  deploymentBlockContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: 40,
    width: '100%',
    maxWidth: 880,
  },
  deploymentBlock: {
    flexBasis: 'calc(33.33% - 28px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 20,
  },
  top12: {
    marginTop: 12,
  },
  verticalMargin: {
    marginTop: 16,
    marginBottom: 28,
  },
  tipsBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-start',
    gap: 6,
  },
  tip: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  checkmarkBlock: {
    width: 20,
    height: 20,
    padding: '2px 4px 2px 0',
  },
  checkIcon: {
    fontSize: 16,
    color: theme.palette.grey[700],
  },
  learnMoreButton: {
    marginRight: 12,
  },
  icon: {
    width: 80,
    height: 80,
  },
}));

interface DeoploymentBlockProps {
  icon: string;
  title: string;
  tips: string[];
  button: React.ReactNode;
}

const DeploymentBlock: React.FC<DeoploymentBlockProps> = props => {
  const styles = useStyles();
  return (
    <Box className={styles.deploymentBlock}>
      <img className={styles.icon} src={props.icon} />
      <Typography align="center" variant="h4" className={styles.h4}>
        {props.title}
      </Typography>
      <Box className={styles.tipsBlock}>
        {props.tips.map(tip => (
          <Box className={styles.tip} key={tip}>
            <Box className={styles.checkmarkBlock}>
              <CheckIcon className={styles.checkIcon} />
            </Box>
            <Typography className={styles.subtitle}>{tip}</Typography>
          </Box>
        ))}
      </Box>
      {props.button}
    </Box>
  );
};

interface EmptyDeploymentPageProps {
  endpointsLoading: boolean;
  onCreateEndpoint: () => void;
  onDownloadEdge: () => void;
}

export const EmptyDeploymentPage: React.FC<EmptyDeploymentPageProps> = ({
  endpointsLoading,
  onCreateEndpoint,
  onDownloadEdge,
}) => {
  const isModelRunnerReady = useFeatureGateEnabled(ClientFeatures.ModelRunnerReady);
  const styles = useStyles();
  const [orgPlanSetting] = useSubscriptionPlanSettings('anystring');

  if (endpointsLoading) {
    return (
      <Grid justifyContent="center" alignItems="center" className={styles.container}>
        <CircularProgress size="32px" data-testid="deploy-page-loader" />
      </Grid>
    );
  }

  return (
    <Grid justifyContent="center" direction="column" className={cx(styles.container, styles.top12)}>
      <Typography align="center" variant="h2" className={styles.h2}>
        {t('Flexible LandingLens Deployment Options')}
      </Typography>
      <Typography align="center" className={styles.subtitle}>
        {t(
          'Cloud and edge device deployment capabilities make integration into existing environments easy - deploy and test your model with just a few mouse clicks.',
        )}
      </Typography>
      <Box className={styles.deploymentBlockContainer}>
        <DeploymentBlock
          icon={CloudDeploymentSvg}
          title={t('Cloud Deployment')}
          tips={[
            t('Easily scale and manage projects in different locations'),
            t('Reduce time to deploy without needing edge device configuration'),
            t('Quickly re-train models upon detecting performance changes'),
          ]}
          button={
            <Button
              id="deploy-empty-page-create-endpoint-button"
              variant="contained"
              color="primary"
              onClick={onCreateEndpoint}
            >
              {t('Create Endpoint')}
            </Button>
          }
        />
        {!orgPlanSetting?.hideLeAndDocker && (
          <DeploymentBlock
            icon={EdgeDeploymentSvg}
            title={t('Edge Deployment')}
            tips={[
              t('An all-in-one application for end-to-end AI deployment'),
              t(
                'Seamless communication with industrial cameras and programmable logic controllers (PLC) for real-time decisions',
              ),
              t(
                'In-app support for pre-processing and post-processing scripts to manage images and output results',
              ),
            ]}
            button={
              <Button
                id="deploy-empty-page-download-edge-button"
                variant="contained"
                color="primary"
                onClick={onDownloadEdge}
              >
                {t('Download LandingEdge')}
              </Button>
            }
          />
        )}
        {isModelRunnerReady && !orgPlanSetting?.hideLeAndDocker && (
          <DeploymentBlock
            icon={ContainerIconSvg}
            title={t('Container Deployment')}
            tips={[
              t('Easily deploy models in a wide range of self-hosted settings using Docker'),
              t('Run in Kubernetes'),
              t('Rapidly scale deployments'),
              t('Deploy on a range of NVIDIA Jetson devices'),
            ]}
            button={
              <Button
                id="deploy-empty-page-container-deploy-button"
                variant="contained"
                color="primary"
                onClick={() => window.open('https://support.landing.ai/docs/docker-deploy')}
              >
                {t('Learn More')}
              </Button>
            }
          />
        )}
      </Box>
    </Grid>
  );
};
