import React, { useCallback, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router';
import {
  Popover,
  Box,
  MenuList,
  MenuItem,
  Tooltip,
  Slider,
  CircularProgress,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { useAtom } from 'jotai';

import { Typography, Dropdown, Button, useLocalStorage, IconButton } from '@clef/client-library';
import { LabelType, ModelStatus } from '@clef/shared/types';
import { CONFIDENCE_THRESHOLD_OPTIONS } from '@clef/shared/constants';

import CLEF_PATH from '@/constants/path';
import {
  SelectedModelIdForProjectsStorageKey,
  ShownModelSwitcherTipsKey,
} from '@/constants/data_browser';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import {
  useCurrentProjectModelInfoQuery,
  useSucceedProjectModels,
  useModelStatusQuery,
} from '@/serverStore/projectModels';
import { thresholdForPredictAtom } from '@/uiStates/projectModels/pageUIStates';
import { isModelTrainingSuccessful } from '@/store/projectModelInfoState/utils';

import InfoIcon from '../icon/InfoIcon';
import useStyles from './styles';

export type ModelSettingsProps = {
  onClick?: () => void;
};

const ModelSettings: React.FC<ModelSettingsProps> = ({ onClick }) => {
  const styles = useStyles();
  const history = useHistory();
  const { labelType, id: projectId } = useGetSelectedProjectQuery().data ?? {};
  const {
    id: currentModelId,
    modelName: currentModelName,
    confidence,
    status: currentStatus,
  } = useCurrentProjectModelInfoQuery();
  const { data: modelStatusRes, isLoading: isModelStatusLoading } = useModelStatusQuery(
    projectId,
    currentModelId,
  );
  const { status: modelStatus, metricsReady } = modelStatusRes ?? {};
  const fastEasyModelSuccessful = isModelTrainingSuccessful(modelStatus, metricsReady);

  const [thresholdForPredict, setThresholdForPredict] = useAtom(thresholdForPredictAtom);
  // init thresholdForPredict with confidence
  useEffect(() => {
    if (thresholdForPredict === undefined && currentStatus === ModelStatus.Succeed && confidence) {
      setThresholdForPredict(confidence);
    }
  }, [confidence, currentStatus, setThresholdForPredict, thresholdForPredict]);

  const handlePreventClickDeeper = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const { models: projectModelsData, succeedModels } = useSucceedProjectModels();

  const { thresholdsMin, thresholdsMax, thresholdsStep } = CONFIDENCE_THRESHOLD_OPTIONS;

  const [selectedModelIdForProjects, setSelectedModelIdForProjects] = useLocalStorage<{
    [projectId: number]: string;
  }>(SelectedModelIdForProjectsStorageKey);

  const onModelSelect = useCallback(
    (newModelId: string, popupState) => {
      if (!projectModelsData || !currentModelId) return;
      const previousModelId = currentModelId;

      if (newModelId === previousModelId || !projectId) {
        return;
      }

      setSelectedModelIdForProjects({ ...selectedModelIdForProjects, [projectId]: newModelId });
      setThresholdForPredict(projectModelsData.find(model => model.id === newModelId)?.confidence!);
      popupState.close();
    },
    [
      projectModelsData,
      currentModelId,
      projectId,
      setThresholdForPredict,
      selectedModelIdForProjects,
      setSelectedModelIdForProjects,
    ],
  );

  const shouldShowConfidenceForProject = useMemo(() => {
    return [LabelType.BoundingBox, LabelType.Segmentation, LabelType.AnomalyDetection].includes(
      labelType!,
    );
  }, [labelType]);

  const handleModelNameClick = useCallback(
    e => {
      onClick?.();
      handlePreventClickDeeper(e);
    },
    [onClick],
  );

  const [showTooltip, setShowTooltip] = useLocalStorage(ShownModelSwitcherTipsKey);

  if (!currentModelId) {
    return (
      <Typography variant="body_medium" className={styles.labelTextDisabeld}>
        {t('No models available')}
      </Typography>
    );
  }

  return (
    <PopupState variant="popover" popupId="data-browser-model-settings">
      {popupState => (
        <div onClick={e => handleModelNameClick(e)}>
          <Tooltip
            open={!showTooltip}
            arrow
            interactive
            placement="bottom-start"
            classes={{
              popper: styles.tipPopper,
              arrow: styles.tipArrow,
              tooltip: styles.tooltip,
            }}
            title={
              <Box display="flex" alignItems="flex-end" flexDirection="column">
                <Typography>
                  {t(
                    'Select a model to see its predictions. This defaults to the model most recently used.',
                  )}
                </Typography>
                <Box onClick={() => setShowTooltip(true)} className={styles.gotIt} marginTop={3}>
                  {t('Got it')}
                </Box>
              </Box>
            }
          >
            <Box display="flex" alignItems="center" {...bindTrigger(popupState)}>
              <Typography
                variant="body_medium"
                className={
                  isModelStatusLoading && !fastEasyModelSuccessful
                    ? styles.labelTextDisabeld
                    : styles.labelText
                }
              >
                {currentModelName}
              </Typography>
              {shouldShowConfidenceForProject && (
                <>
                  <Box mr={0.5} />
                  <Typography
                    variant="body_medium"
                    className={
                      isModelStatusLoading && !fastEasyModelSuccessful
                        ? styles.labelTextDisabeld
                        : styles.caption
                    }
                  >
                    {` (${thresholdForPredict})`}
                  </Typography>
                </>
              )}
              {isModelStatusLoading && !fastEasyModelSuccessful ? (
                <CircularProgress color="inherit" size={16} className={styles.loadingIcon} />
              ) : (
                <KeyboardArrowDownIcon className={styles.arrowIcon} />
              )}
            </Box>
          </Tooltip>

          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            PaperProps={{ style: { marginTop: 10 } }}
            classes={{ paper: styles.popoverPaper }}
          >
            <Box className={styles.modelSettingBlock}>
              <Typography variant="body1">{t('Model')}</Typography>
              <Dropdown
                extraGutter={{ vertical: 8 }}
                classes={{
                  root: styles.dropdownRoot,
                  content: styles.dropdownContent,
                }}
                dropdown={(toggleDropdown: (open: boolean) => void) => (
                  <MenuList aria-label="model-selector-menu" className={styles.modelMenu}>
                    {succeedModels?.map(model => (
                      <MenuItem
                        key={model.id}
                        selected={model.id === currentModelId}
                        onClick={e => {
                          handlePreventClickDeeper(e);
                          toggleDropdown(false);
                          onModelSelect(model.id, popupState);
                        }}
                      >
                        <Typography maxWidth={340}>{model.modelName}</Typography>
                      </MenuItem>
                    ))}
                  </MenuList>
                )}
              >
                <Button
                  id={'model-selector-button'}
                  variant="outlined"
                  className={styles.modelSelectorButton}
                >
                  <Typography>{currentModelName}</Typography>
                  <KeyboardArrowDownIcon className={styles.arrowIcon} />
                </Button>
              </Dropdown>
              {shouldShowConfidenceForProject && confidence && (
                <>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box display="flex" alignItems="center">
                      <Typography variant="body1">{t('Confidence Threshold')}</Typography>
                      <Tooltip
                        arrow
                        placement="top"
                        title={t(
                          'Your model makes several predictions. You can adjust the Confidence Threshold to filter out the less confident predictions so you can focus on the most confident ones.',
                        )}
                      >
                        <IconButton id="confidence-threshold-info" size="small">
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Typography variant="body_small_bold">{thresholdForPredict}</Typography>
                  </Box>
                  <Slider
                    min={thresholdsMin}
                    max={thresholdsMax}
                    step={thresholdsStep}
                    value={thresholdForPredict}
                    marks={[
                      {
                        value: confidence,
                        label: (
                          <Tooltip arrow={true} title={t('Best')} placement="bottom">
                            <Box className={styles.markLabelTooltipLayer} />
                          </Tooltip>
                        ),
                      },
                    ]}
                    onChange={(_, newThreshold) => {
                      setThresholdForPredict(newThreshold as number);
                    }}
                    classes={{
                      root: styles.sliderRoot,
                      rail: styles.sliderRail,
                      track: styles.sliderTrack,
                      thumb: styles.sliderThumb,
                      active: styles.sliderActive,
                      mark: styles.sliderMark,
                      markActive: styles.sliderMark,
                      markLabel: styles.sliderMarkLabel,
                    }}
                  />
                </>
              )}
            </Box>
            <Typography variant="body1" className={styles.desc}>
              {t('To deploy this model, go to the {{modelsPage}}.', {
                modelsPage: (
                  <Typography
                    variant="body_bold"
                    className={styles.modelsPageText}
                    onClick={() => history.push(CLEF_PATH.modelsV2.list)}
                  >
                    {t('Models page')}
                  </Typography>
                ),
              })}
            </Typography>
          </Popover>
        </div>
      )}
    </PopupState>
  );
};

export default ModelSettings;
