import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { Typography } from '@clef/client-library';
import { isNumber } from 'lodash';
import { Skeleton } from '@material-ui/lab';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { LabelType } from '@clef/shared/types';

const useStyles = makeStyles(theme => ({
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(3),
  },
  score: {
    color: theme.palette.greyModern[900],
  },
  label: {
    fontWeight: 600,
    color: theme.palette.greyModern[600],
  },
}));

export type ModelPerformanceProps = {
  performance?: number | null;
  precision?: number | null;
  recall?: number | null;
};

const Score: React.FC<{ score?: number | null }> = ({ score }) => {
  const styles = useStyles();
  return isNumber(score) ? (
    <Typography variant="h4" className={styles.score}>
      {score === -1 ? t('N/A') : t('{{score}}%', { score: (score * 100).toFixed(1) })}
    </Typography>
  ) : (
    <Skeleton variant="text" />
  );
};

const ModelPerformance: React.FC<ModelPerformanceProps> = props => {
  const styles = useStyles();
  const { performance, precision, recall } = props;

  const { data: project } = useGetSelectedProjectQuery();
  const { labelType } = project ?? {};

  return (
    <Box display="flex" flexDirection="column" marginTop={5}>
      <Box className={styles.labelContainer}>
        <Score score={performance} />
        <Typography variant="body2" className={styles.label}>
          {labelType === LabelType.Segmentation ? t('Iou') : t('F1')}
        </Typography>
      </Box>
      <Box className={styles.labelContainer}>
        <Score score={precision} />
        <Typography variant="body2" className={styles.label}>
          {t('Precision')}
        </Typography>
      </Box>
      <Box className={styles.labelContainer}>
        <Score score={recall} />
        <Typography variant="body2" className={styles.label}>
          {t('Recall')}
        </Typography>
      </Box>
    </Box>
  );
};

export default ModelPerformance;
