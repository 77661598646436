import cx from 'classnames';
import React, { useMemo } from 'react';

import CLIBAPI from '@/api/clib_api';
import { useAllModels } from '@/hooks/useModels';
import { getModelName } from '@/utils/models';

import { useModelInferenceCost } from '@/hooks/api/useExperimentReportApi';
import { ClientFeatures, useFeatureGateEnabled } from '@/hooks/useFeatureGate';
import ContentCopySvg from '@/images/deploy/content_copy.svg';
import RocketIcon from '@/images/deploy/download_list_empty_state_rocket.svg';
import { getBrowserTimezone, getDateNumber } from '@/utils';
import { SkipDialogKey, addSkipDialogWithId } from '@/utils/train';
import { ApiResponseLoader, IconButton, Typography } from '@clef/client-library';
import { ProjectId } from '@clef/shared/types';
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/GetAppOutlined';
import InfoIcon from '@material-ui/icons/Info';
import { formatDistance } from 'date-fns';
import { format } from 'date-fns-tz';
import { useSnackbar } from 'notistack';
import { ModelBundle } from '../utils';
import { useGetJobDetailListQuery } from '@/serverStore/endpoints';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
  emptyStateContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    gap: theme.spacing(5),
    alignItems: 'center',
    marginTop: theme.spacing(15),
  },
  title: {
    color: theme.palette.grey[900],
    fontWeight: 500,
  },
  description: {
    color: theme.palette.grey[600],
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  tableContainer: {
    border: '1px solid',
    borderColor: theme.palette.grey[300],
    borderRadius: '6px',
  },
  tableHeader: {
    background: theme.palette.grey[25],
    '& .MuiTableCell-root': {
      padding: '24px 16px',
    },
  },
  downloadIcon: {
    color: theme.palette.grey[500],
    fontSize: '24px',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.grey[50],
    },
    '& .MuiTableCell-root': {
      padding: '10px 16px',
    },
  },
  popper: {
    pointerEvents: 'auto',
  },
  arrow: {
    color: theme.palette.blue[700],
  },
  tooltip: {
    backgroundColor: theme.palette.blue[700],
  },
  gotIt: {
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 700,
  },
  infoIcon: {
    color: theme.palette.blue[200],
    width: '20px',
    height: '20px',
    marginLeft: theme.spacing(1),
  },
  titleWithInfoIcon: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 700,
    color: theme.palette.grey[900],
  },
  subTitle: {
    color: theme.palette.grey[600],
  },
}));

const EmptyState = () => {
  const styles = useStyles();
  return (
    <Box className={styles.emptyStateContainer}>
      <img src={RocketIcon} width={160} height={160} />
      <Typography variant="h4" className={styles.title}>
        {t('No downloadable item here')}
      </Typography>
      <Box>
        <Typography className={cx(styles.textAlignCenter, styles.description)}>
          {t('If you generated a downloadable model version from the build section, ')}
        </Typography>
        <Typography className={cx(styles.textAlignCenter, styles.description)}>
          {t('you will be able to see it and download it for edge deployment here.')}
        </Typography>
      </Box>
    </Box>
  );
};

const PredictionCostTableCell: React.FC<{ jobId: string; projectId: ProjectId }> = ({
  jobId,
  projectId,
}) => {
  const [inferenceCost, inferenceCostLoading] = useModelInferenceCost({
    jobId,
    projectId,
  });
  const credit = inferenceCost?.data.inferenceCredits ?? 1;
  if (inferenceCostLoading) {
    return (
      <TableCell>
        <CircularProgress size={20} />
      </TableCell>
    );
  }
  return <TableCell align="left">{t(`${credit} ${credit > 1 ? 'credits' : 'credit'}`)}</TableCell>;
};

interface Props {}

export const DownloadList: React.FC<Props> = () => {
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { id: projectId, orgId } = useGetSelectedProjectQuery().data ?? {};
  const { findModels } = useAllModels();
  const dateNow = useMemo(() => new Date(), []);
  const {
    data: bundles,
    isLoading: bundlesLoading,
    error: bundlesError,
  } = useGetJobDetailListQuery(projectId ?? 0, orgId ?? 0);

  const downloadModelBundle = async (model: ModelBundle) => {
    const { bundle_zip_url } = await CLIBAPI.downloadBundle(model.bundleId);
    window.open(bundle_zip_url);
  };

  const shouldAllowDownloadingBundle = useFeatureGateEnabled(
    ClientFeatures.ShouldAllowDownloadingBundle,
  );

  const shouldUseAdvancedPricing = useFeatureGateEnabled(ClientFeatures.AdvancedUsageBasedPricing);

  const isCreditReferenceEnabled = !useFeatureGateEnabled(ClientFeatures.DisableCreditReference);

  if (bundles.length === 0 || !projectId) {
    return <EmptyState />;
  }

  return (
    <ApiResponseLoader response={bundles} loading={bundlesLoading} error={bundlesError}>
      {bundles => (
        <Box className={styles.container}>
          <Typography variant="h2" className={styles.title}>
            {t('Deployable Models')}
          </Typography>
          <Typography className={styles.description}>
            {t('Below are a list of models that have been generated for deployment.')}
          </Typography>
          <TableContainer className={styles.tableContainer}>
            <Table aria-label="download-list-table">
              <TableHead className={styles.tableHeader}>
                <TableRow>
                  <TableCell align="left">{t('Models')}</TableCell>
                  <TableCell align="left">{t('Trained at')}</TableCell>
                  {isCreditReferenceEnabled && shouldUseAdvancedPricing && (
                    <TableCell align="left">
                      <Box display="flex" flexDirection="column" textAlign="left">
                        <Typography className={styles.titleWithInfoIcon}>
                          {t('Prediction Cost')}
                          <Tooltip
                            placement="top"
                            title={t(
                              'Prediction cost is calculated based on training settings (image resize, model size) and project type. ',
                            )}
                            arrow
                          >
                            <InfoIcon className={styles.infoIcon} />
                          </Tooltip>
                        </Typography>
                        <Typography className={styles.subTitle}>{t('(per image)')}</Typography>
                      </Box>
                    </TableCell>
                  )}
                  <TableCell align="left">{t('Confidence Threshold')}</TableCell>
                  <TableCell align="left">{t('Generated Time')}</TableCell>
                  {shouldAllowDownloadingBundle && (
                    <TableCell align="left">{t('Download Model')}</TableCell>
                  )}
                  <TableCell align="left">{t('Model ID')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bundles
                  .sort((a, b) => {
                    if (!b.bundleGeneratedTime || !a.bundleGeneratedTime) {
                      return getDateNumber(b.createdAt) - getDateNumber(a.createdAt);
                    }
                    return (
                      getDateNumber(b.bundleGeneratedTime) - getDateNumber(a.bundleGeneratedTime)
                    );
                  })
                  .map(model => {
                    const modelInfo = findModels(model.jobId);
                    return (
                      <TableRow key={model.bundleId} className={styles.tableRow} hover>
                        <TableCell align="left">
                          <Typography maxWidth={200}>{getModelName(modelInfo)}</Typography>
                        </TableCell>
                        <TableCell align="left">
                          {model.createdAt &&
                            t('{{dateDistance}} ago', {
                              dateDistance: formatDistance(getDateNumber(model.createdAt), dateNow),
                            })}
                        </TableCell>
                        {shouldUseAdvancedPricing && (
                          <PredictionCostTableCell jobId={model.jobId} projectId={projectId} />
                        )}
                        <TableCell align="left">{model.threshold}</TableCell>
                        <TableCell align="left">
                          {model.bundleGeneratedTime &&
                            format(
                              getDateNumber(model.bundleGeneratedTime),
                              'MMMM d, yyyy hh:mm aa z',
                              { timeZone: getBrowserTimezone() },
                            )}
                        </TableCell>

                        {shouldAllowDownloadingBundle && (
                          <TableCell align="left">
                            <IconButton
                              id={'download-bundle-' + model.bundleId}
                              arial-label="download-bundle"
                              size="small"
                              onClick={() => {
                                addSkipDialogWithId(SkipDialogKey.DownloadBundle, projectId);
                                downloadModelBundle(model);
                              }}
                            >
                              <DownloadIcon className={styles.downloadIcon} />
                            </IconButton>
                          </TableCell>
                        )}

                        <TableCell align="left">
                          <IconButton
                            tooltip={t('Copy Model Id')}
                            onClick={() => {
                              navigator.clipboard.writeText(model.bundleId);
                              enqueueSnackbar('The Model Id has been copied to your clipboard.', {
                                variant: 'success',
                              });
                            }}
                          >
                            <img src={ContentCopySvg} width={18} height={18} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </ApiResponseLoader>
  );
};
