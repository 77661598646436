import {
  AnnotationChangeType,
  Button,
  CanvasAnnotation,
  CanvasInteractionEvent,
  CanvasMode,
  getThumbnail,
  MediaInteractiveCanvas,
  MediaInteractiveCanvasProps,
  useKeyPress,
  useKeyPressHold,
  Typography,
  AnnotationSourceType,
} from '@clef/client-library';
import {
  Defect,
  LabelingType,
  LabelType,
  MediaDetails,
  MediaDetailsForLabelingReview,
  MediaDetailsWithPrediction,
  MediaLevelLabel,
} from '@clef/shared/types';
import React, { MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import { useLabelingStyles } from './labelingStyles';
import {
  canvasToCanvasAnnotation,
  convertToCanvasAnnotations,
  getCanvasMode,
  offscreenCanvasToCanvasAnnotation,
  useHandleCanvasInteractiveEvent,
} from './utils';
import { getDefectColor } from '../../utils';
import SegmentationToolModeCard from './SegmentationToolModeCard';
import SegmentationToolModeOptionsCard from './SegmentationToolModeOptionsCard';
import ToolDefectCard from './ToolDefectCard';
import ToolImageEnhance from './ToolImageEnhance';
import EnhancedImageViewer from '../ImageEnhancer/EnhancedImageViewer';
import {
  BitMapLabelingAnnotation,
  BoxLabelingAnnotation,
  PureCanvasLabelingAnnotation,
  ToolMode,
  useLabelingState,
} from './labelingState';
import { useDialog } from '../Layout/components/useDialog';
import { useCurrentProjectModelInfoQuery } from '@/serverStore/projectModels';
import { ColorMap, useHeatmapCanvas } from '../../pages/error_analysis/components/utils';
import useGetDefectColorById from '../../hooks/defect/useGetDefectColorById';
import useGetDefectNameById from '../../hooks/defect/useGetDefectNameById';
import { Box, Tooltip } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';
import MediaClassifiedClassWithClassification from '../../pages/DataBrowser/MediaContainer/MediaClassifiedClassWithClassification';
import { useAvailableDefectColor } from '../../hooks/useAvailableDefectColor';
import { CreateClassesDialog } from './CreateClassesDialog';
import RunningMask from '@/pages/InstantLearningLabeling/RunningMask';
import { IconQuickLabeling, UncheckCircleIcon } from './ToolIcon';
import SAMLabelingConfirmationHint from './components/SAMLabelingConfirmationHint';
import { useSnackbar } from 'notistack';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import SAMConfirmationDialog from './components/SAMConfirmationDialog';
import { useSAMOnnxModel, useImageEmbedding } from '@/hooks/api/useExperimentReportApi';
import { throttle } from 'lodash';

interface MediaCanvasWrapperProps {
  isLabelMode: boolean;
  mediaDetails?: MediaDetails | MediaDetailsWithPrediction | MediaDetailsForLabelingReview;
  mediaCanvasRef: MutableRefObject<MediaInteractiveCanvas | null>;
  annotations: BoxLabelingAnnotation[] | BitMapLabelingAnnotation[];
  predictionAnnotations?: BoxLabelingAnnotation[] | PureCanvasLabelingAnnotation[] | null;
  mediaLevelLabel?: MediaLevelLabel | null;
  predictionMediaLevelLabel?: MediaLevelLabel | null;
  hideGroundTruthLabels?: boolean;
  hidePredictionLabels?: boolean;
  showHeatmap?: boolean;
  updateMediaLevelLabel?: (mediaLevelLabel: MediaLevelLabel | undefined) => void;
  onAnnotationChanged?: MediaInteractiveCanvasProps['onAnnotationChanged'];
  onDefectCreate?: (defectName: string, defectColor: string) => Promise<Defect | null>;
  showClassChip?: boolean;
  isLabelingTask?: boolean;
  isLabelingReview?: boolean;
  className?: string;
  hideClassInfo?: boolean;
  onClickClassInfo?: (defectId: number) => void;
  toolsControlFromOutside?: boolean; // tools control from outside toolbar
}

export const MediaCanvasWrapper: React.FC<MediaCanvasWrapperProps> = ({
  isLabelMode,
  mediaDetails,
  mediaCanvasRef,
  annotations,
  predictionAnnotations = [],
  mediaLevelLabel,
  predictionMediaLevelLabel,
  hideGroundTruthLabels,
  hidePredictionLabels,
  showHeatmap = false,
  updateMediaLevelLabel,
  onAnnotationChanged,
  onDefectCreate,
  showClassChip = true,
  isLabelingTask = false,
  isLabelingReview = false,
  className,
  hideClassInfo = undefined,
  onClickClassInfo,
  toolsControlFromOutside = false,
}) => {
  const styles = useLabelingStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [renderUrl, setRenderUrl] = useState<string>();
  const { availableColor, refreshAvailableColor } = useAvailableDefectColor();
  const [createClassDialogOpen, setCreateClassDialogOpen] = useState(false);
  const { labelType } = useGetSelectedProjectQuery().data ?? {};

  // Preload with large thumbnail first, replaced with original image 3s later
  useEffect(() => {
    if (mediaDetails) {
      const largeThumbnail = getThumbnail(mediaDetails, 'large');
      setRenderUrl(largeThumbnail);
      const timer = setTimeout(() => {
        setRenderUrl(mediaDetails.url);
      }, 3 * 1000);
      return () => clearTimeout(timer);
    }
    return () => {};
  }, [mediaDetails]);

  const getDefectColorById = useGetDefectColorById();
  const getDefectNameById = useGetDefectNameById();

  const {
    state: {
      labelingType,
      selectedDefect,
      toolMode,
      hideLabels,
      toolOptions: { erasing, strokeWidth, eraserWidth },
      haveUnsavedLabel,
      showUnsavedLabelConfirmationDialog,
    },
    dispatch,
  } = useLabelingState();

  const groundTruthCanvasAnnotations = convertToCanvasAnnotations(
    labelingType,
    annotations,
    getDefectColorById,
    AnnotationSourceType.GroundTruth,
    getDefectNameById,
  );

  let predictionCanvasAnnotations: CanvasAnnotation[] = [];

  if (!isLabelMode && predictionAnnotations && predictionAnnotations.length) {
    predictionCanvasAnnotations =
      labelingType === LabelingType.DefectSegmentation
        ? predictionAnnotations.map(ann => ({
            ...offscreenCanvasToCanvasAnnotation(ann as PureCanvasLabelingAnnotation),
            defectId: ann.defectId,
          }))
        : convertToCanvasAnnotations(
            labelingType,
            predictionAnnotations as BoxLabelingAnnotation[],
            getDefectColorById,
            AnnotationSourceType.Prediction,
            getDefectNameById,
          );
  }

  let canvasAnnotations: CanvasAnnotation[] = [];
  if (isLabelMode) {
    canvasAnnotations = groundTruthCanvasAnnotations;
  } else {
    if (!hideGroundTruthLabels) {
      canvasAnnotations = groundTruthCanvasAnnotations;
    }
    if (!hidePredictionLabels) {
      canvasAnnotations = [
        ...canvasAnnotations,
        ...predictionCanvasAnnotations.map(item => ({
          ...item,
          group: AnnotationSourceType.Prediction,
        })),
      ];
    }
  }

  const confidenceThreshold = useCurrentProjectModelInfoQuery().confidence ?? 0;
  const heatmapCanvas = useHeatmapCanvas({
    imageSrc: mediaDetails?.url || '',
    attentionHeatmapSrc:
      (mediaDetails as MediaDetailsWithPrediction)?.predictionLabel?.heatmapPath || '',
    colorMap: ColorMap.inferno,
    confidenceThreshold:
      labelingType === LabelingType.DefectClassification ? 0 : confidenceThreshold,
  });
  if (showHeatmap && heatmapCanvas) {
    const heatmapCanvasAnnotation = canvasToCanvasAnnotation('heatmap_id', heatmapCanvas);
    canvasAnnotations.push({ ...heatmapCanvasAnnotation, group: AnnotationSourceType.Heatmap });
  }

  const canvasShapeProps = useMemo(
    () => ({
      color: erasing ? 'transparent' : selectedDefect ? getDefectColor(selectedDefect) : undefined,
      segmentationOpacity: 0.5,
      lineStrokeWidth: erasing ? eraserWidth : strokeWidth,
    }),
    [eraserWidth, erasing, selectedDefect, strokeWidth],
  );

  const { modeCardWarning, defectCardWarning, onCanvasInteractiveEvent } =
    useHandleCanvasInteractiveEvent();

  const onFitZoomScaleChange = useCallback(
    (zoomScale: number) => {
      dispatch(draft => {
        draft.toolOptions.fitZoomScale = zoomScale;
      });
    },
    [dispatch],
  );
  const enableToolCards = [
    LabelingType.DefectBoundingBox,
    LabelingType.DefectSegmentation,
  ].includes(labelingType);

  const { showConfirmationDialog } = useDialog();

  useEffect(() => {
    if (isLabelMode && labelType === LabelType.BoundingBox) {
      dispatch(draft => {
        draft.toolMode = ToolMode.Box;
      });
    }
  }, [dispatch, isLabelMode, labelType]);

  const canvasMode = useMemo(
    () => (isLabelingReview || !isLabelMode ? CanvasMode.View : getCanvasMode(toolMode)),
    [isLabelMode, isLabelingReview, toolMode],
  );

  const isShowNothingToLabelButton = useMemo(() => {
    return (
      annotations.length === 0 &&
      mediaLevelLabel !== MediaLevelLabel.OK &&
      !isLabelingReview &&
      (labelingType === LabelingType.DefectBoundingBox ||
        labelingType === LabelingType.DefectSegmentation) &&
      // SAM labeling canvas mode with unsaved label should not show the nothing-to-label button
      (canvasMode !== CanvasMode.IQuickLabeling || !haveUnsavedLabel)
    );
  }, [
    annotations.length,
    canvasMode,
    haveUnsavedLabel,
    isLabelingReview,
    labelingType,
    mediaLevelLabel,
  ]);

  const enableDefectCreate =
    onDefectCreate &&
    !selectedDefect &&
    // As of 06/05/2023, the quick class creation flow is supported only for object detection and quick labeling mode
    // in segmentation
    ((labelingType === LabelingType.DefectSegmentation &&
      canvasMode === CanvasMode.IQuickLabeling) ||
      labelingType === LabelingType.DefectBoundingBox);
  const enableSAM =
    canvasMode === CanvasMode.IQuickLabeling && labelingType === LabelingType.DefectSegmentation;

  const { id: projectId } = useGetSelectedProjectQuery().data ?? {};
  const [onnxModel, onnxModelLoading, onnxModelError] = useSAMOnnxModel(enableSAM ? {} : undefined);
  const [imageEmbedding, imageEmbeddingLoading, imageEmbeddingError] = useImageEmbedding(
    enableSAM && mediaDetails?.path && projectId
      ? {
          projectId,
          mediaPath: mediaDetails?.path,
        }
      : undefined,
  );

  // If no defect creator, pressing escape will trigger this to reset the tool mode
  useKeyPress('*', e => {
    if (e.key === 'Escape' && labelType !== LabelType.BoundingBox) {
      dispatch(draft => {
        draft.toolMode = undefined;
      });
    }
  });

  const handleSAMLabelingError = useCallback(
    (error: any) => {
      enqueueSnackbar(
        error.message !== 'Something went wrong with your request.'
          ? error.message
          : t(
              'Smart Labeling is currently unavailable due to high demand. Please try again later.',
            ),
        {
          variant: 'error',
          autoHideDuration: 12000,
          preventDuplicate: true,
        },
      );

      // If failed to enable smart labeling, reset the tool mode
      dispatch(draft => {
        if (draft.toolMode === ToolMode.Quick) {
          draft.toolMode = undefined;
          draft.haveUnsavedLabel = false;
        }
      });
    },
    [dispatch, enqueueSnackbar],
  );

  useEffect(() => {
    if (onnxModelError || imageEmbeddingError) {
      handleSAMLabelingError(onnxModelError || imageEmbeddingError);
    }
  }, [handleSAMLabelingError, imageEmbeddingError, onnxModelError]);

  const [markedAsNoClass, setMarkedAsNoClass] = useState(mediaLevelLabel === MediaLevelLabel.OK);

  useKeyPress('n', () => {
    if (annotations.length === 0 && !markedAsNoClass) {
      setMarkedAsNoClass(true);
      updateMediaLevelLabel?.(MediaLevelLabel.OK);
    }
  });

  const handleMediaLevelLabelChange = throttle(() => {
    if (!markedAsNoClass) {
      setMarkedAsNoClass(true);
      updateMediaLevelLabel?.(MediaLevelLabel.OK);
    } else {
      setMarkedAsNoClass(false);
      updateMediaLevelLabel?.(undefined);

      enqueueSnackbar(t('Cancel mark as no Class'), {
        variant: 'success',
        autoHideDuration: 3000,
      });
    }
  }, 3000);

  const confirmMarkAsNoClass = useCallback(() => {
    showConfirmationDialog({
      title: t('Mark as No Class will Remove All Labels'),
      content: t(
        'Are you sure you want to remove all labels on this image? This cannot be undone.',
      ),
      confirmText: t('Remove All Labels and Mark as No Class'),
      color: 'secondary',
      onConfirm: () => {
        setMarkedAsNoClass(true);
        updateMediaLevelLabel?.(MediaLevelLabel.OK);
      },
    });
  }, [showConfirmationDialog, updateMediaLevelLabel]);

  const handleAnnotationChange: MediaInteractiveCanvasProps['onAnnotationChanged'] = (...props) => {
    // if marked as no class, and label again, cancel marked as no class
    if (markedAsNoClass && props[0].length === 1) {
      setMarkedAsNoClass(false);
      updateMediaLevelLabel?.(undefined);

      enqueueSnackbar(t('Cancel mark as no Class'), {
        variant: 'success',
        autoHideDuration: 3000,
      });
    }

    onAnnotationChanged?.(...props);
  };

  const hideKeyPressed = useKeyPressHold('h');

  useEffect(() => {
    dispatch(draft => {
      draft.hideLabels = !!hideKeyPressed;
    });
  }, [dispatch, hideKeyPressed]);

  return mediaDetails ? (
    <div className={cx(styles.mediaCanvasWrapper, className)}>
      {enableSAM && !onnxModelError && !imageEmbeddingError && (
        <RunningMask
          show={onnxModelLoading || imageEmbeddingLoading}
          blur={onnxModelLoading || imageEmbeddingLoading}
          texts={[t('Initiating Smart Labeling...')]}
          icon={<IconQuickLabeling color="white" width="100%" height="100%" />}
        />
      )}

      {/* Draw defect toolkit */}
      <div
        className={cx(
          styles.toolCards,
          haveUnsavedLabel && enableSAM && styles.toolCardsWithSamEnabled,
        )}
        data-testid="tool-cards"
      >
        {isLabelMode && labelingType === LabelingType.DefectSegmentation && (
          <>
            {!toolsControlFromOutside && (
              <SegmentationToolModeCard warning={modeCardWarning} isLabelingTask={isLabelingTask} />
            )}
            {!toolsControlFromOutside && (
              <SegmentationToolModeOptionsCard key={erasing ? 'erasing' : 'drawing'} />
            )}
          </>
        )}
        {isLabelMode && enableToolCards && (
          <ToolDefectCard warning={defectCardWarning} disabled={haveUnsavedLabel && enableSAM} />
        )}
        {toolsControlFromOutside && enableToolCards && (
          <Box className={styles.mediaLevelLabelCard}>
            {isLabelMode && (
              <Tooltip
                placement="right"
                title={t(
                  'If there are no objects of interest to label in this image, mark it as No Class.',
                )}
              >
                <Box
                  className={cx(
                    styles.markAsNoClassWrapper,
                    markedAsNoClass && styles.wrapperChecked,
                  )}
                  onClick={event => {
                    event.stopPropagation();
                    event.nativeEvent.stopImmediatePropagation();
                    if (!markedAsNoClass && annotations.length > 0) {
                      confirmMarkAsNoClass();
                    } else {
                      handleMediaLevelLabelChange();
                    }
                  }}
                >
                  <Typography className={styles.markAsNoClassText}>{t('No Class')}</Typography>
                  {markedAsNoClass ? (
                    <CheckCircleIcon className={styles.markAsNoClassIcon} />
                  ) : (
                    <UncheckCircleIcon />
                  )}
                </Box>
              </Tooltip>
            )}
            {!isLabelMode && (
              <>
                {markedAsNoClass && (
                  <Tooltip
                    placement="right"
                    title={t(
                      `This image has been marked as 'No Class.' If there are target Classes on this image, please use the labeling tools to mark them.`,
                    )}
                  >
                    <Box className={styles.noClassWrapperPanMode}>
                      <RemoveCircleOutline fontSize="small" htmlColor="#4D5761" />
                      <Typography className={styles.markAsNoClassText}>
                        {t('Marked as No Class')}
                      </Typography>
                    </Box>
                  </Tooltip>
                )}
                {predictionMediaLevelLabel === MediaLevelLabel.OK && (
                  <Box
                    className={cx(styles.noClassWrapperPanMode, styles.predictedAsNoClassBorder)}
                  >
                    <RemoveCircleOutline fontSize="small" htmlColor="#4D5761" />
                    <Typography className={styles.markAsNoClassText}>
                      {t('Predicted As No Class')}
                    </Typography>
                  </Box>
                )}
              </>
            )}
          </Box>
        )}
      </div>

      {/* Zoom and image enhancement */}
      {!toolsControlFromOutside && (
        <div
          className={cx(
            styles.toolImageEnhance,
            haveUnsavedLabel && enableSAM && styles.toolImageEnhanceWithSamEnabled,
            'show-on-hover',
          )}
        >
          <ToolImageEnhance
            isLabelingReview={isLabelingReview}
            setZoomScale={newZoomScale => mediaCanvasRef.current?.setZoomScale(newZoomScale)}
          />
        </div>
      )}

      {/* Media level label actions */}
      {isLabelMode &&
        updateMediaLevelLabel &&
        !toolsControlFromOutside &&
        (labelingType === LabelingType.DefectBoundingBox ||
          labelingType === LabelingType.DefectSegmentation) && (
          <div className={styles.mediaLevelLabelActions}>
            {annotations.length > 0 &&
              // SAM labeling canvas mode with unsaved label should not show the nothing-to-label button
              (canvasMode !== CanvasMode.IQuickLabeling || !haveUnsavedLabel) && (
                <Button
                  id="remove-all-label-button"
                  variant="text"
                  color="secondary"
                  onClick={() => {
                    showConfirmationDialog({
                      title: t('Are you sure you want to delete all labels?'),
                      content: t(
                        // eslint-disable-next-line max-len
                        'Are you sure you want to delete all labels on this media? This operations cannot be undone',
                      ),
                      confirmText: t('Remove all labels'),
                      color: 'secondary',
                      onConfirm: () => {
                        mediaCanvasRef.current?.setAnnotations([], AnnotationChangeType.DeleteAll);
                      },
                    });
                  }}
                >
                  {t('Clear All Labels')}
                </Button>
              )}
            {isShowNothingToLabelButton && (
              <Button
                variant="text"
                color="primary"
                onClick={() => {
                  updateMediaLevelLabel(MediaLevelLabel.OK);
                }}
                tooltip={t('Click if there are no classes to identify in this image.')}
                id="task-nothing-to-label"
              >
                {t('No Class to Label')}
              </Button>
            )}
          </div>
        )}
      <EnhancedImageViewer sourceUrl={renderUrl!}>
        {enhancedInfo => (
          <MediaInteractiveCanvas
            ref={mediaCanvasRef}
            key={mediaDetails?.id}
            imageSrc={enhancedInfo.sourceUrl}
            properties={mediaDetails?.properties}
            annotations={canvasAnnotations}
            showGroundTruthLabels={!hideLabels}
            hidePredictions={hideLabels}
            mode={canvasMode}
            enablePinchScrollZoom
            freeDrag
            shapeProps={canvasShapeProps}
            onInteractionEvent={type => {
              if (type === CanvasInteractionEvent.DrawingStarted) {
                dispatch(draft => {
                  draft.haveUnsavedLabel = true;
                });
              } else if (type === CanvasInteractionEvent.DrawingEnded) {
                dispatch(draft => {
                  draft.haveUnsavedLabel = false;
                });
              }

              onCanvasInteractiveEvent(type);
            }}
            onZoomScaleChange={newZoomScale => {
              dispatch(draft => {
                draft.toolOptions.zoomScale = newZoomScale;
              });
            }}
            onFitZoomScaleChange={onFitZoomScaleChange}
            enableFitPadding={isLabelMode}
            onAnnotationChanged={handleAnnotationChange}
            onDefectCreatorModeChange={mode => {
              dispatch(state => {
                state.isCreatingDefect = mode === 'create-defect';
              });
            }}
            pendingDefectColor={enableDefectCreate ? availableColor : undefined}
            onDefectCreate={async (defectName, defectColor) => {
              const newDefect = await onDefectCreate?.(defectName, defectColor);

              if (newDefect?.color) {
                refreshAvailableColor();
                mediaCanvasRef.current?.pressEnter(newDefect.color);
              }
            }}
            onDisabledDrawingMouseDown={() => {
              enableDefectCreate && setCreateClassDialogOpen(true);
            }}
            enablePixelation
            defectCreatorTips={
              enableDefectCreate && labelType === LabelType.Segmentation
                ? t('Click on the object you want to label')
                : undefined
            }
            onCreatorError={enableSAM ? handleSAMLabelingError : undefined}
            onnxModel={onnxModel}
            imageEmbedding={imageEmbedding}
            // As of 23/06/29, we will not enable the click away event of the defect creator for the quick class
            // creation flow in OD, as user might need to select the bbox to remove
            enableDefectCreatorClickAwayEvent={enableSAM}
          />
        )}
      </EnhancedImageViewer>

      {haveUnsavedLabel && enableSAM && (
        <SAMLabelingConfirmationHint
          onSaveButtonClick={() => mediaCanvasRef.current?.pressEnter()}
        />
      )}

      {/* Class chip for classification project */}
      {/* eslint-disable-next-line max-len */}
      {labelingType === LabelingType.DefectClassification && showClassChip && !isLabelingReview && (
        <div className={styles.chilpWrapper} data-testid="label-preview-list">
          {!hideGroundTruthLabels && (
            <MediaClassifiedClassWithClassification
              threshold={confidenceThreshold}
              media={mediaDetails}
              mediaDetails={mediaDetails as MediaDetailsWithPrediction}
              isPrediction={false}
              enableSetClass
              enableColor={false}
              showGroundTruth={true}
              style={{ height: 50, borderRadius: 46, padding: '0 24px' }}
              hideClassInfo={hideClassInfo}
              onClickClassInfo={onClickClassInfo}
            />
          )}
          <Box style={{ height: 15 }}></Box>
          {!hidePredictionLabels && (
            <MediaClassifiedClassWithClassification
              threshold={confidenceThreshold}
              media={mediaDetails}
              mediaDetails={mediaDetails as MediaDetailsWithPrediction}
              isPrediction={true}
              enableSetClass={false}
              enableColor={false}
              showGroundTruth={false}
              style={{ height: 50, borderRadius: 46, padding: '0 24px' }}
            />
          )}
        </div>
      )}

      {createClassDialogOpen && (
        <CreateClassesDialog
          onClose={() => setCreateClassDialogOpen(false)}
          onClassCreateOverwrite={async (defectName, defectColor) => {
            const newDefect = await onDefectCreate?.(defectName, defectColor);

            if (newDefect?.color) {
              refreshAvailableColor();
              mediaCanvasRef.current?.pressEnter(newDefect.color);
            }

            setCreateClassDialogOpen(false);
          }}
          onClassCreateDiscard={() => {
            // TODO: Discard WIP annotations in quick class creation flow for other project types
            if (enableSAM) {
              // Revert defect creator back to the initial state
              if (mediaCanvasRef.current?.defectCreator?.mode === 'create-defect') {
                mediaCanvasRef.current.defectCreator.setMode('label-without-defect');
              }

              mediaCanvasRef.current?.clearWipAnnotations?.();
            }
          }}
        />
      )}

      <SAMConfirmationDialog
        open={showUnsavedLabelConfirmationDialog}
        inClassCreationMode={mediaCanvasRef.current?.defectCreator?.mode === 'create-defect'}
        onClose={() =>
          dispatch(draft => {
            draft.showUnsavedLabelConfirmationDialog = false;
          })
        }
        onSave={() => {
          mediaCanvasRef.current?.pressEnter();
          dispatch(draft => {
            draft.showUnsavedLabelConfirmationDialog = false;
          });
        }}
      />
    </div>
  ) : null;
};

export default MediaCanvasWrapper;
