import React, { MutableRefObject, useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Box, Divider, Tooltip, CircularProgress } from '@material-ui/core';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { githubGist } from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import { useAtom } from 'jotai';
import classNames from 'classnames';

import { MediaStatusType, LabelType, MediaLevelLabel } from '@clef/shared/types';
import {
  MediaInteractiveCanvas,
  Typography,
  Button,
  AnnotationSourceType,
  ToggleButton,
} from '@clef/client-library';

import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import {
  BitMapLabelingAnnotation,
  BoxLabelingAnnotation,
  ClassificationLabelingAnnotation,
  useLabelingState,
} from '@/components/Labeling/labelingState';
import LabelPreviewList from '@/components/Labeling/LabelPreviewList';
import {
  isLabelModeAtom,
  useCurrentMediaStates,
  toggleOnModelAssistLabelingAtom,
} from '@/uiStates/mediaDetails/pageUIStates';
import { showGroundTruthAtom, showPredictionAtom } from '@/uiStates/mediaDetails/labelToggles';
import { isModelTrainingSuccessful } from '@/store/projectModelInfoState/utils';
import { useGetProjectModelListQuery } from '@/serverStore/projectModels';
import { ClientFeatures, useFeatureGateEnabled } from '@/hooks/useFeatureGate';
import { useModelInferenceCost } from '@/hooks/api/useExperimentReportApi';
import { useFetchModelAssistSuggestionsQuery } from '@/serverStore/label';

import ModelAssistLabeling, { ModelAssistLabelingRef } from './ModelAssistLabeling';
import useStyles from './styles';

type LabelListWrapperProps = {
  mediaCanvasRef: MutableRefObject<MediaInteractiveCanvas | null>;
};

const LabelListWrapper: React.FC<LabelListWrapperProps> = ({ mediaCanvasRef }) => {
  const styles = useStyles();
  const history = useHistory();
  const isCreditReferenceEnabled = !useFeatureGateEnabled(ClientFeatures.DisableCreditReference);

  const { labelType, id: projectId } = useGetSelectedProjectQuery().data ?? {};
  const modelId = new URLSearchParams(history?.location?.search).get('modelId');
  const { data: projectModelsData } = useGetProjectModelListQuery();
  const currentModelInfo = projectModelsData?.find(item => item.id === modelId);
  const modelTrainedSuccessful =
    currentModelInfo &&
    isModelTrainingSuccessful(currentModelInfo.status, currentModelInfo.metricsReady);

  const {
    state: { labelingType },
  } = useLabelingState();

  const [isLabelMode] = useAtom(isLabelModeAtom);
  const [showGroundTruth, setShowGroundTruth] = useAtom(showGroundTruthAtom);
  const [showPrediction, setShowPrediction] = useAtom(showPredictionAtom);
  const [toggleOnModelAssistLabeling] = useAtom(toggleOnModelAssistLabelingAtom);

  const labelAssistRef = useRef<ModelAssistLabelingRef>(null);

  const shouldUseAdvancedPricing = useFeatureGateEnabled(ClientFeatures.AdvancedUsageBasedPricing);
  const [inferenceCost, inferenceCostLoading] = useModelInferenceCost(
    shouldUseAdvancedPricing && projectId && currentModelInfo
      ? {
          projectId,
          jobId: currentModelInfo.id,
        }
      : undefined,
  );

  const currentMediaStates = useCurrentMediaStates();
  const {
    mediaDetails,
    annotations,
    predictionAnnotations,
    mediaLevelLabel,
    predictionMediaLevelLabel,
  } = currentMediaStates;
  const currentMediaId = mediaDetails?.id;
  const isInTask = mediaDetails?.mediaStatus && mediaDetails.mediaStatus == MediaStatusType.InTask;
  const unpredicted =
    currentMediaStates.mediaLevelLabel !== MediaLevelLabel.OK &&
    !currentMediaStates.predictionAnnotations;

  const [gettingPrediction, setGettingPrediction] = useState(false);
  const { data: modelAssistSuggestions, isLoading } = useFetchModelAssistSuggestionsQuery(
    currentMediaStates?.mediaDetails?.id,
    modelId!,
    !!modelId && gettingPrediction,
  );
  useEffect(() => {
    if (modelAssistSuggestions) {
      setGettingPrediction(false);
    }
  }, [modelAssistSuggestions]);

  const [showPredictionJSON, setShowPredictionJSON] = useState(false);

  const handleClickLabelAssist = () => {
    if (labelAssistRef.current) {
      labelAssistRef.current.turnOnAssistMode(true);
    }
  };

  if (!currentMediaId) {
    return null;
  }

  return (
    <>
      <Divider orientation="horizontal" />
      <Box className={styles.section}>
        <Box
          className={classNames(styles.sectionTitleWithToggle, {
            [styles.toggleDisabled]: labelType !== LabelType.BoundingBox || isLabelMode,
          })}
          onClick={isLabelMode ? undefined : () => setShowGroundTruth(!showGroundTruth)}
        >
          <Typography variant="body_bold" className={styles.sectionTitleText}>
            {t('Labels')}
          </Typography>
          {labelType === LabelType.BoundingBox && !isLabelMode && (
            <ToggleButton
              id="preview-list-toggle-labels"
              isOn={showGroundTruth}
              disabled={isLabelMode}
              onToggle={isLabelMode ? undefined : () => setShowGroundTruth(!showGroundTruth)}
            />
          )}
        </Box>
        {/* intentionally wrap with a div to avoid layout error caused by flex */}
        <div style={{ flex: 1, minWidth: 120 }}>
          {!isInTask ? (
            <LabelPreviewList
              mediaCanvasRef={mediaCanvasRef}
              annotations={
                annotations?.sort((a, b) => a.id.localeCompare(b.id)) as (
                  | BitMapLabelingAnnotation
                  | BoxLabelingAnnotation
                  | ClassificationLabelingAnnotation
                )[]
              }
              labelingType={labelingType}
              mediaDimensions={mediaDetails?.properties || undefined}
              isLabelMode={isLabelMode}
              annotationSourceType={AnnotationSourceType.GroundTruth}
              mediaLevelLabel={mediaLevelLabel || undefined}
            />
          ) : (
            t('Media is in a labeling task')
          )}
        </div>
      </Box>

      <Divider orientation="horizontal" />

      {labelType === LabelType.BoundingBox && !isInTask && modelTrainedSuccessful && (
        <ModelAssistLabeling
          ref={labelAssistRef}
          mediaCanvasRef={mediaCanvasRef}
          modelInfo={currentModelInfo}
        />
      )}

      {!isLabelMode && (
        <Box className={styles.section} paddingBottom={9}>
          <Box
            className={classNames(styles.sectionTitleWithToggle, {
              [styles.toggleDisabled]: labelType !== LabelType.BoundingBox || isLabelMode,
            })}
            onClick={isLabelMode ? undefined : () => setShowPrediction(!showPrediction)}
          >
            <Typography variant="body_bold" className={styles.sectionTitleText}>
              {t('Predictions')}
            </Typography>
            {labelType === LabelType.BoundingBox && !isLabelMode && !unpredicted && (
              <ToggleButton
                id="preview-list-toggle-predictions"
                isOn={showPrediction}
                disabled={isLabelMode}
                onToggle={isLabelMode ? undefined : () => setShowPrediction(!showPrediction)}
              />
            )}
          </Box>
          {currentModelInfo && (
            <Box mb={2} mr={-4} display="flex" alignItems="center" justifyContent="space-between">
              <Box display="flex" alignItems="center">
                <Typography variant="body_medium">{`${currentModelInfo.modelName}(${currentModelInfo.confidence})`}</Typography>
              </Box>
              {unpredicted ? (
                <Typography variant="body_medium">{t('Unpredicted')}</Typography>
              ) : (
                labelType === LabelType.BoundingBox && (
                  <Tooltip
                    arrow
                    placement="top"
                    title={t(
                      'By enabling label assist, you can accept model predictions as ground truth labels.',
                    )}
                  >
                    <Button
                      id="model-assist-btn-from-prediction"
                      variant="text"
                      className={styles.modelAssistBlueBtn}
                      onClick={handleClickLabelAssist}
                    >
                      {t('Label Assist')}
                    </Button>
                  </Tooltip>
                )
              )}
            </Box>
          )}
          {labelType !== LabelType.BoundingBox ||
          isInTask ||
          !currentMediaStates.predictionAnnotations ||
          currentMediaStates.predictionAnnotations?.length === 0 ||
          toggleOnModelAssistLabeling ? (
            <></>
          ) : (
            <Box className={styles.sectionTitleTabs}>
              <Typography
                variant="body_bold"
                className={classNames(
                  styles.sectionTitle,
                  showPredictionJSON && styles.sectionTitleInactive,
                )}
                onClick={() => {
                  setShowPredictionJSON(false);
                }}
              >
                {t('List View')}
              </Typography>
              <Typography
                variant="body_bold"
                className={classNames(
                  styles.sectionTitle,
                  styles.sectionTitleJSON,
                  !showPredictionJSON && styles.sectionTitleInactive,
                )}
                onClick={() => {
                  setShowPredictionJSON(true);
                }}
              >
                {t('JSON')}
              </Typography>
            </Box>
          )}

          {labelType === LabelType.BoundingBox && currentModelInfo && unpredicted && (
            <Button
              id="get-predict-btn"
              onClick={() => setGettingPrediction(true)}
              variant="outlined"
              color="primary"
              className={styles.modelAssistDefaultBtn}
            >
              {gettingPrediction && isLoading && !modelAssistSuggestions && (
                <CircularProgress color="inherit" size={16} />
              )}
              <Box mr={1} />
              {t('Get Prediction')}
              {isCreditReferenceEnabled && shouldUseAdvancedPricing && !inferenceCostLoading && (
                <Typography className={styles.modelAssistBlockBtnSub}>
                  {t(`({{credit}} credit)`, {
                    credit: inferenceCost?.data.inferenceCredits,
                  })}
                </Typography>
              )}
            </Button>
          )}

          {(labelType === LabelType.BoundingBox && currentModelInfo && unpredicted) ||
          showPredictionJSON ? (
            <></>
          ) : (
            <div style={{ flex: 1, minWidth: 120 }}>
              {/* intentionally wrap with a div to avoid layout error caused by flex */}
              {!isInTask ? (
                <LabelPreviewList
                  mediaCanvasRef={mediaCanvasRef}
                  annotations={
                    predictionAnnotations?.sort((a, b) => a.id.localeCompare(b.id)) as (
                      | BitMapLabelingAnnotation
                      | BoxLabelingAnnotation
                      | ClassificationLabelingAnnotation
                    )[]
                  }
                  labelingType={labelingType}
                  mediaDimensions={mediaDetails?.properties || undefined}
                  isLabelMode={false}
                  isPrediction
                  annotationSourceType={AnnotationSourceType.Prediction}
                  mediaLevelLabel={predictionMediaLevelLabel || undefined}
                />
              ) : (
                t('Media is in a labeling task')
              )}
            </div>
          )}
          {showPredictionJSON && labelType === LabelType.BoundingBox && (
            <Box
              overflow={'hidden auto'}
              display="flex"
              flexDirection="column"
              width="100%"
              height="300px"
              flexWrap="wrap"
            >
              <SyntaxHighlighter
                language="json"
                style={githubGist}
                wrapLongLines
                customStyle={{
                  margin: 0,
                  background: 'unset',
                  padding: 0,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  position: 'relative',
                }}
                codeTagProps={{
                  style: {
                    position: 'absolute',
                  },
                }}
              >
                {JSON.stringify(currentMediaStates.predictionAnnotations, null, 3)}
              </SyntaxHighlighter>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default LabelListWrapper;
