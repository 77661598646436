import { IconButton, Typography } from '@clef/client-library';
import Box from '@material-ui/core/Box';
import cx from 'classnames';
import React from 'react';
import { useSnackbar } from 'notistack';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import { Menu, MenuItem, makeStyles } from '@material-ui/core';
import { RegisteredModel } from '@clef/shared/types';
import CLIBAPI from '@/api/clib_api';
import { isBundleInDeployableState, useGetBundleStatusQuery } from '@/serverStore/modelAnalysis';
import RefreshIcon from '@material-ui/icons/Refresh';
import { usePutBundleMutation } from '@/serverStore/endpoints';
import { isNumber } from 'lodash';
import { useGetActiveProjectsQuery, useGetSelectedProjectQuery } from '@/serverStore/projects';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import {
  useUpdateBundleIsDeletedMutation,
  useUpdateBundleIsFavMutation,
} from '@/serverStore/modelAnalysis/mutations';
import { ClientFeatures, useFeatureGateEnabled } from '@/hooks/useFeatureGate';
import ActiveProjectDialog from '../ActiveProjectDialog';
import {
  useActiveProjectsEnabled,
  useIsNonStripeUser,
  useCheckActiveProjectReachLimit,
} from '@/hooks/useSubscriptions';
import { isModelTrainingSuccessful } from '@/store/projectModelInfoState/utils';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard';

const useStyles = makeStyles(theme => ({
  operationIconBtn: {
    height: 28,
    width: 28,
  },
  operationIcon: {
    fontSize: 24,
    color: theme.palette.greyModern[400],
  },
  deleteText: {
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
  },
  favStarIcon: {
    color: theme.palette.yellow[400],
  },
}));

type OperationTableCellProps = {
  model: RegisteredModel;
  threshold: number;
  isFav: boolean | null;
  bundleId: string | null;
};

const OperationTableCell = (props: OperationTableCellProps) => {
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { model, threshold, isFav, bundleId } = props;
  const updateBundleIsFav = useUpdateBundleIsFavMutation(
    model.modelName || 'Untitled model',
    threshold,
  );
  const updateBundleIsDeleted = useUpdateBundleIsDeletedMutation(
    model.modelName || 'Untitled model',
    threshold,
  );
  const shouldAllowDownloadingBundle = useFeatureGateEnabled(
    ClientFeatures.ShouldAllowDownloadingBundle,
  );
  const { data: bundleStatus } = useGetBundleStatusQuery(model.id, threshold);
  const { data: project } = useGetSelectedProjectQuery();
  const { id: projectId } = project ?? {};
  const putBundle = usePutBundleMutation(model.modelName ?? '');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [activeProjectDialogOpen, setActiveProjectDialogOpen] = React.useState(false);
  const { hasReachLimit } = useCheckActiveProjectReachLimit();
  const activeProjectEnabled = useActiveProjectsEnabled();
  const isNonStripeUser = useIsNonStripeUser();

  const { data: activeProjects } = useGetActiveProjectsQuery();
  const { data: selectedProject } = useGetSelectedProjectQuery();
  const copyToClipboard = useCopyToClipboard({
    text: bundleId ?? '',
    successMessage: t('The Model Id has been copied to your clipboard'),
  });

  const selectedProjectId = selectedProject?.id ?? 0;
  const isSelectedProjectActive = !!activeProjects?.find(
    project => project.id === selectedProjectId,
  );

  const canDirectlyDownloadModelBundle =
    !hasReachLimit && isNonStripeUser && isSelectedProjectActive;

  const handleMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreClose = () => {
    setAnchorEl(null);
  };

  const handleFavIconClick = (isFav: boolean) => {
    updateBundleIsFav.mutate({ modelId: model.id, threshold, isFav });
  };

  const handleBundleDeleteClick = () => {
    updateBundleIsDeleted.mutate({ modelId: model.id, threshold, isDeleted: true });
    handleMoreClose();
  };

  const downloadModelBundle = async (bundleId: string) => {
    if (!projectId) return;
    const { bundle_zip_url } = await CLIBAPI.downloadBundle(bundleId);
    window.open(bundle_zip_url);
  };

  const handleDownloadModelClick = (bundleId: string) => {
    if (activeProjectEnabled) {
      // if not reach limit, enterprise account can directly download model from active projects
      if (canDirectlyDownloadModelBundle) {
        downloadModelBundle(bundleId);
      } else {
        setActiveProjectDialogOpen(true);
      }
    } else if (shouldAllowDownloadingBundle) {
      downloadModelBundle(bundleId);
    }
  };

  const onCreateBundle = async () => {
    if (!projectId) return;
    if (!model.modelName || !isNumber(threshold)) {
      return;
    }
    if (!isModelTrainingSuccessful(model.status, model.metricsReady)) {
      enqueueSnackbar(t('The model has not yet finish training, Please try again later.'), {
        variant: 'warning',
        autoHideDuration: 12000,
      });
      return;
    }
    enqueueSnackbar(
      t(`A version of "${model.modelName}, confidence threshold ${threshold}" is generating`),
      { variant: 'info' },
    );
    await putBundle.mutateAsync({
      projectId,
      modelId: model.id,
      threshold: threshold,
    });
  };

  return (
    <>
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
        {!isBundleInDeployableState(bundleStatus?.status) ? (
          <IconButton
            tooltip={t('Save this bundle for more actions')}
            className={styles.operationIconBtn}
            onClick={onCreateBundle}
          >
            <RefreshIcon className={styles.operationIcon} />
          </IconButton>
        ) : (
          <>
            {bundleId && (
              <>
                <IconButton
                  className={styles.operationIconBtn}
                  onClick={() => {
                    handleFavIconClick(!isFav);
                  }}
                >
                  {isFav ? (
                    <StarRoundedIcon className={cx(styles.operationIcon, styles.favStarIcon)} />
                  ) : (
                    <StarBorderRoundedIcon className={styles.operationIcon} />
                  )}
                </IconButton>
                <IconButton className={styles.operationIconBtn} onClick={handleMoreClick}>
                  <MoreHoriz className={styles.operationIcon} />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  keepMounted
                  onClose={handleMoreClose}
                >
                  <MenuItem onClick={copyToClipboard}>{t('Copy Model ID')}</MenuItem>
                  {(activeProjectEnabled || shouldAllowDownloadingBundle) && (
                    <MenuItem onClick={() => handleDownloadModelClick(bundleId)}>
                      {t('Download Model')}
                    </MenuItem>
                  )}
                  <MenuItem onClick={handleBundleDeleteClick}>
                    <Typography className={styles.deleteText}>{t('Delete Model')}</Typography>
                  </MenuItem>
                </Menu>
              </>
            )}
          </>
        )}
      </Box>
      {activeProjectEnabled && (
        <ActiveProjectDialog
          projectId={selectedProjectId}
          canCommercialUse={isNonStripeUser}
          isSelectedProjectActive={isSelectedProjectActive}
          open={activeProjectDialogOpen}
          onClose={() => {
            setActiveProjectDialogOpen(false);
          }}
          hasReachLimit={hasReachLimit}
          downloadModelBundle={() => {
            bundleId && downloadModelBundle(bundleId);
          }}
        />
      )}
    </>
  );
};

export default OperationTableCell;
