import React, { useState } from 'react';
import cx from 'classnames';
import { Box, makeStyles, Theme } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import OpenInNew from '@material-ui/icons/OpenInNew';

import { Typography, Button } from '@clef/client-library';
import { LabelType } from '@clef/shared/types';

import { defaultSelectOptions } from '@/constants/data_browser';
import { EvaluationSetItem } from '@/api/evaluation_set_api';
import { RegisteredModelWithThreshold } from '@/api/model_api';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useDatasetMediaCountQuery } from '@/serverStore/dataset';

import ModelPerformance from '../ModelPerformance';
import { getEvaluationSetName } from '../utils';
import ModelComparisonTrainingSettings from './ModelComparisonTrainingSettings';
import { useGetBatchModelMetricsQueryByModelId } from '@/serverStore/modelAnalysis';

const useStyles = makeStyles<Theme, { comparisonValue?: number }>(theme => ({
  dialogSection: {
    display: 'flex',
    padding: theme.spacing(5, 0, 5, 0),
  },
  compareSummary: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(10),
    height: 190,
    borderRadius: 20,
    border: `1px solid ${theme.palette.greyModern[300]}`,
    backgroundColor: theme.palette.greyModern[50],
  },
  compareInfoHeaderColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  compareInfoHeaderColumnTitle: {
    marginBottom: theme.spacing(1),
  },
  openInNew: {
    marginLeft: theme.spacing(1),
    color: theme.palette.greyModern[600],
    fontSize: 20,
    cursor: 'pointer',
  },
  modelInfoTitle: {
    color: theme.palette.greyModern[500],
  },
  performanceTrendScore: {
    width: theme.spacing(12),
  },
  performanceTrend: {
    fontSize: theme.spacing(4),
    color: props =>
      props.comparisonValue
        ? props.comparisonValue > 0
          ? theme.palette.green[500]
          : props.comparisonValue < 0
          ? theme.palette.orange[500]
          : theme.palette.greyModern[500]
        : theme.palette.greyModern[500],
  },
  modelInfo: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  comparisionContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(3),
  },
}));

const ComparisonDisplay: React.FC<{ baseline?: number; candidate?: number }> = ({
  baseline,
  candidate,
}) => {
  const comparisonValue = ((candidate ?? 0) - (baseline ?? 0)) * 100;
  const styles = useStyles({ comparisonValue });
  if (!baseline || !candidate) {
    return null;
  }
  return (
    <Box className={styles.comparisionContainer}>
      <Typography
        variant="h4"
        className={cx(styles.performanceTrend, styles.performanceTrendScore)}
      >
        {Math.abs(comparisonValue).toFixed(1)}%
      </Typography>
      {comparisonValue > 0 && <ArrowUpwardIcon className={styles.performanceTrend} />}
      {comparisonValue < 0 && <ArrowDownwardIcon className={styles.performanceTrend} />}
    </Box>
  );
};

interface IProps {
  evaluationSet: EvaluationSetItem;
  baseline: RegisteredModelWithThreshold;
  candidate: RegisteredModelWithThreshold;
}

export const ModelComparisonSummary: React.FC<IProps> = ({
  evaluationSet,
  baseline,
  candidate,
}) => {
  const { data: mediaCount } = useDatasetMediaCountQuery({
    version: evaluationSet.datasetVersion.version,
    selectOptions: defaultSelectOptions,
  });

  const { data: baselineBatchModelMetrics } =
    useGetBatchModelMetricsQueryByModelId(baseline?.id, baseline?.threshold) ?? {};
  const { data: candidateBatchModelMetrics } =
    useGetBatchModelMetricsQueryByModelId(candidate?.id, candidate?.threshold) ?? {};

  const baselineModelMetrics = baselineBatchModelMetrics?.find(
    _ => _.evaluationSetId === evaluationSet?.id,
  );
  const candidateModelMetrics = candidateBatchModelMetrics?.find(
    _ => _.evaluationSetId === evaluationSet?.id,
  );

  const styles = useStyles({});

  const handleOpenModelPage = (model: RegisteredModelWithThreshold) => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    searchParams.set('modelId', model.id);
    searchParams.set('threshold', model.threshold.toString());
    url.search = searchParams.toString();
    window.open(url, '_blank');
  };

  const [openTrainingSettings, setOpenTrainingSettings] = useState<boolean>(false);
  const { labelType } = useGetSelectedProjectQuery().data ?? {};

  return (
    <Box className={styles.dialogSection} flexDirection="row" justifyContent="space-between">
      <Box className={styles.compareSummary}>
        <Box height="100%" display="flex" flexDirection="column" justifyContent="space-between">
          <Box className={styles.compareInfoHeaderColumn}>
            <Box className={styles.compareInfoHeaderColumnTitle}>
              <Typography variant="body_bold">{t('Baseline model')}</Typography>
            </Box>
            <Box className={styles.modelInfo}>
              <Typography maxWidth={300}>{baseline.modelName}</Typography>
              {labelType !== LabelType.Classification && (
                <Typography
                  className={styles.modelInfoTitle}
                >{`(${baseline.threshold})`}</Typography>
              )}
              <OpenInNew
                className={styles.openInNew}
                onClick={() => handleOpenModelPage(baseline)}
              />
            </Box>
          </Box>
          <ModelPerformance
            performance={baselineModelMetrics?.metrics?.all.performance}
            precision={baselineModelMetrics?.metrics?.all.precision}
            recall={baselineModelMetrics?.metrics?.all.recall}
          />
        </Box>
        <Box height="100%" display="flex" flexDirection="column" justifyContent="space-between">
          <Box className={styles.compareInfoHeaderColumn}>
            <Box className={styles.compareInfoHeaderColumnTitle}>
              <Typography variant="body_bold">{t('Candidate model')}</Typography>
            </Box>
            <Box className={styles.modelInfo}>
              <Typography maxWidth={300}>{candidate.modelName}</Typography>
              {labelType !== LabelType.Classification && (
                <Typography
                  className={styles.modelInfoTitle}
                >{`(${candidate.threshold})`}</Typography>
              )}
              <OpenInNew
                className={styles.openInNew}
                onClick={() => handleOpenModelPage(candidate)}
              />
            </Box>
          </Box>
          <ModelPerformance
            performance={candidateModelMetrics?.metrics?.all.performance}
            precision={candidateModelMetrics?.metrics?.all.precision}
            recall={candidateModelMetrics?.metrics?.all.recall}
          />
        </Box>
        <Box height="100%" display="flex" flexDirection="column" justifyContent="space-between">
          <Box className={styles.compareInfoHeaderColumn}>
            <Box className={styles.compareInfoHeaderColumnTitle}>
              <Typography variant="body_bold">{t('Evaluation set')}</Typography>
            </Box>
            <Box className={styles.modelInfo}>
              <Typography maxWidth={300}>{getEvaluationSetName(evaluationSet)}</Typography>
              <Typography className={styles.modelInfoTitle}>{`(${mediaCount} images)`}</Typography>
              <OpenInNew
                className={styles.openInNew}
                onClick={() => {
                  const newUrl =
                    window.location.href.split('/models')[0] +
                    '/data/dataset_snapshot/' +
                    evaluationSet.datasetVersion.version;
                  window.open(newUrl, '_blank', 'noopener,noreferrer');
                }}
              />
            </Box>
            <Box display="flex" flexDirection="column" mt={5}>
              <ComparisonDisplay
                baseline={baselineModelMetrics?.metrics?.all.performance}
                candidate={candidateModelMetrics?.metrics?.all.performance}
              />
              <ComparisonDisplay
                baseline={baselineModelMetrics?.metrics?.all.precision}
                candidate={candidateModelMetrics?.metrics?.all.precision}
              />
              <ComparisonDisplay
                baseline={baselineModelMetrics?.metrics?.all.recall}
                candidate={candidateModelMetrics?.metrics?.all.recall}
              />
            </Box>
          </Box>
        </Box>
        <Button
          id="compare-training-settings-btn"
          variant="outlined"
          onClick={() => setOpenTrainingSettings(true)}
        >
          {t('Compare Training Settings')}
        </Button>
      </Box>

      {openTrainingSettings && (
        <ModelComparisonTrainingSettings
          open={openTrainingSettings}
          onClose={() => setOpenTrainingSettings(false)}
          baseline={baseline}
          candidate={candidate}
        />
      )}
    </Box>
  );
};
