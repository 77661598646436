import { ApiResponseLoader } from '@clef/client-library';
import { JobInfo, JobType, JobMetricData, MetricLabel } from '@clef/shared/types';
import {
  Box,
  Card,
  CardContent,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Button } from '@clef/client-library';
import Autorenew from '@material-ui/icons/Autorenew';
import ExpandMore from '@material-ui/icons/ExpandMore';
import GetAppOutlined from '@material-ui/icons/GetAppOutlined';
import groupBy from 'lodash/groupBy';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
// TODO Mingrui: I think this is overkill to use this library for displaying log, follow up later
import { LazyLog } from 'react-lazylog';
import { useParams } from 'react-router-dom';
import CopyToClipboardButton from '../../components/CopyToClipboardButton/CopyToClipboardButton';
import { UserAvatar } from '../../components/UserAvatar/UserAvatarNew';
import { useDatasetExportedWithVersionsQuery } from '@/serverStore/dataset';
import { refreshJobLogs, useMaglevGetJobLogsApi } from '../../hooks/api/useMaglevApi';
import { useUserInfo } from '../../hooks/api/useUserApi';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { getMetricLabel, getModelClassMetric } from '../../utils';
import AugmentationDetailsDialog from './AugmentationDetailsDialog';
import MetricsChart from './MetricsChart';
import ModelDetailsDialog from './ModelDetailsDialog';
import { useJobDetailForCurrentProject } from '@/serverStore/jobs';
import { ClientFeatures, useFeatureGateEnabled } from '@/hooks/useFeatureGate';

export const DISPLAY_LEARNING_CURVE_METRICS = [
  MetricLabel.loss_train,
  MetricLabel.loss_val,
  MetricLabel.mean_iou_train,
  MetricLabel.mean_iou_val,
  MetricLabel.old_mean_iou_train,
  MetricLabel.old_mean_iou_val,
];

export const DISPLAY_SUMMARY_EVAL_METRICS = [MetricLabel.mAP, MetricLabel.AUC, MetricLabel.mIOU];

const useStyles = makeStyles(theme => ({
  sectionTitle: {
    fontWeight: 500,
  },
  tableRoot: {
    '& caption': {
      fontWeight: 500,
      captionSide: 'top',
      paddingLeft: 0,
    },
    '& th': {
      borderBottom: 'none',
      fontWeight: 500,
    },
    '& td': {
      borderBottom: 'none',
      paddingLeft: 0,
    },
  },
  weakText: {
    fontWeight: 'normal',
    color: theme.palette.text.hint,
  },
  btn: {
    textTransform: 'initial',
    marginLeft: -12,
  },
  monospace: {
    fontFamily: 'monospace',
    fontSize: 12,
  },
  refreshButton: {
    paddingLeft: 0,
  },
}));

export const JobDetailsPage = () => {
  const styles = useStyles();
  const { jobId } = useParams<{ jobId: JobInfo['id'] }>();
  const [openModelDetails, setOpenModelDetails] = useState(false);
  const [openTransformsDetails, setOpenTransformsDetails] = useState(false);
  const [downloadMenuElement, setDownloadMenuElement] = useState<HTMLButtonElement | null>(null);

  const handleDownloadMenuClick: React.MouseEventHandler<HTMLButtonElement> = React.useCallback(
    event => setDownloadMenuElement(event.currentTarget),
    [],
  );

  // get project id
  const { data: selectedProject } = useGetSelectedProjectQuery();
  const projectId = selectedProject?.id;

  const {
    data: jobDetail,
    isLoading: jobDetailLoading,
    error: jobDetailError,
    refetch: refetchJobDetail,
  } = useJobDetailForCurrentProject(jobId);
  const isTrainingLogEnabled = !useFeatureGateEnabled(ClientFeatures.DisableTrainingLogs);
  const [jobLogs, jobLogsLoading, jobLogsError] = useMaglevGetJobLogsApi(
    projectId ? { projectId, jobId } : undefined,
  );

  const { data: datasetExported } = useDatasetExportedWithVersionsQuery({ includeFastEasy: true });

  const {
    jobDescription,
    metrics,
    jobType,
    jobStatus,
    createdAt,
    creatorId,
    hyperParams,
    dataAugSpecId,
    exportedDatasetId,
    exportedModelSignedURL,
    customCodeSignedURL,
    modelArch,
    modelClass,
    modelVersion,
  } = jobDetail || {};

  const users = useUserInfo(creatorId);

  const getSnapshot = (snapshotId: number) => {
    return datasetExported?.datasetVersions?.find(({ id }) => id === snapshotId)?.name;
  };

  const createdAtLocal = createdAt ? new Date(createdAt).toLocaleString() : '';

  const isEvalMetric = (metric: JobMetricData) => {
    return metric.values.length == 1 && includes(DISPLAY_SUMMARY_EVAL_METRICS, metric.name);
  };

  const trainingMetrics = groupBy(
    metrics?.filter(
      // Note: MetricLabel.old_mean_iou_train and MetricLabel.mIOU have the same value
      // which is why we need to add below "!isEvalMetric" check
      metric => includes(DISPLAY_LEARNING_CURVE_METRICS, metric.name) && !isEvalMetric(metric),
    ),
    // Render metrics with `_val` suffix in same chart
    ({ name }) => name.replace(/_val$/, ''),
  );

  return (
    <>
      <Box display="flex" justifyContent="flex-start" pl={0} mb={8}>
        {!jobDetailLoading && (
          <Box>
            <Button
              id="refresh-job-state"
              disabled={jobDetailLoading}
              onClick={() => {
                refetchJobDetail();
              }}
              variant="text"
              color="primary"
              startIcon={<Autorenew />}
              className={styles.refreshButton}
            >
              {t('Refresh Job State')}
            </Button>
          </Box>
        )}
        {exportedModelSignedURL && (
          <>
            <Box ml={2}>
              <Button
                aria-controls="download-menu"
                aria-haspopup="true"
                id="download-model-btn"
                color="primary"
                onClick={
                  customCodeSignedURL
                    ? handleDownloadMenuClick
                    : () => window.open(exportedModelSignedURL)
                }
                startIcon={<GetAppOutlined />}
                endIcon={customCodeSignedURL && <ExpandMore />}
              >
                {customCodeSignedURL ? t('Download') : t('Download Model Weights')}
              </Button>
            </Box>

            <Menu
              data-testid="download-dropdown"
              anchorEl={downloadMenuElement}
              getContentAnchorEl={null}
              onClose={() => setDownloadMenuElement(null)}
              open={downloadMenuElement?.getAttribute('aria-controls') === `download-menu`}
              id={`download-menu`}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <MenuItem
                data-testid="model-weights-download"
                onClick={() => window.open(exportedModelSignedURL)}
              >
                {t('Model Weights')}
              </MenuItem>
              <MenuItem
                data-testid="source-code-download"
                onClick={() => window.open(customCodeSignedURL)}
              >
                {t('Source Code for CLI Job')}
              </MenuItem>
            </Menu>
          </>
        )}
      </Box>

      <ApiResponseLoader response={jobDetail} loading={jobDetailLoading} error={jobDetailError}>
        {loadedResponse => (
          <>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Box mb={8}>
                  <Table size="small" classes={{ root: styles.tableRoot }}>
                    <caption>{t('Job Info')}</caption>
                    <TableBody>
                      <TableRow>
                        <TableCell padding="none" component="th" scope="row">
                          {t('Job ID')}
                        </TableCell>
                        <TableCell data-testid="job-id">
                          <span className={styles.monospace}>{jobId}</span>
                          <CopyToClipboardButton text={jobId} />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell padding="none" component="th" scope="row">
                          {t('Description')}
                        </TableCell>
                        <TableCell data-testid="job-description">{jobDescription}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell padding="none" component="th" scope="row">
                          {t('Status')}
                        </TableCell>
                        <TableCell data-testid="job-status">{jobStatus}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell padding="none" component="th" scope="row">
                          {t('Created at')}
                        </TableCell>
                        <TableCell data-testid="job-createdAt">{createdAtLocal}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell padding="none" component="th" scope="row">
                          {t('Creator')}
                        </TableCell>
                        <TableCell>
                          <Box my={-1}>
                            {users ? (
                              <UserAvatar name={users.name} data-testid="job-creator" />
                            ) : null}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              </Grid>

              <Grid item xs={6}>
                {exportedDatasetId && hyperParams && (
                  <Box mb={8}>
                    <Table size="small" classes={{ root: styles.tableRoot }}>
                      <caption>{t('Data Info')}</caption>
                      <TableBody>
                        <TableRow>
                          <TableCell padding="none" component="th" scope="row" width="30%">
                            {jobType === JobType.Train ? t('Trained on') : t('Evaluated on')}{' '}
                            <span className={styles.weakText}>{t('(Dataset, split)')}</span>
                          </TableCell>
                          <TableCell data-testid="job-dataset" width="50%">
                            {exportedDatasetId}
                            {t('-')}
                            {getSnapshot(exportedDatasetId)}
                            {t(',')}{' '}
                            {jobType === JobType.Train
                              ? hyperParams.dataset.train_split_key
                              : hyperParams.dataset.test_split_key}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                )}

                <Table size="small" classes={{ root: styles.tableRoot }}>
                  <caption>{t('Model Info')}</caption>
                  <TableBody>
                    <TableRow>
                      <TableCell padding="none" component="th" scope="row">
                        {t('Model')}
                      </TableCell>
                      <TableCell>
                        <Box display="flex" ml={-2} my={-2}>
                          <Button
                            id="view-model-details-btn"
                            color="primary"
                            className={styles.btn}
                            onClick={() => setOpenModelDetails(true)}
                          >
                            {t('View Model Details')}
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell padding="none" component="th" scope="row">
                        {t('Class and architecture')}
                      </TableCell>
                      <TableCell>
                        {modelClass}
                        {t('-')}
                        {modelArch}
                        {t('-')}
                        {modelVersion}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell padding="none" component="th" scope="row">
                        {t('Pre-processing')}
                      </TableCell>
                      <TableCell>
                        {dataAugSpecId ? (
                          <Box ml={-2} my={-2}>
                            <Button
                              color="primary"
                              className={styles.btn}
                              id="transforms-details-btn"
                              onClick={() => setOpenTransformsDetails(true)}
                            >
                              {t('View Pre-processing Details')}
                            </Button>
                          </Box>
                        ) : (
                          <span className={styles.weakText}>{t('No Pre-processing Details')}</span>
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
            <Box mt={4} component="section" aria-labelledby="metrics-section-title">
              <Typography
                className={styles.sectionTitle}
                variant="subtitle1"
                color="textSecondary"
                id="metrics-section-title"
              >
                {t('Training Metrics')}
              </Typography>
              {!isEmpty(trainingMetrics) ? (
                <Box display="flex" flexWrap="wrap">
                  {Object.entries(trainingMetrics).map(([metricGroupName, metrics]) => {
                    return (
                      <Box maxWidth={600} mt={2} key={metricGroupName} mr={4} width="100%">
                        <Paper>
                          <MetricsChart
                            name={metricGroupName}
                            metrics={metrics}
                            startTime={Number(createdAt)}
                          />
                        </Paper>
                      </Box>
                    );
                  })}
                </Box>
              ) : (
                <Box mt={2}>
                  <Typography color="textSecondary">{t('No metrics available')}</Typography>
                </Box>
              )}
            </Box>
            <Box mt={2}>
              <Typography
                className={styles.sectionTitle}
                variant="subtitle1"
                color="textSecondary"
                id="metrics-section-title"
                display="block"
              >
                {t('Evaluation Metrics')}
              </Typography>
              {metrics ? (
                <Box display="flex" mt={2}>
                  <Card variant="outlined">
                    <CardContent>
                      <Box display="flex" alignItems="baseline" px={4} mb={-1}>
                        <Typography component="span" variant="h2" id="metric-label">
                          {getMetricLabel(modelClass)}
                        </Typography>
                        <Box ml={4}>
                          <Typography
                            aria-labelledby="metric-label"
                            component="span"
                            variant="h2"
                            color="primary"
                          >
                            {getModelClassMetric(loadedResponse)}
                          </Typography>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
              ) : (
                <Box mt={2}>
                  <Typography color="textSecondary">{t('No metrics available')}</Typography>
                </Box>
              )}
            </Box>
            {openModelDetails && (
              <ModelDetailsDialog onClose={() => setOpenModelDetails(false)} job={loadedResponse} />
            )}

            {openTransformsDetails && projectId && loadedResponse.dataAugSpecId && (
              <AugmentationDetailsDialog
                pipelineId={loadedResponse.dataAugSpecId}
                projectId={projectId}
                onClose={() => setOpenTransformsDetails(false)}
              />
            )}
          </>
        )}
      </ApiResponseLoader>

      {isTrainingLogEnabled && (
        <Box mt={4} component="section">
          <Grid container spacing={4} justifyContent="space-between" alignItems="baseline">
            <Grid item>
              <Typography className={styles.sectionTitle} variant="subtitle1" color="textSecondary">
                {t('Logs')}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                id="refresh-job-logs"
                disabled={jobLogsLoading}
                color="primary"
                onClick={() => refreshJobLogs({ keys: 'refresh-all' })}
              >
                {t('Refresh')}
              </Button>
            </Grid>
          </Grid>

          <ApiResponseLoader response={jobLogs} loading={jobLogsLoading} error={jobLogsError}>
            {loadedResponse => (
              <LazyLog text={loadedResponse} height={600} lineClassName="cy-view-details-job-log" />
            )}
          </ApiResponseLoader>
        </Box>
      )}
    </>
  );
};
