import React from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import LabelTypeSelector from './LabelTypeSelector';
import { useDataBrowserStyles } from '../styles';
import DataBrowserEmptyStateSvg from '../../../images/data-browser/data-browser-upload.svg';
import LinkedImagesSvg from '../../../images/data-browser/linked-images.svg';
import CameraImagesSvg from '../../../images/data-browser/camera.svg';
import { Button } from '@clef/client-library';
import { useDialog } from '../../../components/Layout/components/useDialog';
import { LabelType, UserPermission } from '@clef/shared/types';
import { useCurrentProjectModelInfoQuery } from '@/serverStore/projectModels';
import DataBrowserUploadDropZone from '../DataBrowserUploadDropZone';
import { useGetProjectDefectsQuery, useGetSelectedProjectQuery } from '@/serverStore/projects';
import WebcamFileSwitchButton from '../../../components/WebcamFileSwitchButton';
import { useHasPermission } from '../../../hooks/useProjectRolePermissions';
import { ClientFeatures, useFeatureGateEnabled } from '@/hooks/useFeatureGate';

const useStyles = makeStyles(theme => ({
  emptyPage: {},
  overlay: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255,255,255, 0.5)',
    backdropFilter: 'blur(3px)',
    zIndex: theme.zIndex.drawer - 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  emptyStateImage: {
    verticalAlign: 'middle',
    width: 100,
    height: 100,
    marginTop: theme.spacing(22),
  },
  emptyStateText: {
    color: '#051221', // TODO: should be theme.palette.grey[900],
    fontSize: 20,
    lineHeight: '28px',
    marginBottom: theme.spacing(3),
  },
  circle1: {
    position: 'absolute',
    top: 'calc(50% - 120px)',
    left: 'calc(50% - 120px)',
    width: 240,
    height: 240,
    borderRadius: '100%',
    backgroundColor: theme.palette.blue[25], // TODO: should be theme.palette.blue[25],
    mixBlendMode: 'multiply',
    zIndex: 10,
  },
  circle2: {
    position: 'absolute',
    top: 'calc(50% - 181px)',
    left: 'calc(50% - 181px)',
    width: 362,
    height: 362,
    borderRadius: '100%',
    backgroundColor: theme.palette.blue[50], // TODO: should be theme.palette.blue[50],
    zIndex: 0,
    mixBlendMode: 'multiply',
    cursor: 'pointer',
  },
  uploadButton: {
    backgroundColor: 'transparent',
    marginTop: theme.spacing(15),
    border: `1px solid ${theme.palette.grey[300]}`,
    color: theme.palette.grey[700],
    height: 40,
    '& + $uploadButton': {
      marginLeft: theme.spacing(3),
    },
  },
  dropzone: {
    border: 'none',
    background: 'none',
    cursor: 'unset',
  },
  cloudIcon: {
    width: 100,
    height: 100,
  },
  northIcon: {
    width: 60,
    height: 60,
    position: 'absolute',
    top: 35,
    left: '50%',
    transform: 'translateX(-50%)',
    animation: '$northIconAnimation 2s ease infinite',
  },
}));

export type FREEmptyPageProps = {
  changeLabelType?: (newLabelType: LabelType) => Promise<void>;
  changingLabelType?: boolean;
};

export type FREEmptyPageWithLimitsProps = FREEmptyPageProps & {
  fileCapacity?: number | null;
  fileLimit?: number | null;
  disabled?: boolean;
  withRightPanel?: boolean;
};

const FREEmptyPage: React.FC<FREEmptyPageWithLimitsProps> = ({
  changeLabelType,
  changingLabelType,
  disabled: fileUploadDisabled,
  withRightPanel = false,
}) => {
  const localStyles = useStyles();
  const styles = useDataBrowserStyles();

  const { openUpload, openLoadSampleData } = useDialog();
  const canUploadData = useHasPermission(UserPermission.UploadData);

  const { id: currentModelId } = useCurrentProjectModelInfoQuery();
  const { labelType } = useGetSelectedProjectQuery().data ?? {};
  const { data: defectsForProject = [] } = useGetProjectDefectsQuery();
  const hasDefectsCreated = Object.entries(defectsForProject).length;
  const showLoadSampleDataButton = !useFeatureGateEnabled(
    ClientFeatures.DisableLoadSampleDataButton,
  );
  const isWebcamEnabled = !useFeatureGateEnabled(ClientFeatures.DisableWebcam);

  return (
    <div onClick={() => openUpload()}>
      <DataBrowserUploadDropZone>
        {isDragActive => (
          <>
            {changingLabelType && <Box className={localStyles.overlay}></Box>}
            <Box className={localStyles.emptyPage}>
              {!currentModelId &&
                !hasDefectsCreated &&
                labelType !== LabelType.AnomalyDetection && (
                  <LabelTypeSelector changeLabelType={changeLabelType} />
                )}
              {/* TODO: add some UI when file upload disabled */}
              {!isDragActive && !fileUploadDisabled && (
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  className={styles.emptyStateContainer}
                  direction="column"
                >
                  <div className={localStyles.circle2}>
                    <div className={localStyles.circle1}></div>
                  </div>
                  <img src={DataBrowserEmptyStateSvg} className={localStyles.emptyStateImage} />
                  <Typography variant="h3" className={localStyles.emptyStateText}>
                    {t('Drop to upload')}
                  </Typography>
                  <Typography className={styles.uploadLabel}>
                    {t('(PNG, JPEG, BMP, MPO, TIFF, files/folders)')}
                  </Typography>
                  <Grid container alignItems="center" justifyContent="center">
                    {showLoadSampleDataButton && (
                      <Button
                        id="empty-state-import-sample-data-button"
                        target="_blank"
                        color="primary"
                        startIcon={<img src={LinkedImagesSvg} />}
                        className={localStyles.uploadButton}
                        onClick={e => {
                          e.stopPropagation();
                          e.nativeEvent.stopImmediatePropagation();
                          openLoadSampleData();
                        }}
                      >
                        {t('Load Sample Data')}
                      </Button>
                    )}
                    {canUploadData && isWebcamEnabled && (
                      <Button
                        id="empty-use-webcam-button"
                        target="_blank"
                        color="primary"
                        startIcon={<img src={CameraImagesSvg} />}
                        className={localStyles.uploadButton}
                        onClick={e => {
                          e.stopPropagation();
                          e.nativeEvent.stopImmediatePropagation();
                          openUpload(/* useWebcam */ true);
                        }}
                      >
                        <WebcamFileSwitchButton useWebcam onClick={() => {}} defaultStyles={true} />
                      </Button>
                    )}
                  </Grid>
                </Grid>
              )}
              {!isDragActive && !fileUploadDisabled && canUploadData && (
                <Box
                  justifyContent="center"
                  alignItems="center"
                  display="flex"
                  marginTop="auto"
                  fontSize={14}
                  className={styles.uploadMore}
                >
                  <Box
                    display="flex"
                    style={{
                      paddingLeft: 80,
                      marginRight: withRightPanel ? 332 : undefined,
                    }}
                  >
                    <Typography>{t('More upload options?')}</Typography>
                    <strong
                      data-testid="empty-state-upload-button"
                      id="empty-state-upload-button"
                      onClick={e => {
                        e.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();
                        openUpload();
                      }}
                      className={styles.uploadMoreImages}
                    >
                      {t('Click here')}
                    </strong>
                  </Box>
                </Box>
              )}
            </Box>
          </>
        )}
      </DataBrowserUploadDropZone>
    </div>
  );
};

export default FREEmptyPage;
