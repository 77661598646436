import {
  CanvasAnnotation,
  CanvasAnnotationType,
  CanvasInteractionEvent,
  CanvasMode,
  generateAnnotationId,
  resizeCanvas,
  AnnotationSourceType,
} from '@clef/client-library';
import {
  Annotation,
  AnnotationType,
  AnnotationWithoutId,
  BoundingBoxAnnotationData,
  ClassificationAnnotationData,
  Defect,
  Dimensions,
  LabelingType,
  PureCanvasAnnotation,
  SegmentationAnnotationData,
  LabelType,
} from '@clef/shared/types';
import { Layer } from 'konva/types/Layer';
import { getDefectColor } from '../../utils';
import {
  BitMapLabelingAnnotation,
  BoxLabelingAnnotation,
  ClassificationLabelingAnnotation,
  PureCanvasLabelingAnnotation,
  ToolMode,
  useLabelingState,
} from './labelingState';
import { BoxAnnotation as CanvasBoxAnnotation } from '@clef/shared/types';
import { RGB } from 'konva/types/types';
import { hexToRgb, runLengthEncode } from '@clef/shared/utils';
import { waitForPixelationWorkersFinished } from '@clef/client-library/src/components/MediaInteractiveCanvas/linePixelation';
import { truncate } from 'lodash';
import { useCallback, useState } from 'react';
import { useHintSnackbar, HintEvents } from './HintSnackbar';

export const serverAnnotationsToLabelingAnnotations = (
  annotations?: Annotation[],
  filterFn?: (annotation: Annotation) => boolean,
) => {
  if (!annotations) {
    return undefined;
  }
  return annotations
    .filter(
      ann =>
        ann.annotationType === AnnotationType.segmentation ||
        ann.annotationType === AnnotationType.bndbox ||
        ann.annotationType === AnnotationType.classification ||
        ann.annotationType === AnnotationType.anomaly_detection,
    )
    .filter(ann => !filterFn || filterFn(ann))
    .map(ann => {
      const { rangeBox, segmentationBitmapEncoded } = ann;
      let data:
        | SegmentationAnnotationData
        | BoundingBoxAnnotationData
        | ClassificationAnnotationData = {};

      // console.log('ann', ann);
      if (ann.annotationType === AnnotationType.bndbox) {
        if (!rangeBox) {
          throw new Error('bounding box rangeBox is missing');
        }
        data = rangeBox;
      } else if (ann.annotationType === AnnotationType.segmentation) {
        if (!rangeBox) {
          throw new Error('segmentation rangeBox is missing');
        }
        if (!segmentationBitmapEncoded) {
          throw new Error('segmentation bitmap is missing');
        }
        data = {
          rangeBox: rangeBox,
          bitMap: segmentationBitmapEncoded,
        };
      }
      return {
        id: String(ann.id),
        defectId: ann.defectId,
        confidence: ann.confidence,
        data: data,
        annotationType: ann.annotationType,
      } as BitMapLabelingAnnotation | BoxLabelingAnnotation;
    });
};

export const bitMapAnnotationToCanvasAnnotation = (
  bitMapAnnotation: BitMapLabelingAnnotation,
  getColorFunc: (defectId: number) => string,
  group?: CanvasAnnotation['group'],
): CanvasAnnotation => {
  const color = getColorFunc(bitMapAnnotation.defectId);
  return {
    id: bitMapAnnotation.id,
    type: CanvasAnnotationType.BitMap,
    defectId: bitMapAnnotation.defectId,
    data: {
      ...bitMapAnnotation.data,
      opacity: 0.5,
      color,
      key: bitMapAnnotation.id,
    },
    selected: bitMapAnnotation.selected,
    hovered: bitMapAnnotation.hovered,
    group,
  };
};

export const canvasToCanvasAnnotation = (id: string, canvas: OffscreenCanvas): CanvasAnnotation => {
  return {
    id,
    type: CanvasAnnotationType.PureCanvas,
    defectId: -1,
    data: {
      x: 0,
      y: 0,
      canvas,
      opacity: 1,
    },
  };
};

export const boxAnnotationToCanvasAnnotation = (
  annotation: BoxLabelingAnnotation,
  getColorFunc: (defectId: number) => string,
  annotationSourceType: AnnotationSourceType,
  isPrediction: boolean = false,
  text?: string,
): CanvasAnnotation => {
  const color = getColorFunc(annotation.defectId);
  const { xmin, xmax, ymin, ymax } = annotation.data;
  return {
    id: annotation.id,
    type: CanvasAnnotationType.Box,
    defectId: annotation.defectId,
    data: {
      x: xmin,
      y: ymin,
      width: xmax - xmin,
      height: ymax - ymin,
      color,
      key: annotation.id,
      dashed: annotationSourceType !== AnnotationSourceType.GroundTruth,
      tag:
        isPrediction && annotation.confidence !== undefined
          ? truncate(text, { length: 20 }) + ' ' + annotation.confidence.toFixed(2)
          : text,
    },
    group: annotationSourceType,
    selected: annotation.selected,
    hovered: annotation.hovered,
  };
};

const toolModeToCanvasMode: { [mode in ToolMode]: CanvasMode } = {
  [ToolMode.Quick]: CanvasMode.IQuickLabeling,
  [ToolMode.Brush]: CanvasMode.ILine,
  [ToolMode.Polygon]: CanvasMode.IPolygon,
  [ToolMode.Polyline]: CanvasMode.IPolyline,
  [ToolMode.Box]: CanvasMode.IBox,
};

export const getCanvasMode = (toolMode?: ToolMode) =>
  // Make the pan mode as the default canvas mode for making annotatione editable in canvas
  toolMode ? toolModeToCanvasMode[toolMode] : CanvasMode.Pan;

export const getAnnotationTypeByLabelType = (labelType?: LabelType | null) => {
  let annotationType: AnnotationType | undefined;
  if (labelType === LabelType.BoundingBox) {
    annotationType = AnnotationType.bndbox;
  } else if (
    labelType === LabelType.Segmentation ||
    labelType === LabelType.SegmentationInstantLearning
  ) {
    annotationType = AnnotationType.segmentation;
  } else if (labelType === LabelType.Classification) {
    annotationType = AnnotationType.classification;
  } else if (labelType === LabelType.AnomalyDetection) {
    annotationType = AnnotationType.anomaly_detection;
  }
  return annotationType;
};

export const convertToServerAnnotation = (
  annotation: BoxLabelingAnnotation | BitMapLabelingAnnotation | ClassificationLabelingAnnotation,
  annotationType: AnnotationType,
): AnnotationWithoutId => {
  const serverAnnotation = {
    defectId: annotation.defectId,
    annotationType: annotationType!,
    dataSchemaVersion: 3,
  } as AnnotationWithoutId;
  if (annotationType === AnnotationType.segmentation) {
    const { bitMap, rangeBox } = annotation.data as SegmentationAnnotationData;
    serverAnnotation.segmentationBitmapEncoded = bitMap;
    serverAnnotation.rangeBox = rangeBox;
  } else if (annotationType === AnnotationType.bndbox) {
    const { xmin, xmax, ymin, ymax } = annotation.data as BoundingBoxAnnotationData;
    serverAnnotation.rangeBox = { xmin, xmax, ymin, ymax };
  }
  return serverAnnotation;
};

export const canvasAnnotationToBoxAnnotation = (
  ann: CanvasAnnotation,
  colorToDefectIdMap: { [color: string]: number },
) => {
  const { x, y, width, height, color } = ann.data as CanvasBoxAnnotation;
  return {
    id: ann.id,
    defectId: colorToDefectIdMap![color],
    selected: ann.selected,
    data: {
      xmin: x,
      ymin: y,
      xmax: x + width,
      ymax: y + height,
    },
  };
};

export const isAboutSameColor = (color1?: RGB, color2?: RGB) =>
  color1 &&
  color2 &&
  Math.abs(color1.b - color2.b) + Math.abs(color1.g - color2.g) + Math.abs(color1.r - color2.r) < 8;

export const canvasToBitMap = (
  canvas: OffscreenCanvas | HTMLCanvasElement,
  defects: Defect[],
): BitMapLabelingAnnotation[] => {
  const canvasWidth = canvas.width;
  const canvasHeight = canvas.height;
  const resizedContext = canvas.getContext('2d')! as
    | OffscreenCanvasRenderingContext2D
    | CanvasRenderingContext2D;
  const annotationData = resizedContext.getImageData(0, 0, canvasWidth, canvasHeight).data;
  return defects.reduce((acc, defect) => {
    const defectRgb = hexToRgb(getDefectColor(defect));
    let hasDefect = false;
    const rangeBox = {
      xmin: canvasWidth,
      xmax: -1,
      ymin: canvasHeight,
      ymax: -1,
    };
    // first pass, create range boxes
    for (let y = 0; y < canvasHeight; ++y) {
      for (let x = 0; x < canvasWidth; ++x) {
        const index = (y * canvasWidth + x) * 4;
        const dataRgb = {
          r: annotationData[index],
          g: annotationData[index + 1],
          b: annotationData[index + 2],
        };
        const alpha = annotationData[index + 3];
        if (alpha && isAboutSameColor(dataRgb, defectRgb)) {
          hasDefect = true;
          rangeBox.xmin = Math.min(rangeBox.xmin, x);
          rangeBox.xmax = Math.max(rangeBox.xmax, x);
          rangeBox.ymin = Math.min(rangeBox.ymin, y);
          rangeBox.ymax = Math.max(rangeBox.ymax, y);
        }
      }
    }
    if (!hasDefect) {
      return acc;
    }
    // second pass, generate bit map string
    const { xmin, ymin, xmax, ymax } = rangeBox;
    // instead of directly concatenating a single string, use char array to
    // avoid multiple memory allocations for large bitmap
    const bitMapArr = Array((xmax - xmin + 1) * (ymax - ymin + 1)).fill('0');
    let bitMapIndex = 0;
    for (let y = ymin; y <= ymax; ++y) {
      for (let x = xmin; x <= xmax; ++x) {
        const index = (y * canvasWidth + x) * 4;
        const dataRgb = {
          r: annotationData[index],
          g: annotationData[index + 1],
          b: annotationData[index + 2],
        };
        const alpha = annotationData[index + 3];
        if (alpha && isAboutSameColor(dataRgb, defectRgb)) {
          bitMapArr[bitMapIndex++] = '1';
        } else {
          bitMapArr[bitMapIndex++] = '0';
        }
      }
    }
    const bitMap = runLengthEncode(bitMapArr)!;

    return [
      ...acc,
      {
        id: generateAnnotationId() + defect.id,
        defectId: defect.id,
        data: {
          rangeBox,
          bitMap,
        },
      },
    ];
  }, [] as BitMapLabelingAnnotation[]);
};

export const layerToBitMapAnnotationsAsync = async (
  annotationLayer: Layer | null,
  mediaDimensions?: Dimensions,
  allDefects?: Defect[],
): Promise<BitMapLabelingAnnotation[]> => {
  return new Promise(resolve => {
    // wait for pixelation workers to finish
    waitForPixelationWorkersFinished().then(() => {
      // double rAF to make sure canvas has updated
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          if (annotationLayer && allDefects) {
            const layerCanvas = annotationLayer.getCanvas()._canvas;
            const correctDimensionCanvas = resizeCanvas(layerCanvas, mediaDimensions);
            const annotationBitMapInstances = canvasToBitMap(correctDimensionCanvas, allDefects);
            resolve(annotationBitMapInstances);
          } else {
            resolve([]);
          }
        });
      });
    });
  });
};

export const offscreenCanvasToCanvasAnnotation = (
  { data: canvas, color, id }: Pick<PureCanvasLabelingAnnotation, 'data' | 'color' | 'id'>,
  opacity?: number,
  group?: CanvasAnnotation['group'],
  enableContour?: CanvasAnnotation['enableContour'],
): CanvasAnnotation => {
  return {
    id, // not matters
    type: CanvasAnnotationType.PureCanvas,
    data: {
      x: 0,
      y: 0,
      width: canvas.width,
      height: canvas.height,
      canvas,
      color,
      opacity,
    } as PureCanvasAnnotation,
    group,
    enableContour,
  };
};

export const convertToCanvasAnnotations = (
  labelingType: LabelingType,
  annotations: (BoxLabelingAnnotation | BitMapLabelingAnnotation | PureCanvasLabelingAnnotation)[],
  getDefectColorById: (defectId: number) => string,
  annotationSourceType: AnnotationSourceType = AnnotationSourceType.GroundTruth,
  getDefectNameById: (defectId: number) => string = () => '',
) => {
  let canvasAnnotations: CanvasAnnotation[] = [];
  if (labelingType === LabelingType.DefectSegmentation) {
    canvasAnnotations = annotations.map(annotation =>
      annotation.data instanceof OffscreenCanvas
        ? offscreenCanvasToCanvasAnnotation(annotation as PureCanvasLabelingAnnotation)
        : bitMapAnnotationToCanvasAnnotation(
            annotation as BitMapLabelingAnnotation,
            getDefectColorById,
          ),
    );
  } else if (labelingType === LabelingType.DefectBoundingBox) {
    canvasAnnotations = annotations.map(annotation =>
      boxAnnotationToCanvasAnnotation(
        annotation as BoxLabelingAnnotation,
        getDefectColorById,
        annotationSourceType,
        annotationSourceType === AnnotationSourceType.Prediction, // isPrediction or not,
        getDefectNameById(annotation.defectId),
      ),
    );
  }
  return canvasAnnotations.map(item => ({
    ...item,
    group: annotationSourceType,
  }));
};

export const useHandleCanvasInteractiveEvent = () => {
  const { dispatch } = useLabelingState();
  const [modeCardWarning, setModeCardWarning] = useState(false);
  const [defectCardWarning, setDefectCardWarning] = useState(false);

  const triggerHint = useHintSnackbar();
  const onCanvasInteractiveEvent = useCallback(
    (eventType: CanvasInteractionEvent) => {
      switch (eventType) {
        case CanvasInteractionEvent.NonInteractiveClick: {
          setModeCardWarning(true);
          setTimeout(() => {
            setModeCardWarning(false);
          }, 200);
          break;
        }
        case CanvasInteractionEvent.MissingColor: {
          triggerHint(HintEvents.MissingDefect);
          setDefectCardWarning(true);
          setTimeout(() => {
            setDefectCardWarning(false);
          }, 200);
          break;
        }
        case CanvasInteractionEvent.NoMoreUndo: {
          triggerHint(HintEvents.NoMoreUndo);
          break;
        }
        case CanvasInteractionEvent.NoMoreRedo: {
          triggerHint(HintEvents.NoMoreRedo);
          break;
        }
        case CanvasInteractionEvent.DrawingStarted: {
          dispatch(draft => {
            draft.isDrawing = true;
          });
          break;
        }
        case CanvasInteractionEvent.DrawingEnded: {
          dispatch(draft => {
            draft.isDrawing = false;
          });
          break;
        }
      }
    },
    [dispatch, triggerHint],
  );
  return { modeCardWarning, defectCardWarning, onCanvasInteractiveEvent };
};
