import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';

import { useNewUploadStyles, useUploadStyles } from '../styles';
import FullscreenDialog from '../../FullScreenDialog';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { UploadStage, UploadMediaType } from '../../../../store/uploadState/types';
import { UploadFullscreenProps } from '..';
import {
  SegmentationUploadImageDropFileZone,
  SegmentationUploadMaskDropFileZone,
  SegmentationUploadDefectMapDropFileZone,
} from './SegmentationUploadDropFileZones';
import NewUploadWrapper from '../components/NewUploadWrapper';
import { getUploadStats } from '../../../../store/uploadState/utils';
import { useDialog } from '../../../../components/Layout/components/useDialog';
import { resetUploadState } from '../../../../store/uploadState';
import AdditionalInfo from '../components/AdditionalInfo';
import SegmentationUploadHeader from './SegmentationUploadHeader';
import Row from '../../../Utils/Row';
import WebcamFileSwitchButton from '../../../../components/WebcamFileSwitchButton';
import WebcamCapture from '../components/WebcamCapture';
import QRCodeUploadButton from '@/components/QRCodeUploadButton';
import { ClientFeatures, useFeatureGateEnabled } from '@/hooks/useFeatureGate';

const SegmentationUploadDialogContent: React.FC<{
  mediaCount?: number;
  mediaLimit?: number | null;
  mediaCapacity?: number | null;
  isNewCreditSystem?: boolean;
  hasNoMediaCapacity?: boolean;
  handleDisableEscapeKeyDown?: (disableEscapeKeyDown: boolean) => void;
  onClose?: () => void;
  initialUseWebcam?: boolean;
}> = ({
  mediaCount,
  mediaLimit,
  mediaCapacity,
  isNewCreditSystem,
  hasNoMediaCapacity,
  handleDisableEscapeKeyDown,
  onClose,
  initialUseWebcam = false,
}) => {
  const styles = useUploadStyles();
  const newStyles = useNewUploadStyles();
  const { uploadMediaType } = useTypedSelector(state => state.uploadState);
  const [useWebcam, setUseWebcam] = useState<boolean>(initialUseWebcam);
  const isWebcamEnabled = !useFeatureGateEnabled(ClientFeatures.DisableWebcam);

  return (
    <div className={newStyles.contentContainer}>
      <NewUploadWrapper onClose={onClose}>
        <SegmentationUploadHeader
          mediaCount={mediaCount}
          mediaLimit={mediaLimit}
          isNewCreditSystem={isNewCreditSystem}
        />
        <Typography
          variant="h3"
          component="div"
          className={newStyles.sectionHeaderText}
          gutterBottom
        >
          <strong>{t('Images selection')}</strong>
        </Typography>
        {uploadMediaType === UploadMediaType.SegmentationLabeledMedia ? (
          <Row
            left={
              <div className={newStyles.dropzoneWrapper}>
                <SegmentationUploadImageDropFileZone
                  disabled={hasNoMediaCapacity}
                  fileCapacity={mediaCapacity}
                  fileLimit={mediaLimit}
                  isNewCreditSystem={isNewCreditSystem}
                />
              </div>
            }
            center={
              <div className={newStyles.dropzoneWrapper}>
                <SegmentationUploadMaskDropFileZone
                  disabled={hasNoMediaCapacity}
                  fileCapacity={mediaCapacity}
                  fileLimit={mediaLimit}
                />
              </div>
            }
            right={
              <div className={newStyles.dropzoneWrapper}>
                <SegmentationUploadDefectMapDropFileZone disabled={hasNoMediaCapacity} />
              </div>
            }
          />
        ) : (
          <>
            {/* Drop in zone or Webcam */}
            {useWebcam && isWebcamEnabled ? (
              <WebcamCapture
                disabled={hasNoMediaCapacity}
                fileCapacity={mediaCapacity}
                fileLimit={mediaLimit}
                isNewCreditSystem={isNewCreditSystem}
              />
            ) : (
              <SegmentationUploadImageDropFileZone
                disabled={hasNoMediaCapacity}
                fileCapacity={mediaCapacity}
                fileLimit={mediaLimit}
                isNewCreditSystem={isNewCreditSystem}
              />
            )}
            <div className={styles.webcamFileSwitchButtonWrapper}>
              {isWebcamEnabled && (
                <WebcamFileSwitchButton
                  useWebcam={useWebcam}
                  onClick={() => setUseWebcam(prev => !prev)}
                />
              )}
              <QRCodeUploadButton />
            </div>
          </>
        )}
        <AdditionalInfo
          disabled={hasNoMediaCapacity}
          handleDisableEscapeKeyDown={handleDisableEscapeKeyDown}
          onClose={onClose}
        />
      </NewUploadWrapper>
    </div>
  );
};

const SegmentationUploadDialog: React.FC<UploadFullscreenProps> = props => {
  const { open, onClose, initialUseWebcam } = props;
  const { uploadStage, uploadData } = useTypedSelector(state => state.uploadState);
  const dispatch = useDispatch();
  const { showConfirmationDialog } = useDialog();
  const { retryableCount } = getUploadStats(uploadData);
  const [disableEscapeKeyDown, setDisableEscapeKeyDown] = useState(false);

  const onDialogClose = useCallback(() => {
    let confirmMessage: string | undefined;
    if (uploadStage === UploadStage.NotStarted && uploadData.length) {
      confirmMessage = t('There are still media not uploaded');
    } else if (uploadStage === UploadStage.UploadFulfilledWithFailure && retryableCount > 0) {
      confirmMessage = t('There are media failed to upload');
    }
    if (confirmMessage) {
      showConfirmationDialog({
        title: t('Are you sure you want to leave'),
        content: confirmMessage,
        onConfirm: () => {
          onClose();
          dispatch(resetUploadState());
        },
        confirmText: t('Leave'),
        color: 'primary',
      });
    } else {
      onClose();
      dispatch(resetUploadState());
    }
  }, [dispatch, onClose, retryableCount, showConfirmationDialog, uploadData.length, uploadStage]);

  return (
    <FullscreenDialog
      open={open}
      onClose={uploadStage !== UploadStage.UploadInProgress ? onDialogClose : undefined}
      onMinimize={uploadStage === UploadStage.UploadInProgress ? onClose : undefined}
      coverPendoGuide
      disableEscapeKeyDown={disableEscapeKeyDown}
    >
      {open && (
        <SegmentationUploadDialogContent
          handleDisableEscapeKeyDown={disableEscapeKeyDown => {
            setDisableEscapeKeyDown(disableEscapeKeyDown);
          }}
          onClose={onClose}
          initialUseWebcam={initialUseWebcam}
        />
      )}
    </FullscreenDialog>
  );
};

export default SegmentationUploadDialog;
