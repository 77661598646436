import React, { MutableRefObject, useRef, useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { useImmer } from 'use-immer';
import classnames from 'classnames';
import { useSnackbar } from 'notistack';
import { Grid, Box, makeStyles } from '@material-ui/core';
import { useAtom, useSetAtom } from 'jotai';
import { debounce } from 'lodash';

import {
  LabelingType,
  MediaLevelLabel,
  LabelType,
  Annotation,
  MediaStatusType,
} from '@clef/shared/types';
import {
  AnnotationChangeType,
  MediaInteractiveCanvas,
  useWindowEventListener,
  MediaInteractiveCanvasProps,
  useKeyPress,
  AnnotationSourceType,
} from '@clef/client-library';

import CLEF_PATH from '@/constants/path';
import { useCreateDefectMutation, useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useDatasetMediaDetailsQuery } from '@/serverStore/dataset';
import {
  useFastNEasyEnabled,
  useFirstRunExperienceWorkflowAssistantEnabled,
} from '@/hooks/useFeatureGate';
import { useGetProjectModelListQuery } from '@/serverStore/projectModels';

import { ImageEnhancerStateContext } from '@/components/ImageEnhancer/state';
import { isAnnotationsChanged } from '@/components/Labeling/imageLabelingContext';
import { HintSnackbarContextProvider } from '@/components/Labeling/HintSnackbar';
import {
  ToolMode,
  BitMapLabelingAnnotation,
  BoxLabelingAnnotation,
  PureCanvasLabelingAnnotation,
  defaultState as defaultLabelingState,
  LabelingContext,
  LabelingState,
  useLabelingState,
  useColorToDefectIdMap,
} from '@/components/Labeling/labelingState';
import MediaCanvasWrapper from '@/components/Labeling/MediaCanvasWrapper';
import { useLabelingDrawer } from '@/components/Labeling/LabelingDrawer';

import {
  serverAnnotationsToLabelingAnnotations,
  canvasAnnotationToBoxAnnotation,
  layerToBitMapAnnotationsAsync,
} from '@/components/Labeling/utils';

import { getLabelingType } from '@/utils/labeling_utils';

import { useDefectSelector } from '@/store/defectState/actions';
import { useCurrentProjectModelInfoQuery } from '@/serverStore/projectModels';
import {
  useSegmentationPredictionLabelingAnnotations,
  detectDuplicateAnnotations,
} from '@/pages/DataBrowser/utils';

import {
  showGroundTruthAtom,
  showPredictionAtom,
  showHeatmapAtom,
} from '@/uiStates/mediaDetails/labelToggles';
import {
  rightDrawerTypeAtom,
  isLabelModeAtom,
  currentToolModeAtom,
  annotationInstanceAtom,
  mediaStatesMapAtom,
  initialMediaStatesAtom,
  useCurrentMediaStates,
  useSaveAnnotations,
  // RightDrawerType,
  labelUpdateStateAtom,
  LabelUpdateState,
  resetMediaDetailsAtomsAtom,
  selectedDefectAtom,
  datasetSearchParamsAtom,
  toggleOnModelAssistLabelingAtom,
} from '@/uiStates/mediaDetails/pageUIStates';
import {
  imageEnhancerStatesAtom,
  resetImageEnhancerStatesAtom,
} from '@/uiStates/mediaDetails/imageEnhancerStates';

import Navigation from './Navigation';
import Toolbar from './Toolbar';
import DetailsDrawer from './DetailsDrawer';
import { getAnomalyDetectionPredictionAnnotations } from '@/utils';

const drawerWidth = 350;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
    position: 'relative',
    backgroundColor: theme.palette.grey[300],
  },
  toolbar: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 0,
  },
  main: {
    display: 'flex',
    flex: 1,
  },
  canvasWrapper: {
    flex: 1,
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 0,
  },
  canvasWrapperShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  detailsData: {
    padding: theme.spacing(6),
  },
}));

const MediaDetailsInternal: React.FC = () => {
  const styles = useStyles();
  const { data: selectedProject } = useGetSelectedProjectQuery();
  const { datasetId, labelType } = selectedProject ?? {};
  const defects = useDefectSelector();
  const history = useHistory();
  const { mediaId } = useParams<{ mediaId: string }>();

  const [rightDrawerType] = useAtom(rightDrawerTypeAtom);
  const [isLabelMode, setIsLabelMode] = useAtom(isLabelModeAtom);
  const [currentToolMode] = useAtom(currentToolModeAtom);
  const [selectedDefect] = useAtom(selectedDefectAtom);
  const [annotationInstance] = useAtom(annotationInstanceAtom);
  const setInitialMediaStates = useSetAtom(initialMediaStatesAtom);
  const [mediaStatesMap, setMediaStatesMap] = useAtom(mediaStatesMapAtom);
  const setLabelUpdateState = useSetAtom(labelUpdateStateAtom);
  const resetMediaDetailsAtoms = useSetAtom(resetMediaDetailsAtomsAtom);
  const currentMediaStates = useCurrentMediaStates();
  const [imageEnhancerStates] = useAtom(imageEnhancerStatesAtom);
  const resetImageEnhancerStates = useSetAtom(resetImageEnhancerStatesAtom);
  const [datasetSearchParams] = useAtom(datasetSearchParamsAtom);
  const [toggleOnModelAssistLabeling] = useAtom(toggleOnModelAssistLabelingAtom);

  const createDefectApi = useCreateDefectMutation();

  // useEffect(() => {
  //   // for classification project, inits the right drawer to information, it has no labels
  //   if (labelType === LabelType.Classification) {
  //     setRightDrawerType(RightDrawerType.Information);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const { id: currentModelId, confidence } = useCurrentProjectModelInfoQuery();

  // media details
  const { data: mediaDetails } = useDatasetMediaDetailsQuery({
    datasetId,
    mediaId: annotationInstance?.mediaId ?? parseInt(mediaId),
    modelId: currentModelId,
  });

  const fastNEasyTrainModelEnabled = useFastNEasyEnabled();
  const firstRunExperienceWorkflowAssistantEnabled =
    useFirstRunExperienceWorkflowAssistantEnabled();
  const { data: models } = useGetProjectModelListQuery(firstRunExperienceWorkflowAssistantEnabled);

  const [showGroundTruth] = useAtom(showGroundTruthAtom);
  const [showPrediction, setShowPrediction] = useAtom(showPredictionAtom);
  const [showHeatmap] = useAtom(showHeatmapAtom);

  const mediaCanvasRef: MutableRefObject<MediaInteractiveCanvas | null> = useRef(null);

  const [imageEnhancerState, dispatchImageEnhancerState] = useImmer(imageEnhancerStates);

  // initialize labeling type
  const {
    state: { labelingType, isCreatingDefect, isDrawing, toolMode },
    dispatch: dispatchLabelingState,
  } = useLabelingState();
  const isClassification = labelingType === LabelingType.DefectClassification;
  useEffect(() => {
    dispatchLabelingState(draft => {
      draft.labelingType = getLabelingType(labelType, mediaDetails?.label);
    });
  }, [dispatchLabelingState, labelType, mediaDetails]);

  // initialize annotations
  const confidenceThreshold = confidence ?? 0;
  const segPredictionAnnotations = useSegmentationPredictionLabelingAnnotations(
    mediaDetails?.predictionLabel?.segImgPath || '',
    confidenceThreshold,
  );

  useEffect(() => {
    if (!mediaDetails) {
      return;
    }
    const newPredictionAnnotations = serverAnnotationsToLabelingAnnotations(
      labelType === LabelType.AnomalyDetection
        ? (getAnomalyDetectionPredictionAnnotations(
            mediaDetails.predictionLabel?.annotations as Annotation[],
            confidenceThreshold,
          ) as Annotation[])
        : (mediaDetails.predictionLabel?.annotations as Annotation[]),
      annotation => isClassification || (annotation?.confidence ?? 0) >= confidenceThreshold,
    );
    const isApprovedMedia =
      mediaDetails.mediaStatus && MediaStatusType.Approved === mediaDetails.mediaStatus;
    const newAnnotations = isApprovedMedia
      ? serverAnnotationsToLabelingAnnotations(mediaDetails.label?.annotations as Annotation[]) ??
        []
      : [];

    const initialized = !!mediaStatesMap[mediaDetails.id];
    const _mediaStates = { ...mediaStatesMap[mediaDetails.id] };
    _mediaStates.mediaDetails = mediaDetails;
    if (fastNEasyTrainModelEnabled) {
      _mediaStates.predictionAnnotations =
        labelingType === LabelingType.DefectSegmentation
          ? segPredictionAnnotations
          : newPredictionAnnotations;
    } else {
      _mediaStates.predictionAnnotations = undefined;
    }
    _mediaStates.predictionMediaLevelLabel = mediaDetails?.predictionLabel?.mediaLevelLabel;
    // in case media details is refreshed during drawing, e.g. after set metadata,
    // we do not overwrite annotations etc.
    if (!initialized || !isLabelMode) {
      _mediaStates.annotations = newAnnotations;
      _mediaStates.mediaLevelLabel = _mediaStates.annotations.length
        ? MediaLevelLabel.NG
        : mediaDetails?.label?.mediaLevelLabel;
    }
    // update hovered annotations
    if (annotationInstance) {
      _mediaStates.annotations.forEach(ann => {
        const shouldHighlight = ann.id === String(annotationInstance.groundTruthAnnotation?.id);
        ann.hovered = shouldHighlight;
      });
      _mediaStates.predictionAnnotations?.forEach(ann => {
        const shouldHighlight = ann.id === String(annotationInstance.predictionAnnotation?.id);
        ann.hovered = shouldHighlight;
      });
    }
    setInitialMediaStates(_mediaStates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mediaDetails,
    confidenceThreshold,
    fastNEasyTrainModelEnabled,
    isClassification,
    isLabelMode,
    labelingType,
    segPredictionAnnotations,
    annotationInstance?.mediaId,
    mediaStatesMap,
    setInitialMediaStates,
  ]);

  // refresh mediaCanvas if defect is deleted, Unfiltered predict annotations
  const allDefects = useDefectSelector();
  useEffect(() => {
    mediaCanvasRef.current?.setAnnotations(
      prev =>
        prev.filter(annotation =>
          annotation.group === AnnotationSourceType.Prediction ||
          annotation.group === AnnotationSourceType.Heatmap
            ? true
            : allDefects.find(
                defect =>
                  defect.id === annotation.defectId || defect.color === annotation.data.color,
              ),
        ),
      AnnotationChangeType.Reset,
    );
  }, [allDefects]);

  const saveAnnotations = useSaveAnnotations();
  const { enqueueSnackbar } = useSnackbar();

  // initialize isLabelMode and toolMode
  useEffect(() => {
    if (labelType === LabelType.BoundingBox || labelType === LabelType.Segmentation) {
      if (currentToolMode !== null) {
        // it comes from page switch
        if (currentToolMode !== undefined) {
          setIsLabelMode(true);
        }
        dispatchLabelingState(draft => {
          draft.toolMode = currentToolMode as ToolMode | undefined;
        });
      } else if (models?.length === 0) {
        // If project has no trained model, auto swtich to label mode
        setIsLabelMode(true);
        dispatchLabelingState(draft => {
          if (labelingType === LabelingType.DefectBoundingBox) {
            // bounding box has only one tool mode, select it by default for label mode
            draft.toolMode = ToolMode.Box;
          }
          if (labelingType === LabelingType.DefectSegmentation) {
            draft.toolMode = ToolMode.Brush;
          }
        });
      }
    }
    if (labelType === LabelType.Classification) {
      setIsLabelMode(false);
    }
  }, [models, labelType, dispatchLabelingState, labelingType, setIsLabelMode, currentToolMode]);

  useEffect(() => {
    if (labelType === LabelType.Classification) {
      // for classification prdiction is always shown at the left bottom corner
      setShowPrediction(true);
    }
  }, [labelType, setShowPrediction]);

  const firstDefect = useMemo(() => {
    return defects[0];
  }, [defects]);

  useEffect(() => {
    // If feature toggle is enabled and there is at least one defect, auto select the defect
    if (labelType === LabelType.BoundingBox || labelType === LabelType.Segmentation) {
      dispatchLabelingState(draft => {
        draft.selectedDefect = selectedDefect || firstDefect;
      });
    }
  }, [firstDefect, dispatchLabelingState, labelType, selectedDefect]);

  const handleClose = useCallback(async () => {
    setIsLabelMode(false);
    dispatchLabelingState(draft => {
      draft.toolMode = undefined;
    });

    // only save for label mode because switching to view mode already triggered save
    // if creating defect, do not save the annotations based on the assumption that there will be at most one annotation
    // under this mode
    if (Object.keys(mediaStatesMap).length > 0 && !isCreatingDefect) {
      await saveAnnotations();
    }
    resetMediaDetailsAtoms(true);
    resetImageEnhancerStates(true);
  }, [
    dispatchLabelingState,
    isCreatingDefect,
    mediaStatesMap,
    resetImageEnhancerStates,
    resetMediaDetailsAtoms,
    saveAnnotations,
    setIsLabelMode,
  ]);

  const handleBeforeUnload = useCallback(
    (event: WindowEventMap['beforeunload']) => {
      // if creating defect, do not save the annotations based on the assumption that there will be at most one
      // annotation under this mode
      if (Object.keys(mediaStatesMap).length > 0 && !isCreatingDefect) {
        // when user clicks cancel we can proceed to save changes
        saveAnnotations();
        event.preventDefault();
        event.returnValue = 'There are unsaved changes. Are you sure want to leave?';
      }
      resetMediaDetailsAtoms(true);
      resetImageEnhancerStates(true);
    },
    [
      isCreatingDefect,
      mediaStatesMap,
      resetImageEnhancerStates,
      resetMediaDetailsAtoms,
      saveAnnotations,
    ],
  );
  useWindowEventListener('beforeunload', handleBeforeUnload);

  // handle with unexpected navigate out
  // TODO: (@tian.lan) to @minyu.mao, if I draw a bbox and switch image, this will also be triggered,
  // causing reset of selected defect.
  // See https://app.asana.com/0/1204554785675703/1207206459862995
  // useEffect(() => {
  //   return () => {
  //     if (Object.keys(mediaStatesMap).length > 0 && !mediaCanvasRef.current) {
  //       handleClose();
  //     }
  //   };
  // }, [mediaCanvasRef, mediaStatesMap, handleClose]);

  const updateMediaLevelLabel = useCallback(
    (mediaLevelLabel: MediaLevelLabel | undefined) => {
      const mediaId = mediaDetails?.id ?? -1;
      const mediaStates = currentMediaStates ?? {};
      mediaStates.mediaLevelLabel = mediaLevelLabel;
      if (mediaLevelLabel === MediaLevelLabel.OK) {
        mediaCanvasRef.current?.setAnnotations([], AnnotationChangeType.DeleteAll);
        mediaStates.annotations = [];
      }
      const newMediaStatesMap = {
        ...mediaStatesMap,
        [mediaId]: mediaStates,
      };
      setMediaStatesMap(newMediaStatesMap);
      if (mediaLevelLabel === MediaLevelLabel.OK) {
        enqueueSnackbar(t('Marked as No Class'), {
          variant: 'success',
          autoHideDuration: 3000,
        });
      }
    },
    [currentMediaStates, enqueueSnackbar, mediaDetails?.id, mediaStatesMap, setMediaStatesMap],
  );

  const changeLabelUpdateState = debounce(() => {
    setLabelUpdateState(LabelUpdateState.Updating);
    setTimeout(() => {
      setLabelUpdateState(LabelUpdateState.Updated);
    }, 1000);
  }, 500);

  const onDefectCreate = useCallback(
    async (defectName: string, defectColor: string) => {
      try {
        const newDefect = await createDefectApi.mutateAsync({
          name: defectName.trim(),
          color: defectColor,
        });

        if (allDefects.length === 0) {
          changeLabelUpdateState();
        }

        dispatchLabelingState(draft => {
          draft.selectedDefect = newDefect;
        });

        // Directly backfill the pending annotation with the new defect
        const currMediaId = annotationInstance?.mediaId ?? parseInt(mediaId);
        if (currentMediaStates.annotations) {
          const annotations = currentMediaStates.annotations.map(anno => ({
            ...anno,
            defectId: anno.defectId ? anno.defectId : newDefect.id,
          }));
          const newMediaStatesMap = {
            ...mediaStatesMap,
            [currMediaId]: { ...currentMediaStates, annotations },
          };
          setMediaStatesMap(newMediaStatesMap);
        }

        enqueueSnackbar(
          t('Successfully created the class: {{defectName}}', {
            defectName,
          }),
          {
            variant: 'success',
          },
        );
        return newDefect;
      } catch (err) {
        const e = err as Record<string, any>;
        enqueueSnackbar(
          (e?.body || e)?.message, // this could be an api error or frontend error
          { variant: 'error' },
        );

        return null;

        // TODO (Shuo): show the create defect dialog
      }
    },
    [
      createDefectApi,
      allDefects.length,
      dispatchLabelingState,
      annotationInstance?.mediaId,
      mediaId,
      currentMediaStates,
      enqueueSnackbar,
      changeLabelUpdateState,
      mediaStatesMap,
      setMediaStatesMap,
    ],
  );

  const enableModeSwitch = [
    LabelingType.DefectBoundingBox,
    LabelingType.DefectSegmentation,
  ].includes(labelingType);

  const { drawerOpened, toggleDefectBookDrawer, toggleLabelingInstructionsDrawer } =
    useLabelingDrawer();
  useKeyPress('*', e => {
    if (e.key === 'Escape') {
      if (
        (enableModeSwitch && isLabelMode && isDrawing) ||
        drawerOpened ||
        isCreatingDefect ||
        toggleOnModelAssistLabeling ||
        !!toolMode // If in a tool mode, block escape listener
      ) {
        return;
      }
      handleClose();
      const initialPageIndex =
        datasetSearchParams.sortOptions.offset / datasetSearchParams.sortOptions.limit;
      history.push(`${CLEF_PATH.data.dataBrowser}?initialPageIndex=${initialPageIndex}`);
    }
    if (e.key === 'i') {
      toggleLabelingInstructionsDrawer();
    }
  });

  const colorToDefectIdMap = useColorToDefectIdMap();

  const updateAnnotations: MediaInteractiveCanvasProps['onAnnotationChanged'] = async (
    canvasAnnotations,
    changeType,
    layer,
  ) => {
    // in media details dialog, we use Reset to update canvas annotations.
    // states is already in-sync. no need to handle Reset here.
    // no need to update media states if select
    if (changeType === AnnotationChangeType.Reset || changeType === AnnotationChangeType.Select) {
      return;
    }

    if (labelingType === LabelingType.DefectBoundingBox) {
      const { duplicated, filteredAnnotations } = detectDuplicateAnnotations(canvasAnnotations);

      if (duplicated) {
        enqueueSnackbar(t('Duplicated bounding box detected during labeling.'), {
          variant: 'error',
        });
        mediaCanvasRef.current?.setAnnotations(filteredAnnotations, AnnotationChangeType.Edit);
      }
      const newAnnotations =
        changeType === AnnotationChangeType.DeleteAll
          ? []
          : filteredAnnotations
              .filter(ann => ann.group === AnnotationSourceType.GroundTruth)
              .map(ann => canvasAnnotationToBoxAnnotation(ann, colorToDefectIdMap));
      const mediaId = mediaDetails?.id ?? -1;
      const mediaStates = mediaStatesMap[mediaId] ?? {};
      mediaStates.mediaDetails = mediaDetails;
      mediaStates.annotations = newAnnotations;
      mediaStates.mediaLevelLabel = newAnnotations.length > 0 ? MediaLevelLabel.NG : undefined;
      const newMediaStatesMap = {
        ...mediaStatesMap,
        [mediaId]: mediaStates,
      };
      setMediaStatesMap(newMediaStatesMap);
    } else if (labelingType === LabelingType.DefectSegmentation) {
      const newAnnotations =
        changeType === AnnotationChangeType.DeleteAll
          ? []
          : await layerToBitMapAnnotationsAsync(layer, mediaDetails?.properties!, allDefects);

      const mediaId = mediaDetails?.id ?? -1;
      const mediaStates = mediaStatesMap[mediaId] ?? {};
      mediaStates.mediaDetails = mediaDetails;

      if (
        !!mediaStatesMap[mediaId] &&
        !isAnnotationsChanged(mediaStates.annotations, newAnnotations)
      ) {
        return;
      }

      mediaStates.annotations = newAnnotations;
      mediaStates.mediaLevelLabel = newAnnotations.length > 0 ? MediaLevelLabel.NG : undefined;
      const newMediaStatesMap = {
        ...mediaStatesMap,
        [mediaId]: mediaStates,
      };
      setMediaStatesMap(newMediaStatesMap);
    }
    if (allDefects.length) {
      changeLabelUpdateState();
    }
  };

  const value = useMemo(
    () => ({ state: imageEnhancerState, dispatch: dispatchImageEnhancerState }),
    [imageEnhancerState, dispatchImageEnhancerState],
  );

  return (
    <ImageEnhancerStateContext.Provider value={value}>
      {annotationInstance || mediaId ? (
        <Grid container className={styles.root} direction="column">
          <Navigation onPageBack={handleClose} />
          <Toolbar mediaCanvasRef={mediaCanvasRef} className={styles.toolbar} />
          <Box className={styles.main}>
            <Box
              className={classnames(styles.canvasWrapper, {
                [styles.canvasWrapperShift]: !!rightDrawerType,
              })}
            >
              {currentMediaStates.mediaDetails ? (
                <MediaCanvasWrapper
                  key={currentMediaStates.mediaDetails.id}
                  isLabelMode={isLabelMode}
                  mediaDetails={mediaDetails}
                  annotations={
                    currentMediaStates.annotations as
                      | BitMapLabelingAnnotation[]
                      | BoxLabelingAnnotation[]
                  }
                  predictionAnnotations={
                    currentMediaStates.predictionAnnotations as
                      | PureCanvasLabelingAnnotation[]
                      | BoxLabelingAnnotation[]
                  }
                  mediaLevelLabel={currentMediaStates.mediaLevelLabel}
                  predictionMediaLevelLabel={currentMediaStates.predictionMediaLevelLabel}
                  mediaCanvasRef={mediaCanvasRef}
                  hideGroundTruthLabels={!showGroundTruth}
                  hidePredictionLabels={!showPrediction}
                  showHeatmap={showHeatmap}
                  updateMediaLevelLabel={updateMediaLevelLabel}
                  onAnnotationChanged={updateAnnotations}
                  onDefectCreate={
                    labelType === LabelType.BoundingBox || labelType === LabelType.Segmentation
                      ? onDefectCreate
                      : undefined
                  }
                  hideClassInfo={false}
                  onClickClassInfo={toggleDefectBookDrawer}
                  toolsControlFromOutside
                />
              ) : null}
            </Box>
          </Box>
          <DetailsDrawer mediaCanvasRef={mediaCanvasRef} />
        </Grid>
      ) : null}
    </ImageEnhancerStateContext.Provider>
  );
};

const MediaDetails: React.FC = props => {
  const { data: selectedProject } = useGetSelectedProjectQuery();
  const { labelType } = selectedProject ?? {};
  const [labelingState, dispatchLabelingState] = useImmer<LabelingState>({
    ...defaultLabelingState,
    labelingType: getLabelingType(labelType),
  });
  const value = useMemo(
    () => ({ state: labelingState, dispatch: dispatchLabelingState }),
    [labelingState, dispatchLabelingState],
  );

  return (
    <HintSnackbarContextProvider>
      <LabelingContext.Provider value={value}>
        <MediaDetailsInternal {...props} />
      </LabelingContext.Provider>
    </HintSnackbarContextProvider>
  );
};

export default MediaDetails;
