import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Typography } from '@clef/client-library';
import CLEF_PATH from '@/constants/path';

import { ButtonGroup, MenuItem, MenuList, Popover, Tooltip, Box } from '@material-ui/core';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks';
import { TrainMode, LabelType } from '@clef/shared/types';
import { useDataBrowserStyles } from '../legacyStyles';
import {
  TrainingStateProvider,
  useTrainModel,
  TrainModelState,
  useGotDataForCustomTraining,
} from './state';
import { useLabeledMediaCount } from '../../../hooks/api/useDatasetApi';
import { useDefectSelector } from '../../../store/defectState/actions';

import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { isModelTrainingInProgress } from '../../../store/projectModelInfoState/utils';
import { rootElement } from '../../../utils/dom_utils';
import { useGetProjectModelListQuery } from '@/serverStore/projectModels';
import { getDateNumber } from '../../../utils/time_utils';
import { MIN_CLASSIFICATION_DEFECTS_FOR_FAST_N_EASY_TRAIN } from '../../../constants/model_train';
import HighlightWrapper from '../../../components/WorkflowAssistant/HighlightWrapper';
import { useWorkflowAssistantState } from '../../../components/WorkflowAssistant/state';
import { StepName } from '../../../types/client';
import { useCheckCreditReachLimit, useCurrentSubscription } from '@/hooks/useSubscriptions';
import { useTotalMediaCount } from '../utils';
import { PulseWrapper } from '@clef/client-library';
import { ReachLimitModelDialog } from './ReachLimitModelDialog';
import { useDataBrowserState } from '../dataBrowserState';
import { useModelStatusQuery } from '@/serverStore/projectModels';
import { FlashIcon, TuneIcon } from '@/images/custom_training/icons';
import { ClientFeatures, useFeatureGateEnabled } from '@/hooks/useFeatureGate';

interface TrainModelButtonGroupProps {
  disabled?: boolean;
}

const TrainModelButtonGroup: React.FC<TrainModelButtonGroupProps> = ({ disabled }) => {
  const styles = useDataBrowserStyles();
  const history = useHistory();
  const isCreditReferenceEnabled = !useFeatureGateEnabled(ClientFeatures.DisableCreditReference);
  const defects = useDefectSelector();
  const trainModel = useTrainModel();
  const { state } = useDataBrowserState();
  const { data: totalMediaCount } = useTotalMediaCount({
    ...state,
    appliedFilters: {},
  });
  const [isTriggeringTrain, setIsTriggeringTrain] = useState(false);
  const { id: projectId = 0, datasetId = 0, labelType } = useGetSelectedProjectQuery().data ?? {};
  const { data: projectModels } = useGetProjectModelListQuery();
  const latestModel = useMemo(
    () =>
      projectModels?.sort(
        (a, b) => (getDateNumber(b.createdAt) || 0) - (getDateNumber(a.createdAt) || 0),
      )[0],
    [projectModels],
  );
  const { data: modelStatusRes } = useModelStatusQuery(projectId, latestModel?.id);
  const { status: modelStatus, metricsReady } = modelStatusRes ?? {};
  const popupState = usePopupState({ variant: 'popover', popupId: 'train-with-custom-option' });

  const gotDataFromCustomTraining = useGotDataForCustomTraining();

  const labeledMediaCount = useLabeledMediaCount({ projectId, datasetId }) ?? 0;
  const labeledNGMediaCount = useLabeledMediaCount({ projectId, datasetId }, true) ?? 0;

  const {
    state: { step, hovering, autoHovering },
  } = useWorkflowAssistantState();

  const { checkReachLimitApplyingCost } = useCheckCreditReachLimit();

  const subscription = useCurrentSubscription();
  const [openReachLimitModelDialog, setOpenReachLimitModelDialog] = useState(false);

  const shouldOpenReachLimitDialog = checkReachLimitApplyingCost(totalMediaCount ?? 0);

  const [openTootip, setOpenToolTip] = useState(false);

  const onTrainButtonClick = async (state: TrainModelState) => {
    if (shouldOpenReachLimitDialog) {
      setOpenReachLimitModelDialog(true);
    } else {
      setIsTriggeringTrain(true);
      await trainModel(state.default, TrainMode.Default);
      setIsTriggeringTrain(false);
      return;
    }
  };

  return (
    <TrainingStateProvider>
      {state => {
        const noLimitsPresent =
          !state.default.limits?.minLabeledMedia || !state.default.limits?.maxLabeledMedia;
        const insufficientLabeledMedia =
          state.default.limits?.minLabeledMedia &&
          labeledMediaCount < state.default.limits.minLabeledMedia;
        const tooMuchLabeledMedia =
          state.default.limits?.maxLabeledMedia &&
          labeledMediaCount > state.default.limits.maxLabeledMedia;
        const insufficientLabeledNGMedia = labeledNGMediaCount === 0;
        const isTrainingInProgress = isModelTrainingInProgress(modelStatus, metricsReady);
        const invalidClassificationTrain =
          labelType === LabelType.Classification
            ? defects.length < MIN_CLASSIFICATION_DEFECTS_FOR_FAST_N_EASY_TRAIN
            : false;
        const disableTrainPTR =
          noLimitsPresent ||
          insufficientLabeledMedia ||
          tooMuchLabeledMedia ||
          insufficientLabeledNGMedia ||
          isTriggeringTrain ||
          (!!latestModel && !modelStatus) ||
          invalidClassificationTrain;
        return (
          <>
            <Tooltip
              arrow
              placement="top"
              disableHoverListener={disabled}
              open={openTootip}
              title={
                (!!latestModel && !modelStatus && t('Loading...')) ||
                ((isTriggeringTrain || isTrainingInProgress) &&
                  t('Model training is in progress...')) ||
                (noLimitsPresent &&
                  t('Model architecture did not specify the image limits for training.')) ||
                (insufficientLabeledMedia &&
                  t('Upload and label at least {{count}} images to train a model.', {
                    count: state.default.limits && state.default.limits.minLabeledMedia,
                  })) ||
                (tooMuchLabeledMedia &&
                  t(
                    '{{labeledCount}} labeled media found. Currently we only support {{count}} media.',
                    {
                      labeledCount: labeledMediaCount,
                      count: state.default.limits && state.default.limits.maxLabeledMedia,
                    },
                  )) ||
                (insufficientLabeledNGMedia &&
                  t(
                    'Upload and label at least 1 image with the associated class(es) to train a model.',
                  )) ||
                (invalidClassificationTrain &&
                  t('Classification projects should contain at least two classes created')) ||
                (subscription &&
                  isCreditReferenceEnabled &&
                  t(
                    shouldOpenReachLimitDialog
                      ? 'Training will cost {{price}} credits which exceeds your credit limit.'
                      : 'This training will cost {{price}} credits',
                    { price: totalMediaCount },
                  )) ||
                ''
              }
            >
              <span
                onClick={e => {
                  if (!disabled) {
                    e.stopPropagation();
                    e.preventDefault();
                    setOpenToolTip(false);
                  }
                }}
                onMouseEnter={() => {
                  if (!disabled) {
                    setOpenToolTip(true);
                  }
                }}
                onMouseLeave={() => {
                  if (!disabled) {
                    setOpenToolTip(false);
                  }
                }}
              >
                <HighlightWrapper
                  enabled={(hovering || autoHovering) && step?.stepName === StepName.Train}
                >
                  <PulseWrapper enabled={step?.stepName === StepName.Train}>
                    <ButtonGroup
                      disabled={disabled || disableTrainPTR}
                      variant={'contained'}
                      color="primary"
                    >
                      <Button
                        id={
                          disableTrainPTR
                            ? 'data-browser-train-button-disabled'
                            : 'data-browser-train-button'
                        }
                        onClick={() => onTrainButtonClick(state)}
                        variant={shouldOpenReachLimitDialog ? 'tonal' : undefined}
                      >
                        {t(shouldOpenReachLimitDialog ? 'Upgrade to Train' : 'Train')}
                      </Button>
                      {!shouldOpenReachLimitDialog && (
                        <Button
                          className={styles.customizeTrainDropdownButton}
                          {...bindTrigger(popupState)}
                          id="data-browser-more-train-options-button"
                        >
                          <KeyboardArrowDown />
                        </Button>
                      )}
                    </ButtonGroup>
                  </PulseWrapper>
                </HighlightWrapper>

                <Popover
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  classes={{ root: styles.trainGroupPopover }}
                  container={rootElement}
                  {...bindMenu(popupState)}
                  disableEnforceFocus
                >
                  <MenuList classes={{ root: styles.trainGroupMenuList }}>
                    <Tooltip
                      arrow
                      placement="left-end"
                      title={t(
                        'Train labeled images with settings optimized for speed and accuracy. This is the default train option.',
                      )}
                    >
                      <MenuItem
                        id="data-browser-menu-item-train"
                        classes={{ root: styles.trainMenuItem }}
                        onClick={() => {
                          popupState.close();
                          onTrainButtonClick(state);
                        }}
                      >
                        <Box className={styles.trainMenuItemTitle}>
                          <FlashIcon />
                          <Typography variant="body_bold">{t('Train')}</Typography>
                        </Box>
                      </MenuItem>
                    </Tooltip>
                    <Tooltip
                      arrow
                      placement="left-end"
                      title={t('Configure splits, hyperparameters, transforms, and augmentations.')}
                    >
                      <MenuItem
                        id="data-browser-menu-item-custom-training"
                        classes={{ root: styles.trainMenuItem }}
                        disabled={!gotDataFromCustomTraining}
                        onClick={() => {
                          history.push(CLEF_PATH.data.customTraining);
                        }}
                      >
                        <Box className={styles.trainMenuItemTitle}>
                          <TuneIcon />
                          <Typography variant="body_bold">{t('Custom Training')}</Typography>
                        </Box>
                      </MenuItem>
                    </Tooltip>
                  </MenuList>
                </Popover>
              </span>
            </Tooltip>
            {datasetId && projectId && (
              <ReachLimitModelDialog
                projectId={projectId}
                datasetId={datasetId}
                open={openReachLimitModelDialog}
                onClose={() => {
                  setOpenReachLimitModelDialog(false);
                }}
              />
            )}
          </>
        );
      }}
    </TrainingStateProvider>
  );
};

export default TrainModelButtonGroup;
