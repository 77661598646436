import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import PictorAPI from '@/api/pictor_api';
import { useGetSelectedProjectQuery } from '../projects';
import {} from '@tanstack/react-query';
import { snowflakeQueryKeys } from './queries';
import { useTypedSelector } from '@/hooks/useTypedSelector';

export const useSyncSnowflakeDataMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const currentProject = useGetSelectedProjectQuery().data;
  const orgId = useTypedSelector(state => state.login.user?.orgId)!;
  const userId = useTypedSelector(state => state.login.user?.id)!;
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: {
      stage: string;
      prefix: string;
      create_label_for_classification?: boolean;
    }) =>
      currentProject?.datasetId &&
      PictorAPI.syncSnowflakeData({
        ...params,
        project_id: currentProject.id,
        dataset_id: currentProject.datasetId,
      }),
    onSuccess: () => {
      enqueueSnackbar(
        'Snowflake data sync in progress. Please allow a few minutes for completion.',
        { variant: 'success' },
      );
      queryClient.invalidateQueries(
        snowflakeQueryKeys.syncTaskList(userId, orgId, currentProject?.id),
      );
    },
    onError: (error: Error) => {
      enqueueSnackbar(error.message, { variant: 'error', autoHideDuration: 12000 });
    },
  });
};
