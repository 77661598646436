// Create a new enum when you want to use a new feature toggle.
export enum ServerFeatures {
  CLIJobs = 'cli-jobs',
  FastNEasyWorkerForceArgo = 'mi.fastneasy.force-argo-workers',
  MultiFoldMislabelDetection = 'mi.mislabel-detection.run-on-fastneasy-flow',
  PreventSpinningUpCeleryWorker = 'mi.fastneasy.prevent-spinning-up-celery-worker',
  FastNEasyLargeImageSupport = 'fne-od-large-image-support',
  IntercomIntegration = 'dm.intercom.integration',
}

export enum ClientFeatures {
  SiteSwitch = 'site_switch_do_not_touch',
  PaulExportInternalAction = 'paul-pascal-export-internal-action',
  MingruiAnomalyDetection = 'mingrui-anomaly-detection',
  CristobalMedtronicDisableCloudDevice = 'cristobal-medtronic-disable-cloud-device',
  CristobalMedtronicDisableSupportCenter = 'cristobal-medtronic-disable-support-center',
  MIActionableErrorAnalysis = 'mi-actionable-error-analysis',
  J4679JunjieOnpremUiFeatures = 'j4679-junjie-onprem-ui-features',
  ClassificationGradcam = 'mi-lan-4022-gradcam',
  GreenfieldAppDownloadEnabled = 'GreenfieldAppDownloadEnabled',
  SCAppsEnabled = 'SCAppsEnabled',
  EnableLEappDownloadLink = 'leapp-vs-winapp-and-clapp',
  SegmentationInstantLearning = 'segmentation-instant-learning',
  NMSThresholdAdvTrain = 'nms-threshold-advance-train',
  EnableInternalSettings = 'internal-settings',
  EnableLanguageSwitch = 'language-switch',
  InstantLearningPostprocessing = 'dm.instant-learning.post-processing',
  QuickLabelingExp = 'quick-labeling-exp',
  ShouldAllowDownloadingBundle = 'new-edge-bundle-download-page',
  AdvancedUsageBasedPricing = 'advanced-usage-based-pricing',
  StreamYardIntegration = 'dm.streamyard.oct.3',
  ModelRunnerReady = 'edge.model-runner-ready',

  // Feature related to Snowflake and Pure Storage
  DisableSubscription = 'disable.subscription', // Subscription and billing
  DisableUsage = 'disable.usage', // Show usage
  DisableMemberManagement = 'disable.member-management', // Member management
  DisableApiKey = 'disable.api-key', // API key
  DisableShowReleaseVersion = 'disable.showing-release-version',
  SnowflakeSync = 'snowflake-sync',
  ShowGpuTimer = 'show-gpu-timer',
  SnowflakeModelRunner = 'snowflake-model-runner',
  SnowflakeIntroVideo = 'snowflake-intro-video',
  SnowflakePythonSDK = 'snowflake-python-sdk',
  // In snowflake, user are already authenticated before redirected to LandingLens,
  // there is no need for us to manage any authentication, this means
  // - no login flow, directly set login state to true
  // - no logout flow, user is always logged in
  // - no switching organizations
  SkipAuthenticationFlows = 'skip-authentication-flows',

  // Move from useFeatureGate.tsx function useIsFeatureEnabledAndMayHideForSnowflake
  DisableLoadSampleDataButton = 'disable.load-sample-data-button',
  DisableExamplesPage = 'disable.examples-page',
  DisableVisualPrompting = 'disable.visual-prompting',
  DisableApiCode = 'disable.api-code',
  DisableQrCode = 'disable.qr-code', // mobile upload qrcode
  DisableTrainingLogs = 'disable.training-logs',
  DisableWebcam = 'disable.webcam',
  DisableMobileInference = 'disable.mobile-inference', // mobile inference qrcode
  DisableCreditReference = 'disable.credit-reference',
}
