import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Button, IconButton, ToggleButton, useDebounce } from '@clef/client-library';
import React, { useEffect, useMemo, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import SnowflakePathTreeView, { findNode } from '../TreeView/SnowflakePathTreeView';
import { Autocomplete } from '@material-ui/lab';
import {
  snowflakeQueryKeys,
  useGetSnowflakeDatabaseQuery,
  useGetSnowflakeFolderPathListQuery,
  useGetSnowflakeSchemaQuery,
  useGetSnowflakeStageQuery,
  useSyncSnowflakeDataMutation,
} from '@/serverStore/snowflake';
import { isExternalStage } from '@/api/pictor_api';
import { useSnackbar } from 'notistack';
import { useTypedSelector } from '@/hooks/useTypedSelector';
import { queryClient } from '@/serverStore';
import LoadingProgress from '@/pages/model_iteration/componentsV2/LoadingProgress';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

const SYNC_FOLDER_MEDIA_NUMBER_MAX_LIMIT = 10000;

export interface IProps {
  open: boolean;
  onClose: () => void;
  showClassificationToggle?: boolean;
}

const useStyles = makeStyles(theme => ({
  dialogContentRoot: {
    padding: theme.spacing(0, 6, 6, 6),
  },
  textFields: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    gap: theme.spacing(3),
  },
  schemaTextField: {
    width: '320px',
  },
  prefixTextField: {
    width: '100%',
    '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: theme.palette.grey[100],
      cursor: 'not-allowed',
    },
  },
  label: {
    paddingTop: theme.spacing(2),
    color: theme.palette.grey[700],
    fontWeight: 500,
  },
  disabledLabel: {
    paddingTop: theme.spacing(2),
    color: theme.palette.grey[400],
    fontWeight: 500,
  },
  dialogPrompt: {
    fontSize: 14,
    fontWeight: 400,
  },
  copyToClipboardBtn: {
    backgroundColor: theme.palette.grey[200], // Change to your desired background color
    '&:hover': {
      backgroundColor: theme.palette.grey[300], // Change to your desired hover background color
    },
    position: 'absolute',
    top: 4,
    right: 4,
    padding: 4,
  },
  selectInput: {
    padding: theme.spacing(2, 3),
  },
  copyIcon: {
    width: 20,
    height: 20,
  },
  treeViewContainer: {
    overflow: 'auto',
    minHeight: 120,
    maxHeight: 160,
    width: '100%',
    borderColor: theme.palette.grey[300],
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 8,
    padding: theme.spacing(2),
  },
  commonAutocomplete: {
    flex: 1,
  },
  selectedFolderPath: {
    paddingLeft: 4,
    color: theme.palette.greyModern[500],
  },
  selectedFolderPathContainer: {
    wordWrap: 'break-word',
  },
  infoBlock: {
    backgroundColor: theme.palette.blue[50],
    padding: theme.spacing(2),
    borderRadius: 5,
  },
  codeBlock: {
    backgroundColor: theme.palette.greyBlue[100],
    padding: theme.spacing(2),
    borderRadius: 5,
    overflow: 'scroll',
    position: 'relative',
    width: '100%',
  },
  codeText: {
    display: 'inline',
    fontSize: 12,
    whiteSpace: 'nowrap',
  },
  highlightCodeText: {
    color: theme.palette.orange[600],
  },
  checkIcon: {
    color: theme.palette.green[500],
    width: 20,
    height: 20,
  },
  permissionCheckSuccessText: {
    color: theme.palette.green[900],
    marginLeft: theme.spacing(1),
    fontWeight: 500,
  },
  promptText: {
    fontSize: 14,
    color: theme.palette.greyModern[600],
  },
  toggleButton: {
    padding: theme.spacing(0.5, 1),
    width: 'auto',
    height: 'auto',
    minWidth: 32,
  },
}));

type CodeHighlighterProps = {
  children: any;
  commandToCopy: string;
};

const CodeHighlighter = (props: CodeHighlighterProps) => {
  const { children, commandToCopy } = props;
  const styles = useStyles();

  const copyToClipboard = useCopyToClipboard({
    text: commandToCopy, //@TODO
    successMessage: t('Command copied to clipboard.'),
  });

  return (
    <Box
      position="relative"
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      width="100%"
    >
      <Box className={styles.codeBlock}>{children}</Box>
      <IconButton size="small" className={styles.copyToClipboardBtn} onClick={copyToClipboard}>
        <FileCopyOutlinedIcon className={styles.copyIcon} />
      </IconButton>
    </Box>
  );
};

interface StageCommandProps {
  isExternalStage: boolean;
  snowflakeApplicationName: string | undefined;
  database: string | undefined;
  schema: string | undefined;
  stage: string | undefined;
}

const HighlightCodeCommand = ({ text }: { text: string }) => {
  const styles = useStyles();
  return <span className={styles.highlightCodeText}>{text}</span>;
};

const StageCommand = (props: StageCommandProps) => {
  const { isExternalStage, snowflakeApplicationName, database, schema, stage } = props;
  const styles = useStyles();
  const applicationName = snowflakeApplicationName ?? '<THIS APPLICATION NAME>';
  if (!database) {
    return null;
  }

  return (
    <Box minWidth={600}>
      {database && (
        <Box>
          <Typography className={styles.codeText}>
            GRANT USAGE ON DATABASE <HighlightCodeCommand text={database} /> TO APPLICATION{' '}
            <HighlightCodeCommand text={applicationName} />;
          </Typography>
        </Box>
      )}
      {database && schema && (
        <Box>
          <Typography className={styles.codeText}>
            GRANT USAGE ON SCHEMA <HighlightCodeCommand text={database} />.
            <HighlightCodeCommand text={schema} /> TO APPLICATION{' '}
            <HighlightCodeCommand text={applicationName} />;
          </Typography>
        </Box>
      )}
      {database && schema && stage && (
        <Box>
          <Typography display="inline" className={styles.codeText}>
            GRANT {isExternalStage ? 'USAGE' : 'READ'} ON STAGE{' '}
            <HighlightCodeCommand text={database} />.
            <HighlightCodeCommand text={schema} />.<HighlightCodeCommand text={stage} /> TO
            APPLICATION <HighlightCodeCommand text={applicationName} />;
          </Typography>
        </Box>
      )}
    </Box>
  );
};
const getStageCommand = (params: StageCommandProps) => {
  const { isExternalStage, snowflakeApplicationName, database, schema, stage } = params;
  const applicationName = snowflakeApplicationName ?? '<THIS APPLICATION NAME>';
  let result = '';
  if (database) {
    result += `GRANT USAGE ON DATABASE ${database} TO APPLICATION ${applicationName};\n`;
  }
  if (database && schema) {
    result += `GRANT USAGE ON SCHEMA ${database}.${schema} TO APPLICATION ${applicationName};\n`;
  }
  if (database && schema && stage) {
    result += `GRANT ${
      isExternalStage ? 'USAGE' : 'READ'
    } ON STAGE ${database}.${schema}.${stage} TO APPLICATION ${applicationName};`;
  }
  return result;
};

type CommonAutocompleteProps = {
  id: string;
  label: string | null;
  options: { title: string }[];
  loading: boolean;
  error: boolean;
  inputValue: string | undefined;
  setInputValue: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const CommonAutocomplete = (props: CommonAutocompleteProps) => {
  const { id, label, options, inputValue, setInputValue, loading, error } = props;
  const styles = useStyles();
  const [value, setValue] = React.useState<string | null>(null);
  const debouncedSetInputValue = useDebounce(setInputValue, 3000);
  return (
    <Autocomplete
      loading={loading}
      classes={{
        root: styles.commonAutocomplete,
      }}
      id={id}
      value={value}
      onChange={(_event: any, newValue: string | null) => {
        setValue(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(_event, newInputValue) => {
        debouncedSetInputValue(newInputValue);
      }}
      freeSolo
      options={options.map(option => option.title)}
      renderInput={params => (
        <TextField
          error={error}
          {...params}
          label={label}
          margin="dense"
          size="small"
          variant="outlined"
        />
      )}
    />
  );
};

enum PermissionStatus {
  INIT = 'init',
  SUCCESS = 'success',
  FAIL = 'fail',
}
export const SnowflakeSyncDataDialog: React.FC<IProps> = ({
  open,
  onClose,
  showClassificationToggle = false,
}) => {
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [database, setDatabase] = useState<string | undefined>();
  const [schema, setSchema] = useState<string | undefined>();
  const [stage, setStage] = useState<string | undefined>();
  const snowflakeEnv = useTypedSelector(state => state.login.user?.snowflakeEnv);
  const { applicationName: snowflakeApplicationName } = snowflakeEnv ?? {};
  const {
    data: databaseOptions,
    isLoading: isDatabaseOptionsLoading,
    error: databaseOptionsError,
  } = useGetSnowflakeDatabaseQuery();
  const {
    data: schemaOptions,
    isLoading: isSchemaOptionsLoading,
    error: schemaOptionsError,
  } = useGetSnowflakeSchemaQuery(database);
  const {
    data: stageOptions,
    isLoading: isStageOptionsLoading,
    error: stageOptionsError,
  } = useGetSnowflakeStageQuery(database, schema);

  const {
    data: folderPathList,
    isLoading: isFolderPathListLoading,
    isError: isFolderPathListError,
  } = useGetSnowflakeFolderPathListQuery(database, schema, stage);

  const [customPrefix, setCustomPrefix] = useState<string>(''); // only used when isFolderPathListError=true

  const [errors, setErrors] = useState<{
    database: boolean;
    schema: boolean;
    stage: boolean;
    prefix: boolean;
    customPrefix: boolean;
  }>({
    database: false,
    schema: false,
    stage: false,
    prefix: false,
    customPrefix: false,
  });
  const [selectedFolderPathNodeId, setSelectedFolderPathNodeId] = React.useState<string>('');
  const [clsToggleBtnOn, setClsToggleBtnOn] = useState<boolean>(false);

  const selectedFolderPath = selectedFolderPathNodeId; // backend has processed id = full folder path
  const selectedFolderPathNode = useMemo(() => {
    if (!folderPathList) {
      return undefined;
    }
    return findNode(folderPathList, selectedFolderPathNodeId || '');
  }, [folderPathList, selectedFolderPathNodeId]);
  const selectedFolderPathNodeMediaNum = selectedFolderPathNode?.num_medias;
  const folderFilesReachLimit =
    selectedFolderPathNodeMediaNum !== undefined &&
    selectedFolderPathNodeMediaNum > SYNC_FOLDER_MEDIA_NUMBER_MAX_LIMIT;

  const selectedStage = stageOptions?.find(stageOption => stageOption.stage === stage);
  const syncMutation = useSyncSnowflakeDataMutation();

  const isEmptyStr = (strToCheck: string | undefined) => {
    return !strToCheck || strToCheck.trim().length === 0;
  };
  const isDatabaseEmpty = isEmptyStr(database);
  const isSchemaEmpty = isEmptyStr(schema);
  const isStageEmpty = isEmptyStr(stage);
  const isFullStageSelected = !isDatabaseEmpty && !isSchemaEmpty && !isStageEmpty;
  const showCustomPrefixTextfield =
    !isFullStageSelected || (!folderPathList && !isFolderPathListLoading && isFolderPathListError);

  useEffect(() => {
    setSelectedFolderPathNodeId('');
  }, [database, schema, stage]);

  const [permissionStatus, setPermissionStatus] = useState(PermissionStatus.INIT);
  const [permissionStatusClicked, setRefreshPermissionStatusClicked] = useState(false);

  useEffect(() => {
    if (
      !databaseOptionsError &&
      !schemaOptionsError &&
      !stageOptionsError &&
      !isFolderPathListError
    ) {
      setPermissionStatus(PermissionStatus.SUCCESS);
    } else {
      setPermissionStatus(PermissionStatus.FAIL);
    }
  }, [databaseOptionsError, stageOptionsError, schemaOptionsError, isFolderPathListError]);

  const onSync = () => {
    if (!isFullStageSelected) {
      setErrors({
        database: isDatabaseEmpty,
        schema: isSchemaEmpty,
        stage: isStageEmpty,
        prefix: false,
        customPrefix: false,
      });
      return;
    }
    if (folderFilesReachLimit) {
      enqueueSnackbar(
        t(`Folder {{folderPath}} has {{numMedias}} images, reaching max limit {{upperLimit}}`, {
          folderPath: selectedFolderPath,
          numMedias: selectedFolderPathNodeMediaNum,
          upperLimit: SYNC_FOLDER_MEDIA_NUMBER_MAX_LIMIT,
        }),
        { variant: 'error' },
      );
      return;
    }
    const fullStage = `${database}.${schema}.${stage}`;
    syncMutation.mutate(
      {
        stage: fullStage,
        prefix: showCustomPrefixTextfield ? customPrefix : selectedFolderPath ?? '',
        ...(clsToggleBtnOn && { create_label_for_classification: clsToggleBtnOn }),
      },
      {
        onSuccess: () => {
          onClose();
        },
      },
    );
  };

  const renderPermissionPromptInfoBlock = () => {
    return (
      <Box
        className={styles.infoBlock}
        marginBottom={2}
        paddingBottom={4}
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
      >
        <Typography>
          {t(
            'It seems you have not yet granted read permission to this stage. Please {{boldText}} to grant permission to move forward. After running, please refresh permission status.',
            {
              boldText: <strong>{t('run the below Snowflake SQL')}</strong>,
            },
          )}
        </Typography>
      </Box>
    );
  };

  const renderFolderTree = () => {
    return (
      <>
        {folderPathList && (
          <Box marginBottom={4} display={'flex'} className={styles.treeViewContainer}>
            <SnowflakePathTreeView
              data={folderPathList}
              selected={selectedFolderPathNodeId ?? ''}
              setSelected={setSelectedFolderPathNodeId}
            />
          </Box>
        )}
      </>
    );
  };

  const renderCustomizedPrefixTextField = () => {
    return (
      <Box className={styles.textFields}>
        <TextField
          className={styles.prefixTextField}
          placeholder="/Example/Path/To/Folder"
          required
          disabled={!isFullStageSelected}
          error={errors.customPrefix}
          value={customPrefix}
          size="small"
          margin="dense"
          onChange={e => {
            errors.prefix && setErrors(_errors => ({ ..._errors, prefix: false }));
            setCustomPrefix(e.target.value);
          }}
          variant="outlined"
        />
      </Box>
    );
  };

  const orgId = useTypedSelector(state => state.login.user?.orgId)!;
  const userId = useTypedSelector(state => state.login.user?.id)!;

  const onRefresh = () => {
    setRefreshPermissionStatusClicked(true);
    queryClient.resetQueries(snowflakeQueryKeys.databaseList(userId, orgId));
    database && queryClient.resetQueries(snowflakeQueryKeys.schemaList(userId, orgId, database));
    database &&
      schema &&
      queryClient.resetQueries(snowflakeQueryKeys.stageList(userId, orgId, database, schema));
    database &&
      schema &&
      stage &&
      queryClient.resetQueries(
        snowflakeQueryKeys.folderPathList(userId, orgId, database, schema, stage),
      );
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={open}
      onClose={onClose}
      onClick={e => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
      }}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h3">{t('Sync Snowflake Data')}</Typography>
          <IconButton
            id="close-snowflake-sync-data-dialog"
            aria-label="close"
            onClick={() => onClose()}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent className={styles.dialogContentRoot}>
        <Box marginBottom={2}>
          <Typography className={styles.dialogPrompt}>
            {t(
              'Please choose the database, schema, stage and folder. We will add the data in the destination folder that is not currently added to your project.',
            )}
          </Typography>
        </Box>
        <Box marginBottom={2}>
          <Typography className={styles.label}>{t('Database')}</Typography>
          <CommonAutocomplete
            id={'database-auto-complete'}
            label={null}
            loading={isDatabaseOptionsLoading}
            error={errors.database}
            inputValue={database}
            setInputValue={setDatabase}
            options={
              databaseOptions?.map(databaseOption => {
                return {
                  title: databaseOption.database,
                };
              }) ?? []
            }
          />
        </Box>
        <Box marginBottom={2}>
          <Typography className={styles.label}>{t('Schema')}</Typography>
          <CommonAutocomplete
            id={'schema-auto-complete'}
            label={null}
            loading={isSchemaOptionsLoading}
            error={errors.schema}
            inputValue={schema}
            setInputValue={setSchema}
            options={
              schemaOptions?.map(schemaOption => {
                return {
                  title: schemaOption.schema,
                };
              }) ?? []
            }
          />
        </Box>
        <Box marginBottom={2}>
          {databaseOptionsError ||
          schemaOptionsError ||
          stageOptionsError ||
          isFolderPathListError ? (
            <Box
              display="flex"
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Typography className={styles.label}>{t('Stage')}</Typography>
              <Button
                id="snowflake-sync-refresh-permission-status"
                variant="text"
                color="primary"
                onClick={() => {
                  onRefresh();
                }}
              >
                {t('Refresh Permission Status')}
              </Button>
            </Box>
          ) : (
            <Typography className={styles.label}>{t('Stage')}</Typography>
          )}
          <CommonAutocomplete
            error={errors.stage}
            loading={isStageOptionsLoading}
            id={'stage-auto-complete'}
            label={null}
            inputValue={stage}
            setInputValue={setStage}
            options={
              stageOptions?.map(stageOption => {
                return {
                  title: stageOption.stage,
                };
              }) ?? []
            }
          />
        </Box>
        {permissionStatusClicked && permissionStatus === PermissionStatus.SUCCESS && (
          <Box display={'flex'} flexDirection={'row'}>
            <CheckCircleRoundedIcon className={styles.checkIcon} />
            <Typography className={styles.permissionCheckSuccessText}>
              {t('Permission granted successfully')}
            </Typography>
          </Box>
        )}
        {(databaseOptionsError ||
          schemaOptionsError ||
          stageOptionsError ||
          isFolderPathListError) &&
          database && (
            <>
              {renderPermissionPromptInfoBlock()}
              <CodeHighlighter
                commandToCopy={getStageCommand({
                  isExternalStage: isExternalStage(selectedStage?.stage_type ?? null),
                  snowflakeApplicationName,
                  database,
                  schema,
                  stage,
                })}
              >
                <StageCommand
                  isExternalStage={isExternalStage(selectedStage?.stage_type ?? null)}
                  snowflakeApplicationName={snowflakeApplicationName}
                  database={database}
                  schema={schema}
                  stage={stage}
                />
              </CodeHighlighter>
            </>
          )}
        <Box
          display="flex"
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          paddingBottom={2}
        >
          <Typography className={isFullStageSelected ? styles.label : styles.disabledLabel}>
            {t('Folder')}
          </Typography>
          {showCustomPrefixTextfield ? (
            <Box />
          ) : (
            <Box>
              {isFolderPathListLoading ? (
                <Box paddingTop={4} paddingRight={2}>
                  <LoadingProgress size={16} />
                </Box>
              ) : (
                <Box paddingTop={2} className={styles.selectedFolderPathContainer}>
                  <Typography>
                    {t('Selected: {{selectedFolderPath}}{{selectedFolderMediaNumber}}', {
                      selectedFolderPath: (
                        <Typography component="span" className={styles.selectedFolderPath}>
                          {selectedFolderPath === '' ? 'None' : selectedFolderPath}
                        </Typography>
                      ),
                      selectedFolderMediaNumber:
                        selectedFolderPathNodeMediaNum === undefined ? null : (
                          <Typography component="span" className={styles.selectedFolderPath}>
                            {t('({{selectedFolderPathNodeMediaNum}} medias)', {
                              selectedFolderPathNodeMediaNum,
                            })}
                          </Typography>
                        ),
                    })}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Box>
        {showClassificationToggle && (
          <Box display="flex" flexDirection={'row'} alignItems={'center'}>
            <Typography className={styles.promptText}>
              {t('Classify images based on folder names')}
            </Typography>
            <ToggleButton
              className={styles.toggleButton}
              isOn={clsToggleBtnOn}
              onToggle={() => {
                setClsToggleBtnOn(!clsToggleBtnOn);
              }}
              id={'cls-folder-toggle-btn'}
            />
          </Box>
        )}
        <Box marginBottom={2}>
          {showCustomPrefixTextfield ? renderCustomizedPrefixTextField() : renderFolderTree()}
        </Box>
        <Box width="100%" padding={1}>
          <Tooltip
            placement="top"
            arrow={true}
            title={t(
              `Folder {{folderPath}} has {{numMedias}} images, reaching max limit {{upperLimit}}`,
              {
                folderPath: selectedFolderPath,
                numMedias: selectedFolderPathNodeMediaNum,
                upperLimit: SYNC_FOLDER_MEDIA_NUMBER_MAX_LIMIT,
              },
            )}
            open={folderFilesReachLimit}
          >
            <Button
              fullWidth={true}
              variant="contained"
              id="confirm-snowflake-sync-data"
              color="primary"
              onClick={() => onSync()}
              disabled={syncMutation.isLoading || folderFilesReachLimit || !isFullStageSelected}
            >
              {syncMutation.isLoading ? t('Syncing...') : t('Sync')}
            </Button>
          </Tooltip>
        </Box>
        <Box width="100%" padding={1}>
          <Button
            fullWidth={true}
            variant="outlined"
            id="cancel-snowflake-sync-data"
            color="default"
            onClick={() => onClose()}
            disabled={syncMutation.isLoading || folderFilesReachLimit}
          >
            {t('Cancel')}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
