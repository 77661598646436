import { Box, CircularProgress, Popover, Tooltip } from '@material-ui/core';
import React, { useMemo } from 'react';
import cx from 'classnames';
import useHeaderStyles from './styles';
import { Typography } from '@clef/client-library';
import SyncIcon from '@material-ui/icons/Sync';
import { useGetSnowflakeSyncTaskList } from '@/serverStore/snowflake';
import { SnowflakeSyncTaskMonitoringResponse, SnowflakeSyncTaskStatus } from '@/api/pictor_api';
import CheckCircleRounded from '@material-ui/icons/CheckCircleRounded';
import { VariableSizeList } from 'react-window';
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import InfoIcon from '@material-ui/icons/Info';
import { formatDistance } from 'date-fns';
import { getDateNumber } from '@clef/shared/utils';
import SnowflakeSyncTaskProgressBar from './SnowflakeSyncTaskProgressBar';

interface SnowflakeSyncIndicatorProps {}

const tooltipText = t('List snowflake sync tasks in last 7 days');

interface VirtualizedSyncTaskListProps {
  data: SnowflakeSyncTaskMonitoringResponse;
}

const rowHeightDefault = 72;
const rowHeightPending = 60;
const rowHeightRunning = 80;
const maxListHeight = 300;
const listWidth = 400;
const taskNameMaxWidth = 280;

const VirtualizedSyncTaskList = (props: VirtualizedSyncTaskListProps) => {
  const { data } = props;
  const dateNow = useMemo(() => new Date(), []);
  const styles = useHeaderStyles();
  const RCWindowItem = ({
    data,
    index,
    style,
  }: {
    data: SnowflakeSyncTaskMonitoringResponse;
    index: number;
    style: any;
  }) => {
    const row = data[index];

    return (
      <div key={`${row.taskId}`} style={style} className={styles.snowflakeSyncTaskListItem}>
        <Box
          height="100%"
          display="flex"
          flexDirection="row"
          justifyContent={'flex-start'}
          alignItems={'center'}
        >
          <Box
            width={96}
            paddingRight={2}
            display="flex"
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'flex-start'}
          >
            <Box
              className={cx(
                styles.snowflakeTaskStatusChip,
                row.status === SnowflakeSyncTaskStatus.FAILURE
                  ? styles.snowflakeTaskStatusFail
                  : row.status === SnowflakeSyncTaskStatus.SUCCESS
                  ? styles.snowflakeTaskStatusSuccess
                  : styles.snowflakeTaskStatusInProgress,
              )}
            >
              <Typography variant="body_small_bold">{row.status}</Typography>
            </Box>
            {row.status === SnowflakeSyncTaskStatus.FAILURE && row.message && (
              <Tooltip title={t(row.message)} arrow={true}>
                <InfoIcon className={styles.snowflakeSyncFailInfoIcon} />
              </Tooltip>
            )}
          </Box>
          <Box display="flex" flexDirection={'column'}>
            <Box width={taskNameMaxWidth} paddingRight={4}>
              <Typography variant="body_small_bold" maxWidth={taskNameMaxWidth}>
                {t('{{stage}}{{separator}}{{prefix}}', {
                  stage: row?.taskSpec?.stage,
                  separator: row?.taskSpec?.prefix ? '/' : '',
                  prefix: row?.taskSpec?.prefix,
                })}
              </Typography>
            </Box>
            {row.tsStarted && (
              <Typography variant="body_small" className={styles.dateDistanceText}>
                {t('Created {{dateDistance}} ago', {
                  dateDistance: formatDistance(getDateNumber(row.tsStarted), dateNow),
                })}
              </Typography>
            )}
            {row?.stats && row.status === SnowflakeSyncTaskStatus.RUNNING && (
              <SnowflakeSyncTaskProgressBar stats={row.stats} />
            )}
            {row?.stats && (
              <Box width="100%" paddingRight={4}>
                <Typography variant="body_small" className={styles.snowflakeSyncTaskStatsText}>
                  {t('{{inserted}} synced{{duplicatedText}}{{failedText}}', {
                    total: row.stats.total,
                    inserted: row.stats.inserted,
                    duplicatedText: row.stats.duplicated
                      ? t(' | {{duplicated}} duplicated', {
                          duplicated: row.stats.duplicated,
                        })
                      : null,
                    failedText: row.stats.failed
                      ? t(' | {{failed}} errors', { failed: row.stats.failed })
                      : null,
                    duplicated: row.stats.duplicated,
                    failed: row.stats.failed,
                  })}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </div>
    );
  };
  const listHeightOriginal = data.reduce((acc, cur) => {
    const { status } = cur;
    if (status === SnowflakeSyncTaskStatus.PENDING) {
      return acc + rowHeightPending;
    } else if (status === SnowflakeSyncTaskStatus.RUNNING) {
      return acc + rowHeightRunning;
    }
    return acc + rowHeightDefault;
  }, 0);
  const listHeight = Math.min(maxListHeight, listHeightOriginal);
  return (
    <VariableSizeList
      width={listWidth}
      height={listHeight}
      itemSize={_index => {
        const { status } = data[_index];
        if (status === SnowflakeSyncTaskStatus.PENDING) {
          return rowHeightPending;
        } else if (status === SnowflakeSyncTaskStatus.RUNNING) {
          return rowHeightRunning;
        }
        return rowHeightDefault;
      }}
      itemData={data}
      itemCount={data.length}
    >
      {RCWindowItem}
    </VariableSizeList>
  );
};

const SnowflakeSyncIndicator: React.FC<SnowflakeSyncIndicatorProps> = () => {
  const classes = useHeaderStyles();
  const { data, isLoading } = useGetSnowflakeSyncTaskList();
  const popupState = usePopupState({
    popupId: 'snowflake-sync-task-menu',
    variant: 'popover',
  });
  const hasSyncInProgress = data?.some(
    task =>
      task.status === SnowflakeSyncTaskStatus.RUNNING ||
      task.status === SnowflakeSyncTaskStatus.PENDING,
  );

  if (!data || isLoading || data.length === 0) {
    return null;
  }

  const renderSyncIcon = () => {
    if (hasSyncInProgress) {
      return (
        <div aria-label="sync in progress">
          <CircularProgress size="small" className={cx(classes.uploadInProgress)} />
          <CircularProgress
            variant="determinate"
            value={100}
            size="small"
            className={cx(classes.uploadBase, 'wip')}
          />
          <SyncIcon
            fontSize="small"
            className={cx(classes.uploadProgressIcon, 'animated')}
            {...bindTrigger(popupState)}
          />
        </div>
      );
    }
    return (
      <div aria-label="sync completed">
        <CircularProgress
          variant="determinate"
          value={100}
          size="small"
          className={cx(classes.uploadBase)}
        />
        <SyncIcon
          fontSize="small"
          className={cx(classes.uploadProgressIcon)}
          {...bindTrigger(popupState)}
        />
        <CheckCircleRounded fontSize="small" className={classes.mediaUploadSuccessBadge} />
      </div>
    );
  };

  return (
    <>
      <Tooltip placement="left" title={tooltipText} arrow={true}>
        <div className={classes.indicatorContainer} data-testid="header-upload-progress">
          {renderSyncIcon()}
        </div>
      </Tooltip>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <VirtualizedSyncTaskList data={data} />
      </Popover>
    </>
  );
};
export default SnowflakeSyncIndicator;
