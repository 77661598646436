import React, { useCallback, useMemo, useState } from 'react';
import { ClassChip, ClassifiedClass, defectColors } from '@clef/client-library';
import {
  Media,
  EdgeMedia,
  MediaDetailsWithPrediction,
  MediaStatusType,
  AnnotationType,
  AnnotationWithoutId,
  MediaLevelLabel,
  LabelSource,
  LabelType,
} from '@clef/shared/types';
import {
  useGetSelectedProjectQuery,
  useGetProjectVersionedDefectsQuery,
} from '@/serverStore/projects';
import {
  useDefectSelector,
  useDefectSelectorWithArchived,
} from '../../../store/defectState/actions';
import {
  getAnomalyDetectionPredictionAnnotations,
  getClassifiedClass,
  getDefectColor,
} from '../../../utils';
import { useSnackbar } from 'notistack';
import { Box, makeStyles, Tooltip } from '@material-ui/core';
import LabelAPI from '../../../api/label_api';
import CreateDefectDialog from '../../DefectBook/DefectList/CreateDefectDialog';
import { useCurrentProjectModelInfoQuery } from '@/serverStore/projectModels';
import { refreshLabeledMediaCount } from '../../../hooks/api/useDatasetApi';
import { useQueryClient } from '@tanstack/react-query';
import { datasetQueryKeys } from '@/serverStore/dataset';
import { SuccessIcon, ErrorIconV2 } from '@/images/data-browser/icons';

export const useClassChipStyles = makeStyles(theme => ({
  toDefect: {
    color: '#659bf3',
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  question: {
    width: '18px',
    height: '18px',
    borderRadius: '18px',
    border: '1px solid #fff',
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
  },
  unknown: {
    color: theme.palette.grey[400],
  },
  classChipTooltip: {
    borderRadius: theme.spacing(1),
    padding: 0,
  },
  tooltipContent: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
}));

export type MediaClassifiedClassProps = {
  media: Media | EdgeMedia;
  mediaDetails?: MediaDetailsWithPrediction;
  versionId?: number;
  enableSetClass?: boolean;
  enableColor?: boolean;
  isPrediction?: boolean;
  showGroundTruth?: boolean;
  style?: React.CSSProperties;
  hideClassInfo?: boolean;
  onClickClassInfo?: (defectId: number) => void;
  // For AD Project only
  threshold?: number;
};

export type StatusIndicator = {
  el: React.ReactNode;
  type: 'question' | 'success' | 'error';
  tooltip?: React.ReactNode;
} | null;

/**
 *  This Class is used for both Classification and Anomaly Detection projects
 */
const MediaClassifiedClass: React.FC<MediaClassifiedClassProps> = ({
  threshold,
  mediaDetails,
  versionId,
  enableSetClass,
  enableColor,
  isPrediction = false,
  style,
  hideClassInfo,
  onClickClassInfo,
}) => {
  const styles = useClassChipStyles();
  const { id: projectId, datasetId, labelType } = useGetSelectedProjectQuery().data ?? {};
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const datasetDefects = useDefectSelector() ?? [];

  const { data: versionedDefects } = useGetProjectVersionedDefectsQuery(versionId);
  const defects = versionId ? versionedDefects : datasetDefects;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const datasetDefectsWithArchived = useDefectSelectorWithArchived() ?? [];
  const defectsWithArchived = versionId ? versionedDefects : datasetDefectsWithArchived;

  const annotations = useMemo(() => {
    if (labelType === LabelType.AnomalyDetection && threshold !== undefined) {
      if (!isPrediction) {
        return mediaDetails?.label?.annotations || [];
      }
      const predictionAnnotation = getAnomalyDetectionPredictionAnnotations(
        mediaDetails?.predictionLabel?.annotations,
        threshold,
      );
      return predictionAnnotation;
    } else {
      return (
        (isPrediction
          ? mediaDetails?.predictionLabel?.annotations
          : mediaDetails?.label?.annotations) || []
      );
    }
  }, [
    isPrediction,
    labelType,
    mediaDetails?.label?.annotations,
    mediaDetails?.predictionLabel?.annotations,
    threshold,
  ]);
  const [loading, setLoading] = useState(false);
  const allClassifiedClasses = defects?.map(defect => ({
    id: defect.id,
    name: defect.name,
    color: getDefectColor(defect),
  })) as ClassifiedClass[];

  const classifiedClass = useMemo(() => {
    const chosenDefects = isPrediction ? defectsWithArchived : defects ?? [];
    return getClassifiedClass(annotations, chosenDefects!);
  }, [isPrediction, annotations, defectsWithArchived, defects]);
  const mediaStatus = mediaDetails?.mediaStatus;
  const isInTask = mediaStatus === MediaStatusType.InTask;
  const gtClassifiedClass = useMemo(() => {
    if (isPrediction && isInTask) return undefined;
    return getClassifiedClass(mediaDetails?.label?.annotations || [], defects ?? []);
  }, [defects, isInTask, isPrediction, mediaDetails?.label?.annotations]);
  const { id: currentModelId } = useCurrentProjectModelInfoQuery();

  const [statusIndicator, statusIndicatorTooltip] = useMemo((): [
    StatusIndicator | null,
    React.ReactNode,
  ] => {
    if (!isPrediction) return [null, ''];
    if (!classifiedClass?.id) return [null, ''];

    if (!gtClassifiedClass?.id) {
      return [
        {
          el: <div className={styles.question}>?</div>,
          type: 'question',
        },
        <div key="question">{t('No Ground Truth to compare with')}</div>,
      ];
    }
    if (classifiedClass?.id === gtClassifiedClass?.id) {
      return [
        {
          el: <SuccessIcon />,
          type: 'success',
        },
        <div key="success">
          <span style={{ color: '#34C759' }}>{t('Correct Prediction. ')}</span>
          {t('Ground Truth: {{label}}.', {
            label: gtClassifiedClass?.name || '',
          })}
        </div>,
      ];
    }
    return [
      {
        el: <ErrorIconV2 />,
        type: 'error',
      },
      <div key="error">
        <span style={{ color: '#FF6A60' }}>{t('Incorrect Prediction.')}</span>
        {t(' Ground Truth: {{label}}.', {
          label: gtClassifiedClass?.name || '',
        })}
      </div>,
    ];
  }, [
    classifiedClass?.id,
    gtClassifiedClass?.id,
    gtClassifiedClass?.name,
    isPrediction,
    styles.question,
  ]);

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  const handleSetClass = useCallback(
    async (newClassifiedClass: ClassifiedClass) => {
      const classId = isPrediction ? gtClassifiedClass?.id : classifiedClass?.id;
      if (newClassifiedClass.id === classId || !mediaDetails || !datasetId || !projectId) {
        return;
      }
      setLoading(true);
      try {
        const defectId = newClassifiedClass.id;
        // upload pascal voc
        const serverAnnotation = {
          defectId,
          annotationType:
            labelType === LabelType.AnomalyDetection
              ? AnnotationType.anomaly_detection
              : AnnotationType.classification,
          dataSchemaVersion: 3,
        } as AnnotationWithoutId;
        await LabelAPI.upsertLabels({
          projectId,
          mediaId: mediaDetails!.id,
          annotations: [serverAnnotation],
          mediaLevelLabel: MediaLevelLabel.NG,
          source: LabelSource.DirectLabeling,
          modelId: currentModelId,
        });

        // refresh media details
        queryClient.invalidateQueries(datasetQueryKeys.allStats(projectId));
        queryClient.invalidateQueries(datasetQueryKeys.mediaCount(projectId, datasetId));
        queryClient.invalidateQueries(
          datasetQueryKeys.mediaDetails(datasetId, {
            mediaId: mediaDetails.id,
            modelId: currentModelId,
          }),
        );
        queryClient.invalidateQueries(datasetQueryKeys.modelMetrics(projectId));
        refreshLabeledMediaCount({
          projectId,
          datasetId,
        });
        queryClient.invalidateQueries(datasetQueryKeys.medias(projectId));
        queryClient.invalidateQueries(
          datasetQueryKeys.annotationInstancesCount(projectId, currentModelId),
        );
        enqueueSnackbar(
          t(`Successfully set class to {{className}}`, { className: newClassifiedClass.name }),
          { variant: 'success' },
        );
      } catch (e) {
        enqueueSnackbar((e as Error).message, { variant: 'error' });
      }
      setLoading(false);
    },
    [
      isPrediction,
      gtClassifiedClass?.id,
      classifiedClass?.id,
      mediaDetails,
      datasetId,
      projectId,
      labelType,
      currentModelId,
      queryClient,
      enqueueSnackbar,
    ],
  );

  const classifiedClassOrText = useMemo(() => {
    if (isPrediction) {
      return classifiedClass ?? t('Not predicted');
    }
    if (!isPrediction && isInTask) {
      return t('Select class');
    }
    if (mediaStatus === MediaStatusType.Raw || (!isPrediction && !defects?.length)) {
      return t('Select Class');
    }
    return classifiedClass;
  }, [isPrediction, isInTask, mediaStatus, defects?.length, classifiedClass]);

  const toolTip = useMemo(() => {
    if (statusIndicatorTooltip) return statusIndicatorTooltip;
    if (isPrediction && !classifiedClass) {
      return t('Not in last model run. Please train a new model to see prediction on it');
    }
    if (isInTask) {
      return t('Image is in a labeling task.');
    }
    return '';
  }, [classifiedClass, isInTask, isPrediction, statusIndicatorTooltip]);

  const confidenceScore = useMemo(() => {
    if (labelType === LabelType.Classification && isPrediction) {
      return mediaDetails?.predictionLabel?.mediaLevelScore?.toFixed(2) || '';
    }
    return '';
  }, [isPrediction, labelType, mediaDetails?.predictionLabel?.mediaLevelScore]);

  const [open, setOpen] = useState(false);

  const ClassChipEl = (
    <div data-testid="set-class-trigger">
      {classifiedClassOrText && defects && (
        <ClassChip
          classifiedClass={classifiedClassOrText}
          gtClassifiedClass={gtClassifiedClass}
          loading={loading}
          allClassifiedClasses={allClassifiedClasses}
          onClassChange={enableSetClass && !isInTask ? handleSetClass : undefined}
          enableColor={enableColor}
          dotted={isPrediction}
          openCreateDialog={() => {
            setOpenCreateDialog(true);
          }}
          statusIndicator={statusIndicator}
          confidenceScore={confidenceScore}
          showDisabledGroundTruth={isPrediction && labelType === LabelType.Classification}
          setOpenTooltip={(open: boolean) => {
            setOpen(open);
          }}
          style={{
            borderRadius: '20px',
            borderColor: '#fff',
            borderWidth: '2px',
            borderStyle: isPrediction ? 'dashed' : 'solid',
            ...(style || {}),
          }}
          hideMoreIcon={!classifiedClass && isPrediction}
          showMoreIconWithInTask={isInTask && !isPrediction}
          allowCreateClass={labelType != LabelType.AnomalyDetection}
          hideClassInfo={hideClassInfo}
          onClickClassInfo={onClickClassInfo}
        />
      )}
      {openCreateDialog && (
        <CreateDefectDialog
          onClose={() => setOpenCreateDialog(false)}
          nextDefectIndex={(defects?.length ?? 0) % defectColors.length}
          alertText={t(
            // eslint-disable-next-line max-len
            'You can delete inactive classes before they are used to annotate your dataset. To delete an active defect class, either delete all label instances or delete the labeled images.',
          )}
          onCreateSuccess={defect => {
            if (enableSetClass && !isInTask && !isPrediction && defect.color) {
              handleSetClass({
                id: defect.id,
                name: defect.name,
                color: defect.color,
              });
            }
            setOpenCreateDialog(false);
          }}
        />
      )}
    </div>
  );

  if (!toolTip) return ClassChipEl;
  return (
    <Tooltip
      open={open}
      arrow
      interactive
      title={
        <Box onMouseOver={() => setOpen(true)} onMouseOut={() => setOpen(false)}>
          {toolTip}
        </Box>
      }
      className={styles.classChipTooltip}
    >
      {ClassChipEl}
    </Tooltip>
  );
};

export default MediaClassifiedClass;
