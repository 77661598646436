import React, { useEffect, useRef } from 'react';
import cx from 'classnames';
import { useAtom } from 'jotai';
import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { Button, Typography } from '@clef/client-library';
import {
  DefectColorChip,
  PredictionMatrixData,
} from '@/pages/DataBrowser/ModelPerformance/ConfusionMatrix';
import { modelListFilterOptionsAtom } from '../atoms';
import InfoOutlined from '@material-ui/icons/InfoOutlined';

const usePercentageStyles = makeStyles(theme => ({
  green: {
    color: theme.palette.green[500],
  },
  red: {
    color: theme.palette.red[500],
  },
  grey: {
    color: theme.palette.grey[600],
  },
  textAlignRight: {
    textAlign: 'right',
  },
}));

const Percentage: React.FC<{
  baseline: number;
  candidate: number;
  isCorrectMapping?: boolean;
}> = ({ baseline, candidate, isCorrectMapping }) => {
  const styles = usePercentageStyles();
  if (baseline === 0 && candidate === 0) {
    return <Typography className={cx(styles.textAlignRight, styles.grey)}>--</Typography>;
  }
  if (baseline === 0) {
    return isCorrectMapping ? (
      <Typography className={cx(styles.textAlignRight, styles.grey)}>--</Typography>
    ) : (
      <Typography className={cx(styles.textAlignRight, styles.red)}>{t('New Error')}</Typography>
    );
  } else if (candidate === 0) {
    return isCorrectMapping ? (
      <Typography className={cx(styles.textAlignRight, styles.grey)}>--</Typography>
    ) : (
      <Typography className={cx(styles.textAlignRight, styles.green)}>{t('Fixed')}</Typography>
    );
  }
  const percentage = ((candidate - baseline) / baseline) * 100;
  if (percentage === 0) {
    return <Typography className={cx(styles.textAlignRight, styles.grey)}>0%</Typography>;
  } else {
    const isGreen = (percentage < 0 && !isCorrectMapping) || (percentage > 0 && isCorrectMapping);
    const isRed = (percentage > 0 && !isCorrectMapping) || (percentage < 0 && isCorrectMapping);

    const fixedNumber = `${Math.abs(percentage).toFixed(1)}%`;

    const getTooltipTitle = () => {
      if (isCorrectMapping) {
        return isGreen
          ? t('Candidate has {{fixedNumber}} more correct pixels', { fixedNumber })
          : t('Candidate has {{fixedNumber}} less correct pixels', { fixedNumber });
      } else {
        return isRed
          ? t('Candidate has {{fixedNumber}} more errors', { fixedNumber })
          : t('Candidate has {{fixedNumber}} less errors', { fixedNumber });
      }
    };
    return (
      <Tooltip title={getTooltipTitle()} placement="top">
        <Box>
          <Typography
            className={cx(styles.textAlignRight, {
              [styles.green]: isGreen,
              [styles.red]: isRed,
            })}
          >
            {percentage > 0 ? '+' : '-'}
            {fixedNumber}
          </Typography>
        </Box>
      </Tooltip>
    );
  }
};

interface ComparisonMatrix {
  gtDefectId: number | null;
  predDefectId: number | null;
  baseline: number;
  candidate: number;
}

const useTableStyles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(5),
    borderRadius: '10px',
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  headerRow: {
    backgroundColor: theme.palette.grey[50],
  },
  pointerCursor: {
    cursor: 'pointer',
  },
  selected: {
    backgroundColor: theme.palette.blue[50],
  },
  textAlignRight: {
    textAlign: 'right',
  },
  sumText: {
    fontWeight: 500,
    color: theme.palette.grey[600],
  },
  infoTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

const ConfusionMatrixTableRow: React.FC<{
  gtDefectId: number | null;
  predDefectId: number | null;
  count: number;
}> = ({ gtDefectId, predDefectId, count }) => {
  const styles = useTableStyles();
  const gtClassId = gtDefectId ?? 0;
  const predClassId = predDefectId ?? 0;
  const [filterOptions, setFilterOptions] = useAtom(modelListFilterOptionsAtom);
  const isCorrectMapping = gtClassId === predClassId;
  const isSelected =
    filterOptions?.gtClassId === gtClassId && filterOptions?.predClassId === predClassId;

  const tableRowRef = useRef<HTMLTableRowElement>(null);

  useEffect(() => {
    if (tableRowRef.current && isSelected) {
      tableRowRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [tableRowRef, isSelected]);

  return (
    <TableRow
      key={`${gtDefectId}-${predDefectId}`}
      className={cx(styles.pointerCursor, {
        [styles.selected]: isSelected,
      })}
      onClick={() =>
        setFilterOptions({
          gtClassId,
          predClassId,
        })
      }
      ref={tableRowRef}
    >
      <TableCell>
        <Box display="flex" alignItems="center">
          <DefectColorChip
            width={isCorrectMapping ? 250 : 130}
            defectId={gtDefectId}
            size={12}
            maxWidth={isCorrectMapping ? 150 : 100}
          />
          {!isCorrectMapping && (
            <DefectColorChip
              width={130}
              isPrediction
              defectId={predDefectId}
              size={12}
              maxWidth={100}
            />
          )}
        </Box>
      </TableCell>
      <TableCell>
        <Typography className={styles.textAlignRight}>{count}</Typography>
      </TableCell>
    </TableRow>
  );
};

const ComparisonConfusionMatrixTableRow: React.FC<{
  gtDefectId: number | null;
  predDefectId: number | null;
  baseline: number;
  candidate: number;
}> = ({ gtDefectId, predDefectId, baseline, candidate }) => {
  const styles = useTableStyles();
  const gtClassId = gtDefectId ?? 0;
  const predClassId = predDefectId ?? 0;
  const [filterOptions, setFilterOptions] = useAtom(modelListFilterOptionsAtom);
  const isCorrectMapping = gtClassId === predClassId;
  const isSelected =
    filterOptions?.gtClassId === gtClassId && filterOptions?.predClassId === predClassId;

  const tableRowRef = useRef<HTMLTableRowElement>(null);

  useEffect(() => {
    if (tableRowRef.current && isSelected) {
      tableRowRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [tableRowRef, isSelected]);

  return (
    <TableRow
      key={`${gtDefectId}-${predDefectId}`}
      className={cx(styles.pointerCursor, {
        [styles.selected]:
          filterOptions?.gtClassId === gtClassId && filterOptions?.predClassId === predClassId,
      })}
      onClick={() =>
        setFilterOptions({
          gtClassId,
          predClassId,
        })
      }
      ref={tableRowRef}
    >
      <TableCell>
        <Box display="flex" alignItems="center">
          <DefectColorChip
            width={filterOptions ? (isCorrectMapping ? 250 : 130) : isCorrectMapping ? 350 : 175}
            defectId={gtDefectId}
            size={12}
            maxWidth={isCorrectMapping ? 150 : 100}
          />
          {!isCorrectMapping && (
            <DefectColorChip
              width={filterOptions ? 130 : 175}
              isPrediction
              defectId={predDefectId}
              size={12}
              maxWidth={100}
            />
          )}
        </Box>
      </TableCell>
      <TableCell>
        <Typography className={styles.textAlignRight}>{baseline}</Typography>
      </TableCell>
      <TableCell>
        <Typography className={styles.textAlignRight}>{candidate}</Typography>
      </TableCell>
      <TableCell>
        <Percentage baseline={baseline} candidate={candidate} isCorrectMapping={isCorrectMapping} />
      </TableCell>
      {!filterOptions && (
        <TableCell>
          <Button
            id="view-specific-comparison-images-button"
            color="primary"
            variant="text"
            size="small"
            onClick={() =>
              setFilterOptions({
                gtClassId,
                predClassId,
              })
            }
          >
            {t('View')}
          </Button>
        </TableCell>
      )}
    </TableRow>
  );
};

export const ConfusionMatrixDiffTable: React.FC<{
  title: string;
  titleTooltip: string;
  comparisonSum?: {
    base: number;
    candidate: number;
  };
  confusionMatrixSum?: number;
  isCorrectMapping?: boolean;
  comparsionMatrices?: Array<ComparisonMatrix>;
  confusionMatrices?: Array<PredictionMatrixData>;
}> = ({
  title,
  titleTooltip,
  comparisonSum,
  confusionMatrixSum,
  isCorrectMapping,
  comparsionMatrices,
  confusionMatrices,
}) => {
  const styles = useTableStyles();
  const [filterOptions] = useAtom(modelListFilterOptionsAtom);
  return (
    <TableContainer className={styles.container}>
      <Table>
        <TableHead>
          <TableRow className={styles.headerRow}>
            <TableCell width={filterOptions || confusionMatrices ? 265 : 345}>
              <Box className={styles.infoTitle}>
                <Typography>{title}</Typography>
                <Tooltip placement="top" arrow={true} title={titleTooltip}>
                  <InfoOutlined fontSize="small" />
                </Tooltip>
              </Box>
            </TableCell>
            {confusionMatrixSum && (
              <TableCell width={100}>
                <span>
                  <Typography className={cx(styles.sumText, styles.textAlignRight)}>
                    {confusionMatrixSum}
                  </Typography>
                </span>
              </TableCell>
            )}
            {comparisonSum && (
              <>
                <TableCell width={filterOptions ? 100 : 145}>
                  <Tooltip
                    placement="top-start"
                    title={t(
                      'Total number of errors the baseline model made for {{errorType}} type.',
                      {
                        errorType: title.toLowerCase(),
                      },
                    )}
                  >
                    <span>
                      <Typography className={cx(styles.sumText, styles.textAlignRight)}>
                        {comparisonSum.base}
                      </Typography>
                    </span>
                  </Tooltip>
                </TableCell>
                <TableCell width={filterOptions ? 100 : 145}>
                  <Tooltip
                    placement="top-start"
                    title={t(
                      'Total number of errors the candidate model made for {{errorType}} type.',
                      {
                        errorType: title.toLowerCase(),
                      },
                    )}
                  >
                    <span>
                      <Typography className={cx(styles.sumText, styles.textAlignRight)}>
                        {comparisonSum.candidate}
                      </Typography>
                    </span>
                  </Tooltip>
                </TableCell>
                <TableCell width={filterOptions ? 100 : 120}>
                  <Percentage
                    baseline={comparisonSum.base}
                    candidate={comparisonSum.candidate}
                    isCorrectMapping={isCorrectMapping}
                  />
                </TableCell>
                {!filterOptions && <TableCell width={90} />}
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {comparsionMatrices &&
            comparsionMatrices.map(({ gtDefectId, predDefectId, baseline, candidate }) => {
              return (
                <ComparisonConfusionMatrixTableRow
                  key={`comparison-confusion-matrix-table-row-${gtDefectId}-${predDefectId}`}
                  gtDefectId={gtDefectId}
                  predDefectId={predDefectId}
                  baseline={baseline}
                  candidate={candidate}
                />
              );
            })}
          {confusionMatrices &&
            confusionMatrices.map(({ gtDefectId, predDefectId, count }) => (
              <ConfusionMatrixTableRow
                key={`confusion-matrix-table-row-${gtDefectId}-${predDefectId}`}
                gtDefectId={gtDefectId}
                predDefectId={predDefectId}
                count={count}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
