import { Defect, DefectBookExample, WipDefectData } from '@clef/shared/types';
import seedColor from 'seed-color';
import { greyScale } from '@clef/client-library';
import { AnomalyDefectionClassName } from '@clef/shared/constants';

// As of 2021-04-01, newer defects should have color in the database, older defects will still calculate from seedColor
export const getDefectColor = (defect?: Defect): string => {
  if (defect?.color) return defect?.color;
  if (defect?.id) {
    return seedColor(`${defect?.id}`).toHex() ?? greyScale[200];
  }
  return greyScale[200] as string;
};
export const EMPTY_DESCRIPTION_TEXT = t('Click “Edit” to add a description for this class.');

export const defectToDisplayData = (
  defect: Defect,
  defectExamples?: DefectBookExample[],
): WipDefectData => ({
  name: defect.name,
  description: defect.descriptionText ?? EMPTY_DESCRIPTION_TEXT,
  color: getDefectColor(defect),
  // init with false
  drafted: false,
  // this is undefined because examples has to be set later from useFetchDefectBookExampleApi api
  examples: defectExamples
    ? defect.defect_book_example_ids?.map(id => defectExamples.find(de => de.id === id)!)
    : undefined,
});

/**
 * TODO: should add a new column to do this.
 * Temporarily using description for quick fix.
 */
export const AutoCreatedClassDescription = '(auto_created)';

export const isPreCreatedDefect = (defect: Defect | undefined) =>
  defect?.name &&
  /^Class \d+$/.test(defect.name) &&
  defect.descriptionText === AutoCreatedClassDescription;

export const isAbnormalDefect = (defectName: string) => {
  return defectName.toLocaleLowerCase() === AnomalyDefectionClassName.Abnormal.toLocaleLowerCase();
};

export const isNormalDefect = (defectName: string) => {
  return defectName.toLocaleLowerCase() === AnomalyDefectionClassName.Normal.toLocaleLowerCase();
};
