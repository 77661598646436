import { ClientFeatures } from '@clef/shared/features';
import { BaseAPI } from './base_api';

class CommonAPI extends BaseAPI {
  // This API will set cookie for later calls
  async getDefaultFeatures(): Promise<ClientFeatures[]> {
    return this.get('enabled_features', undefined, true);
  }
}

export default new CommonAPI('common', '');
